import { createAction, props } from '@ngrx/store';
import {
    Article,
    ArticleChangeActive,
    ArticleCharge,
    ArticleGroup,
    ArticleHistory,
    ArticleRemoveUser,
    ArticleSimple,
    ArticleTempSimple,
    ArticleTemporaryRemoveSendToAPI,
    ArticleUpdateMultiple,
    ArticleWash,
    ListQueryParams,
    UserArticle
} from '@shared/models';

const actionPrefixArticle = '[Article]';
const actionPrefixArticleGroup = '[Article Group]';

export const CLEAR_ARTICLES = createAction(
    `${actionPrefixArticle} Clear articles from store from the API`
);

export const CLEAR_TEMP_ARTICLE = createAction(`${actionPrefixArticle} Clear temp article object`);

export const CLEAR_ARTICLE_SIMPLE = createAction(`${actionPrefixArticle} Clear simple articles`);

export const CLEAR_SELECTED_ARTICLE = createAction(
    `${actionPrefixArticle} Clear selected articles`
);

export const GET_ARTICLE_TEMP_QUERY_PARAMS = createAction(
    `${actionPrefixArticle} Get articles temporary by query params from the API`,
    props<{ searchQuery: ListQueryParams }>()
);
export const GET_ARTICLE_QUERY_PARAMS = createAction(
    `${actionPrefixArticle} Get articles by query params from the API`,
    props<{ searchQuery: ListQueryParams }>()
);
export const GET_ARTICLE_QUERY_PARAMS_SUCCESS = createAction(
    `${actionPrefixArticle} Get articles by query params from the API success`,
    props<{ articles: Article[] }>()
);
export const GET_ARTICLE_QUERY_PARAMS_FAIL = createAction(
    `${actionPrefixArticle} Get articles by query params from the API fail`,
    props<{ error: any }>()
);

export const GET_ARTICLE_FOR_SEARCH = createAction(
    `${actionPrefixArticle} Get articles for search from the API`,
    props<{ searchQuery: ListQueryParams }>()
);

export const GET_ARTICLE = createAction(`${actionPrefixArticle} Get all articles from the API`);
export const GET_ARTICLE_SUCCESS = createAction(
    `${actionPrefixArticle} Get all articles from the API success`,
    props<{ articles: ArticleSimple[] }>()
);
export const GET_ARTICLE_FAIL = createAction(
    `${actionPrefixArticle} Get all articles from the API fail`,
    props<{ error: any }>()
);

export const GET_ARTICLE_TEMP_SIMPLE = createAction(
    `${actionPrefixArticle} Get articles temporary simple from the API`
);
export const GET_ARTICLE_TEMP_SIMPLE_SUCCESS = createAction(
    `${actionPrefixArticle} Get articles temporary simple from the API success`,
    props<{ articles: ArticleTempSimple[] }>()
);
export const GET_ARTICLE_TEMP_SIMPLE_FAIL = createAction(
    `${actionPrefixArticle} Get articles temporary simple from the API fail`,
    props<{ error: any }>()
);

export const GET_ARTICLE_HISTORY = createAction(
    `${actionPrefixArticle} Get article history from the API`,
    props<{ articleId: string }>()
);
export const GET_ARTICLE_HISTORY_SUCCESS = createAction(
    `${actionPrefixArticle} Get article history from the API success`,
    props<{ articles: ArticleHistory[] }>()
);
export const GET_ARTICLE_HISTORY_FAIL = createAction(
    `${actionPrefixArticle} Get article history from the API fail`,
    props<{ error: any }>()
);

export const GET_ARTICLE_USER_HISTORY = createAction(
    `${actionPrefixArticle} Get article history user from the API`,
    props<{ articleId: string }>()
);
export const GET_ARTICLE_USER_HISTORY_SUCCESS = createAction(
    `${actionPrefixArticle} Get article history user from the API success`,
    props<{ articles: ArticleHistory[] }>()
);
export const GET_ARTICLE_USER_HISTORY_FAIL = createAction(
    `${actionPrefixArticle} Get article history user from the API fail`,
    props<{ error: any }>()
);

export const GET_ARTICLE_BY_ID = createAction(
    `${actionPrefixArticle} Get article by id from the API`,
    props<{ articleId: string }>()
);
export const GET_ARTICLE_BY_ID_SUCCESS = createAction(
    `${actionPrefixArticle} Get article by id from the API success`,
    props<{ article: Article[] }>()
);
export const GET_ARTICLE_BY_ID_FAIL = createAction(
    `${actionPrefixArticle} Get article by id from the API fail`,
    props<{ error: any }>()
);

export const GET_ARTICLE_BY_DEPARTMENT_ID = createAction(
    `${actionPrefixArticle} Get article by departmentId from the API`,
    props<{ departmentId: string }>()
);
export const GET_ARTICLE_BY_DEPARTMENT_ID_SUCCESS = createAction(
    `${actionPrefixArticle} Get article by departmentId from the API success`,
    props<{ articles: Article[] }>()
);
export const GET_ARTICLE_BY_DEPARTMENT_ID_FAIL = createAction(
    `${actionPrefixArticle} Get article by departmentId from the API fail`,
    props<{ error: any }>()
);

export const GET_ARTICLE_BY_CLIENT_ID = createAction(
    `${actionPrefixArticle} Get article by clientId from the API`,
    props<{ clientId: string; userId: string; active: number }>()
);
export const GET_ARTICLE_BY_CLIENT_ID_SUCCESS = createAction(
    `${actionPrefixArticle} Get article by clientId from the API success`,
    props<{ articles: Article[], chargeFailMsg: string; }>()
);
export const GET_ARTICLE_BY_CLIENT_ID_FAIL = createAction(
    `${actionPrefixArticle} Get article by clientId from the API fail`,
    props<{ error: any }>()
);

export const GET_ARTICLE_BY_USER_ID = createAction(
    `${actionPrefixArticle} Get article by user id from the API`,
    props<{ userId: string }>()
);
export const GET_ARTICLE_BY_USER_ID_SUCCESS = createAction(
    `${actionPrefixArticle} Get article by user id from the API success`,
    props<{ articles: Article[] }>()
);
export const GET_ARTICLE_BY_USER_ID_FAIL = createAction(
    `${actionPrefixArticle} Get article by user id from the API fail`,
    props<{ error: any }>()
);

export const GET_CHARGE_ARTICLE_BY_ARTICLE_ID = createAction(
    `${actionPrefixArticle} Get charge article by article id from the API`,
    props<{ articleId: string }>()
);
export const GET_CHARGE_ARTICLE_BY_ARTICLE_ID_SUCCESS = createAction(
    `${actionPrefixArticle} Get charge article by article id from the API success`,
    props<{ article: Article }>()
);
export const GET_CHARGE_ARTICLE_BY_ARTICLE_ID_FAIL = createAction(
    `${actionPrefixArticle} Get charge article by article id from the API fail`,
    props<{ error: any }>()
);

export const GET_CHARGED_TEMP_ARTICLE_BY_USER_ID = createAction(
    `${actionPrefixArticle} Get charged temp article by user id from the API`,
    props<{ userId: string }>()
);
export const GET_CHARGED_TEMP_ARTICLE_BY_USER_ID_SUCCESS = createAction(
    `${actionPrefixArticle} Get charged temp article by user id from the API success`,
    props<{ articles: Article[] }>()
);
export const GET_CHARGED_TEMP_ARTICLE_BY_USER_ID_FAIL = createAction(
    `${actionPrefixArticle} Get charged temp article by user id from the API fail`,
    props<{ error: any }>()
);

export const GET_ARTICLE_FOR_WRITE_OFF = createAction(
    `${actionPrefixArticle} Get article by status and active from the API`,
    props<{ status: number; active: number }>()
);
export const GET_ARTICLE_FOR_WRITE_OFF_SUCCESS = createAction(
    `${actionPrefixArticle} Get article by status and active from the API success`,
    props<{ articles: Article[] }>()
);
export const GET_ARTICLE_FOR_WRITE_OFF_FAIL = createAction(
    `${actionPrefixArticle} Get article by status and active from the API fail`,
    props<{ error: any }>()
);

export const ADD_USER_ARTICLE = createAction(
    `${actionPrefixArticle} Add user article object`,
    props<{ articleId: string; userId: string }>()
);
export const ADD_USER_ARTICLE_SUCCESS = createAction(
    `${actionPrefixArticle} Add user article object success`,
    props<{ articles: Article[] }>()
);
export const ADD_USER_ARTICLE_FAIL = createAction(
    `${actionPrefixArticle} Add user article object fail`,
    props<{ error: any }>()
);

export const UPDATE_MULTIPLE_ARTICLE = createAction(
    `${actionPrefixArticle} Update multiple article object`,
    props<{ article: { article: ArticleUpdateMultiple; pdf: any } }>()
);
export const UPDATE_MULTIPLE_ARTICLE_SUCCESS = createAction(
    `${actionPrefixArticle} Update multiple article object success`,
    props<{ article: Article[] }>()
);
export const UPDATE_MULTIPLE_ARTICLE_FAIL = createAction(
    `${actionPrefixArticle} Update multiple article object fail`,
    props<{ error: any }>()
);

export const UPDATE_ARTICLE = createAction(
    `${actionPrefixArticle} Update article object`,
    props<{ article: Article }>()
);
export const UPDATE_ARTICLE_SUCCESS = createAction(
    `${actionPrefixArticle} Update article object success`,
    props<{ article: Article[] }>()
);
export const UPDATE_ARTICLE_FAIL = createAction(
    `${actionPrefixArticle} Update article object fail`,
    props<{ error: any }>()
);

export const UPDATE_ARTICLE_ACTIVE_STATUS = createAction(
    `${actionPrefixArticle} Update article active status object`,
    props<{ article: ArticleChangeActive }>()
);

export const CREATE_ARTICLE = createAction(
    `${actionPrefixArticle} Create article object`,
    props<{ article: Article }>()
);
export const CREATE_ARTICLE_SUCCESS = createAction(
    `${actionPrefixArticle} Create article object success`,
    props<{ article: Article[] }>()
);
export const CREATE_ARTICLE_FAIL = createAction(
    `${actionPrefixArticle} Create article object fail`,
    props<{ error: any }>()
);

export const CREATE_TEMPORARY_ARTICLE = createAction(
    `${actionPrefixArticle} Create temporary article object`,
    props<{ article: Article }>()
);

export const VERIFY_USER_ARTICLES = createAction(
    `${actionPrefixArticle} Verify user articles object`,
    props<{ userArticle: { articles: Article[]; user: string; pdf: any } }>()
);
export const VERIFY_USER_ARTICLES_SUCCESS = createAction(
    `${actionPrefixArticle} Verify user articles object success`,
    props<{ articles: UserArticle[] }>()
);
export const VERIFY_USER_ARTICLES_FAIL = createAction(
    `${actionPrefixArticle} Verify user articles object fail`,
    props<{ error: any }>()
);

export const SET_MULTIPLE_EDIT_ARTICLES = createAction(
    `${actionPrefixArticle} Set multiple edit articles object`,
    props<{ article: Article[] }>()
);

export const CLEAR_MULTIPLE_EDIT_ARTICLES = createAction(
    `${actionPrefixArticle} Clear multiple edit articles object`
);

export const WRITE_OFF_ARTICLE = createAction(
    `${actionPrefixArticle} Write off article object`,
    props<{ articles: Article[]; pdf: any }>()
);
// export const WRITE_OFF_ARTICLE_SUCCESS = createAction(
//     `${actionPrefixArticle} Write off article object success`,
//     props<{ articles: Article[] }>()
// );
// export const WRITE_OFF_ARTICLE_FAIL = createAction(
//     `${actionPrefixArticle} Write off article object fail`,
//     props<{ error: any }>()
// );

//#region Charge
export const ARTICLE_CHARGE = createAction(
    `${actionPrefixArticle} Article charge object`,
    props<{ articleCharge: ArticleCharge; printData: any }>()
);
export const ARTICLE_CHARGE_SUCCESS = createAction(
    `${actionPrefixArticle} Article charge object success`,
    props<{ articles: UserArticle[] }>()
);
export const ARTICLE_CHARGE_FAIL = createAction(
    `${actionPrefixArticle} Article charge object fail`,
    props<{ error: any }>()
);

export const ARTICLE_CHARGE_TEMP = createAction(
    `${actionPrefixArticle} Temporary article charge object`,
    props<{ articleCharge: ArticleCharge; printData: any }>()
);

export const ARTICLE_REMOVE_USER = createAction(
    `${actionPrefixArticle} Article remove user object`,
    props<{ userArticles: ArticleRemoveUser }>()
);
export const ARTICLE_REMOVE_USER_SUCCESS = createAction(
    `${actionPrefixArticle} Article remove user object success`,
    props<{ articles: UserArticle[] }>()
);
export const ARTICLE_REMOVE_USER_FAIL = createAction(
    `${actionPrefixArticle} Article remove user object fail`,
    props<{ error: any }>()
);

export const GET_ARTICLE_CHARGE_BY_ARTICLE = createAction(
    `${actionPrefixArticle} Get article charge by article id object`,
    props<{ articleId: string; itemsPerPage: number; page: number; searchString: any }>()
);
export const GET_ARTICLE_CHARGE_BY_ARTICLE_SUCCESS = createAction(
    `${actionPrefixArticle} Get article charge by article id object success`,
    props<{ articles: ArticleRemoveUser; userId: string }>()
);
export const GET_ARTICLE_CHARGE_BY_ARTICLE_FAIL = createAction(
    `${actionPrefixArticle} Get article charge by article id object fail`,
    props<{ error: any }>()
);

export const GET_ARTICLE_CHARGE_BY_STATUS = createAction(
    `${actionPrefixArticle} Get article charge by status from the API`,
    props<{ status: number }>()
);
export const GET_ARTICLE_CHARGE_BY_STATUS_SUCCESS = createAction(
    `${actionPrefixArticle} Get article charge by status from the API success`,
    props<{ articles: ArticleWash[] }>()
);
export const GET_ARTICLE_CHARGE_BY_STATUS_FAIL = createAction(
    `${actionPrefixArticle} Get article charge by status from the API fail`,
    props<{ error: any }>()
);

export const ARTICLE_SEND_TO_WASH = createAction(
    `${actionPrefixArticle} Send articles for a washing`,
    props<{ articles: ArticleWash[]; status: number; pdf: any }>()
);
export const ARTICLE_SEND_TO_WASH_SUCCESS = createAction(
    `${actionPrefixArticle} Send articles for a washing success`,
    props<{ articles: ArticleWash[] }>()
);
export const ARTICLE_SEND_TO_WASH_FAIL = createAction(
    `${actionPrefixArticle} Send articles for a washing fail`,
    props<{ error: any }>()
);

export const ARTICLE_RETURN_FROM_WASH = createAction(
    `${actionPrefixArticle} Articles return from a wash`,
    props<{ articles: ArticleWash[]; status: number; pdf: any }>()
);

export const ADJUST_TEMPORARY_ARTICLE_QUANTITY = createAction(
    `${actionPrefixArticle} Adjust temporary article quantity`,
    props<{ articles: ArticleTemporaryRemoveSendToAPI }>()
);
export const ADJUST_TEMPORARY_ARTICLE_QUANTITY_SUCCESS = createAction(
    `${actionPrefixArticle} Adjust temporary article quantity success`,
    props<{ articles: ArticleWash[] }>()
);
export const ADJUST_TEMPORARY_ARTICLE_QUANTITY_FAIL = createAction(
    `${actionPrefixArticle} Adjust temporary article quantity fail`,
    props<{ error: any }>()
);

export const ARTICLE_GROUP_RETURN = createAction(
    `${actionPrefixArticle} Article group return`,
    props<{ articles: ArticleWash[]; pdf: any }>()
);
export const ARTICLE_GROUP_RETURN_SUCCESS = createAction(
    `${actionPrefixArticle} Article group return success`,
    props<{ status: boolean }>()
);
export const ARTICLE_GROUP_RETURN_FAIL = createAction(
    `${actionPrefixArticle} Article group return fail`,
    props<{ error: any }>()
);

// export const THERMAL_PRINTER_PRINT = createAction(
//     `${actionPrefixArticle} Thermal printer print`,
//     props<{ data: any }>()
// );
// export const THERMAL_PRINTER_PRINT_SUCCESS = createAction(
//     `${actionPrefixArticle} Thermal printer print success`,
//     props<{ data: any }>()
// );
// export const THERMAL_PRINTER_PRINT_FAIL = createAction(
//     `${actionPrefixArticle} Thermal printer print fail`,
//     props<{ error: any }>()
// );
//#endregion

//#region Article Group
export const GET_ARTICLE_GROUP_QUERY_PARAMS = createAction(
    `${actionPrefixArticleGroup} Get article groups by query params from the API`,
    props<{ searchQuery: ListQueryParams }>()
);
export const GET_ARTICLE_GROUP_QUERY_PARAMS_SUCCESS = createAction(
    `${actionPrefixArticleGroup} Get article groups by query params from the API success`,
    props<{ articleGroups: ArticleGroup[] }>()
);
export const GET_ARTICLE_GROUP_QUERY_PARAMS_FAIL = createAction(
    `${actionPrefixArticleGroup} Get article groups by query params from the API fail`,
    props<{ error: any }>()
);

export const GET_ARTICLE_GROUP = createAction(
    `${actionPrefixArticleGroup} Get all article groups from the API`
);
export const GET_ARTICLE_GROUP_SUCCESS = createAction(
    `${actionPrefixArticleGroup} Get all article groups from the API success`,
    props<{ articleGroups: ArticleGroup[] }>()
);
export const GET_ARTICLE_GROUP_FAIL = createAction(
    `${actionPrefixArticleGroup} Get all article groups from the API fail`,
    props<{ error: any }>()
);

export const GET_ARTICLE_GROUP_BY_ID = createAction(
    `${actionPrefixArticleGroup} Get article group by id from the API`,
    props<{ articleGroupId: string }>()
);
export const GET_ARTICLE_GROUP_BY_ID_SUCCESS = createAction(
    `${actionPrefixArticleGroup} Get article group by id from the API success`,
    props<{ articleGroups: ArticleGroup[] }>()
);
export const GET_ARTICLE_GROUP_BY_ID_FAIL = createAction(
    `${actionPrefixArticleGroup} Get article group by id from the API fail`,
    props<{ error: any }>()
);

export const UPDATE_ARTICLE_GROUP = createAction(
    `${actionPrefixArticleGroup} Update article group object`,
    props<{ articleGroup: ArticleGroup }>()
);
export const UPDATE_ARTICLE_GROUP_SUCCESS = createAction(
    `${actionPrefixArticleGroup} Update article group object success`,
    props<{ articleGroups: ArticleGroup[] }>()
);
export const UPDATE_ARTICLE_GROUP_FAIL = createAction(
    `${actionPrefixArticleGroup} Update article group object fail`,
    props<{ error: any }>()
);

export const CREATE_ARTICLE_GROUP = createAction(
    `${actionPrefixArticleGroup} Create article group object`,
    props<{ articleGroup: ArticleGroup }>()
);
export const CREATE_ARTICLE_GROUP_SUCCESS = createAction(
    `${actionPrefixArticleGroup} Create article group object success`,
    props<{ articleGroups: ArticleGroup[] }>()
);
export const CREATE_ARTICLE_GROUP_FAIL = createAction(
    `${actionPrefixArticleGroup} Create article group object fail`,
    props<{ error: any }>()
);
//#endregion
