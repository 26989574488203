//#region Imports
import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { UI_CONSTANTS } from '@shared/config/constants';
//#endregion

/**
 * This class is notification service. Service can show notification,
 * enable and disable notification
 *
 * @Function are:
 *     - default(message: string)
 *     - info(message: string)
 *     - success(message: string)
 *     - warn(message: string)
 *     - error(message: string)
 */
@Injectable({
    providedIn: 'root'
})
export class NotificationService {
    constructor(public snackBar: MatSnackBar, private translateService: TranslateService) {}

    private openSnackBar(message: string = '', configuration?: MatSnackBarConfig) {
        let translatedMessage;

        this.translateService.stream(message).subscribe((data: any) => (translatedMessage = data));
        this.snackBar.open(translatedMessage, 'Close', configuration);
    }

    default(message: string) {
        this.openSnackBar(message, {
            duration: UI_CONSTANTS.TOAST_MESSAGE_DURATION,
            horizontalPosition: 'left',
            panelClass: 'default-notification-overlay'
        });
    }

    info(message: string) {
        this.openSnackBar(message, {
            duration: UI_CONSTANTS.TOAST_MESSAGE_DURATION,
            horizontalPosition: 'left',
            panelClass: 'info-notification-overlay'
        });
    }

    success(message: string) {
        this.openSnackBar(message, {
            duration: UI_CONSTANTS.TOAST_MESSAGE_DURATION,
            horizontalPosition: 'left',
            panelClass: 'success-notification-overlay'
        });
    }

    warn(message: string) {
        this.openSnackBar(message, {
            duration: UI_CONSTANTS.TOAST_MESSAGE_DURATION,
            horizontalPosition: 'left',
            panelClass: 'warning-notification-overlay'
        });
    }

    error(message: string) {
        this.openSnackBar(message, {
            duration: UI_CONSTANTS.TOAST_MESSAGE_DURATION,
            horizontalPosition: 'left',
            panelClass: 'error-notification-overlay'
        });
    }
}
