import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'articleChargeStatus'
})
export class ArticleChargeStatusPipe implements PipeTransform {
    transform(value: unknown): string {
        let result = '';
        if (Number(value) === 0) {
            result = 'pipe.returned';
        }
        if (Number(value) === 1) {
            result = 'pipe.charged';
        }
        if (Number(value) === 2) {
            result = 'pipe.sentToWash';
        }
        if (Number(value) === 3) {
            result = 'pipe.readyToCharge';
        }
        if (Number(value) === 4) {
            result = 'pipe.userReturnedArticle';
        }
        if (Number(value) === 5) {
            result = 'pipe.lost';
        }
        if (Number(value) === 6) {
            result = 'pipe.damaged';
        }
        if (Number(value) === 7) {
            result = 'pipe.all';
        }

        return result;
    }
}
