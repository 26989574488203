<div class="mat-typography ec-image-cropper-wrap">
    <div>
        <h2 class="ec-section-title">{{ 'imageCropper.title' | translate }}</h2>
    </div>

    <section>
        <div>
            <button type="button" mat-raised-button (click)="imgFileInput.click()">
                {{ 'imageCropper.addPhoto' | translate }}
            </button>
            <input
                hidden
                type="file"
                accept="image/png, image/jpeg"
                #imgFileInput
                (change)="fileChangeEvent($event)"
            />
        </div>
        <button mat-button [disabled]="!croppedImage" (click)="rotateLeft()">
            {{ 'imageCropper.rotateLeft' | translate }}
        </button>
        <button mat-button [disabled]="!croppedImage" (click)="rotateRight()">
            {{ 'imageCropper.rotateRight' | translate }}
        </button>
        <button mat-button [disabled]="!croppedImage" (click)="flipHorizontal()">
            {{ 'imageCropper.flipHorizontal' | translate }}
        </button>
        <button mat-button [disabled]="!croppedImage" (click)="flipVertical()">
            {{ 'imageCropper.flipVertical' | translate }}
        </button>
        <button mat-button [disabled]="!croppedImage" (click)="resetImage()">
            {{ 'imageCropper.resetImage' | translate }}
        </button>
    </section>

    <section>
        <button mat-button [disabled]="!croppedImage" (click)="toggleContainWithinAspectRatio()">
            {{ containWithinAspectRatio ? fillAspectRatio : containAspectRatio }}
        </button>
        <button mat-button [disabled]="!croppedImage" (click)="zoomOut()">
            {{ 'imageCropper.zoom' | translate }} -
        </button>
        <button mat-button [disabled]="!croppedImage" (click)="zoomIn()">
            {{ 'imageCropper.zoom' | translate }} +
        </button>
    </section>

    <mat-dialog-content>
        <div>
            <image-cropper
                [imageChangedEvent]="imageChangedEvent"
                [maintainAspectRatio]="maintainAspectRatio"
                [containWithinAspectRatio]="containWithinAspectRatio"
                [aspectRatio]="aspectRatio"
                [resizeToWidth]="resizeToWidth"
                [cropperMinWidth]="cropperMinWidth"
                [onlyScaleDown]="onlyScaleDown"
                [roundCropper]="roundCropper"
                [canvasRotation]="canvasRotation"
                [transform]="transform"
                [alignImage]="alignImage"
                [style.display]="showCropper ? null : 'none'"
                [format]="format"
                output="base64"
                (imageCropped)="imageCropped($event)"
                (imageLoaded)="imageLoaded()"
                (cropperReady)="cropperReady($event)"
                (loadImageFailed)="loadImageFailed()"
            ></image-cropper>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions class="ec-image-cropper-actions">
        <button mat-raised-button mat-dialog-close>{{ 'button.cancel' | translate }}</button>

        @if (!isImageBig && croppedImage) {
        <div>{{ 'error.imageCropper.currentFileSize' | translate }}: {{ currentImageSize }}</div>
        } @if (isImageBig) {
        <div>
            <span class="mat-error">{{ 'error.imageCropper.bigImage' | translate }}</span>
            <span class="mat-error">
                {{ 'error.imageCropper.maxFileSize' | translate }}: {{ formatBytes(maxImageSize) }},
                {{ 'error.imageCropper.currentFileSize' | translate }}: {{ currentImageSize }}
            </span>
        </div>
        }

        <button mat-raised-button [disabled]="isImageBig" [mat-dialog-close]="croppedImage">
            {{ 'button.add' | translate }}
        </button>
    </mat-dialog-actions>
</div>
