//#region Imports
import * as fromActions from './../actions';
import { createReducer, on } from '@ngrx/store';
import * as fromModel from '@shared/models';
//#endregion

export interface DepartmentState {
    departments: { [departmentId: string]: fromModel.Department };
    departmentSimple: { [departmentId: string]: fromModel.Department };
    total: number;
    selectedDepartment: fromModel.Department | null;

    isLoading: boolean;
    isLoaded: boolean;
    error: fromModel.ErrorModel | null;
}

export const initialState: DepartmentState = {
    departments: {},
    departmentSimple: {},
    total: 0,
    selectedDepartment: null,

    isLoaded: false,
    isLoading: false,
    error: null
};

const departmentReducerState = createReducer(
    initialState,
    on(
        fromActions.GET_DEPARTMENT_QUERY_PARAMS,
        fromActions.GET_DEPARTMENT,
        fromActions.GET_DEPARTMENT_BY_ID,
        fromActions.GET_DEPARTMENT_BY_USER_ID,
        fromActions.GET_DEPARTMENT_BY_CLIENT_ID,
        fromActions.UPDATE_DEPARTMENT,
        fromActions.CREATE_DEPARTMENT,
        (state: DepartmentState) => {
            return {
                ...state,
                isLoaded: false,
                isLoading: true,
                error: null
            };
        }
    ),
    on(fromActions.GET_DEPARTMENT_QUERY_PARAMS_SUCCESS, (state: DepartmentState, payload: any) => {
        const getDepartment: fromModel.Department[] = payload.departments.msg;
        const total: number = payload.departments.total;
        const entities = getDepartment.reduce(
            (
                entity: { [departmentId: string]: fromModel.Department },
                department: fromModel.Department
            ) => {
                return {
                    ...entity,
                    [department.departmentId]: department
                };
            },
            {}
        );
        return {
            ...state,
            departments: entities,
            total,
            isLoaded: true,
            isLoading: false,
            error: null
        };
    }),
    on(fromActions.GET_DEPARTMENT_QUERY_PARAMS_FAIL, (state: DepartmentState, payload: any) => {
        const error: fromModel.ErrorModel = payload.error;
        return {
            ...state,
            departments: {},
            total: 0,
            isLoaded: false,
            isLoading: false,
            error
        };
    }),

    on(fromActions.GET_DEPARTMENT, (state: DepartmentState) => {
        return {
            ...state,
            departmentSimple: {},
            isLoaded: false,
            isLoading: true,
            error: null
        };
    }),
    on(fromActions.GET_DEPARTMENT_SUCCESS, (state: DepartmentState, payload: any) => {
        const getDepartment: fromModel.Department[] = payload.departments.msg;
        const entities = getDepartment.reduce(
            (
                entity: { [departmentId: string]: fromModel.Department },
                department: fromModel.Department
            ) => {
                return {
                    ...entity,
                    [department.departmentId]: department
                };
            },
            {
                ...state.departmentSimple
            }
        );
        return {
            ...state,
            departmentSimple: entities,
            isLoaded: true,
            isLoading: false,
            error: null
        };
    }),
    on(
        fromActions.GET_DEPARTMENT_FAIL,
        fromActions.GET_DEPARTMENT_BY_ID_FAIL,
        fromActions.GET_DEPARTMENT_BY_USER_ID_FAIL,
        fromActions.UPDATE_DEPARTMENT_FAIL,
        fromActions.CREATE_DEPARTMENT_FAIL,
        (state: DepartmentState, payload: any) => {
            const error: fromModel.ErrorModel = payload.error;
            return {
                ...state,
                isLoaded: false,
                isLoading: false,
                error
            };
        }
    ),

    on(fromActions.GET_DEPARTMENT_BY_ID_SUCCESS, (state: DepartmentState, payload: any) => {
        return {
            ...state,
            selectedDepartment: payload.department.msg[0],
            isLoaded: true,
            isLoading: false,
            error: null
        };
    }),

    on(
        fromActions.GET_DEPARTMENT_BY_USER_ID_SUCCESS,
        fromActions.GET_DEPARTMENT_BY_CLIENT_ID_SUCCESS,
        (state: DepartmentState, payload: any) => {
            const getDepartment: fromModel.Department[] = payload.departments.msg;
            const entities = getDepartment?.reduce((idx, department) => {
                return {
                    ...idx,
                    [department.departmentId]: department
                };
            }, {});
            return {
                ...state,
                departments: entities,
                isLoaded: true,
                isLoading: false,
                error: null
            };
        }
    ),

    on(fromActions.GET_DEPARTMENT_BY_CLIENT_ID_FAIL, (state: DepartmentState, payload: any) => {
        const error: fromModel.ErrorModel = payload.payload;
        return {
            ...state,
            departments: {},
            isLoaded: false,
            isLoading: false,
            error
        };
    }),

    on(
        fromActions.CREATE_DEPARTMENT_SUCCESS,
        fromActions.UPDATE_DEPARTMENT_SUCCESS,
        (state: DepartmentState, payload: any) => {
            const department: fromModel.Department = payload.department.msg[0];
            const departments = {
                ...state.departments,
                [department.departmentId]: department
            };
            return {
                ...state,
                departments,
                isLoaded: true,
                isLoading: false,
                error: null
            };
        }
    )
);

/**
 *
 * @param {DepartmentState} state
 * @param {DepartmentActions} action
 * @return {departmentReducerState} departmentReducerState
 */
export function departmentReducer(state: DepartmentState, action: any) {
    return departmentReducerState(state, action);
}
