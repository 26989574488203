//#region Imports
import { createAction, props } from '@ngrx/store';
import * as fromModel from '@shared/models';
//#endregion

const actionPrefixUser = '[User]';
const actionPrefixRole = '[Role]';
const actionNamePdfDocs = '[PdfDocs]';

//#region Users
export const GET_USER_QUERY_PARAMS = createAction(
    `${actionPrefixUser} Get users by query params from the API`,
    props<{ searchQuery: fromModel.ListQueryParams }>()
);
export const GET_USER_QUERY_PARAMS_SUCCESS = createAction(
    `${actionPrefixUser} Get users by query params from the API success`,
    props<{ users: fromModel.User[] }>()
);
export const GET_USER_QUERY_PARAMS_FAIL = createAction(
    `${actionPrefixUser} Get users by query params from the API fail`,
    props<{ error: any }>()
);

export const GET_USER = createAction(
    `${actionPrefixUser} Get all users from the API`,
    props<{ active: number }>()
);
export const GET_USER_SUCCESS = createAction(
    `${actionPrefixUser} Get all users from the API success`,
    props<{ userSimple: fromModel.UserSimple[] }>()
);
export const GET_USER_FAIL = createAction(
    `${actionPrefixUser} Get all users from the API fail`,
    props<{ error: any }>()
);

export const GET_USER_BY_ID = createAction(
    `${actionPrefixUser} Get user by id from the API`,
    props<{ userId: string }>()
);
export const GET_USER_BY_ID_SUCCESS = createAction(
    `${actionPrefixUser} Get user by id from the API success`,
    props<{ user: fromModel.User[] }>()
);
export const GET_USER_BY_ID_FAIL = createAction(
    `${actionPrefixUser} Get user by id from the API fail`,
    props<{ error: any }>()
);

export const GET_USER_BY_EXTERNAL_ID = createAction(
    `${actionPrefixUser} Get user by externalId from the API`,
    props<{ externalId: string }>()
);
export const GET_USER_BY_EXTERNAL_ID_SUCCESS = createAction(
    `${actionPrefixUser} Get user by externalId from the API success`,
    props<{ user: fromModel.User[] }>()
);
export const GET_USER_BY_EXTERNAL_ID_FAIL = createAction(
    `${actionPrefixUser} Get user by externalId from the API fail`,
    props<{ error: any }>()
);

export const GET_USER_BY_DEPARTMENT_ID = createAction(
    `${actionPrefixUser} Get user by departmentId from the API`,
    props<{ departmentId: string }>()
);
export const GET_USER_BY_DEPARTMENT_ID_SUCCESS = createAction(
    `${actionPrefixUser} Get user by departmentId from the API success`,
    props<{ users: fromModel.User[] }>()
);
export const GET_USER_BY_DEPARTMENT_ID_FAIL = createAction(
    `${actionPrefixUser} Get user by departmentId from the API fail`,
    props<{ error: any }>()
);

export const GET_USER_BY_CLIENT_DEPARTMENT_ID = createAction(
    `${actionPrefixUser} Get user by clientId, departmentId from the API`,
    props<{ clientId: string; departmentId: string }>()
);
export const GET_USER_BY_CLIENT_DEPARTMENT_ID_SUCCESS = createAction(
    `${actionPrefixUser} Get user by clientId, departmentId from the API success`,
    props<{ users: fromModel.User[] }>()
);
export const GET_USER_BY_CLIENT_DEPARTMENT_ID_FAIL = createAction(
    `${actionPrefixUser} Get user by clientId, departmentId from the API fail`,
    props<{ error: any }>()
);

export const GET_USER_BY_USERNAME = createAction(
    `${actionPrefixUser} Get user by username from the API`,
    props<{ username: string }>()
);
export const GET_USER_BY_USERNAME_SUCCESS = createAction(
    `${actionPrefixUser} Get user by username from the API success`,
    props<{ user: fromModel.User[] }>()
);
export const GET_USER_BY_USERNAME_FAIL = createAction(
    `${actionPrefixUser} Get user by username from the API fail`,
    props<{ error: any }>()
);

export const GET_USER_BY_FULL_NAME = createAction(
    `${actionPrefixUser} Get user by full-name from the API`,
    props<{ firstName: string; lastName: string }>()
);
export const GET_USER_BY_FULL_NAME_SUCCESS = createAction(
    `${actionPrefixUser} Get user by full-name from the API success`,
    props<{ user: fromModel.User[] }>()
);
export const GET_USER_BY_FULL_NAME_FAIL = createAction(
    `${actionPrefixUser} Get user by full-name from the API fail`,
    props<{ error: any }>()
);

export const UPDATE_USER_PASSWORD = createAction(
    `${actionPrefixUser} Update user password object`,
    props<{ value: { userId: string; password: string; confirmPassword: string } }>()
);

export const UPDATE_USER_ACTIVE = createAction(
    `${actionPrefixUser} Update user active object`,
    props<{ value: fromModel.UserActive }>()
);

export const UPDATE_USER_ACTIVE_FAIL = createAction(
    `${actionPrefixUser} Update user active object fail`,
    props<{ error: fromModel.UserActiveError }>()
);

export const UPDATE_USER = createAction(
    `${actionPrefixUser} Update user object`,
    props<{ user: fromModel.User }>()
);
export const UPDATE_USER_SUCCESS = createAction(
    `${actionPrefixUser} Update user object success`,
    props<{ user: fromModel.User[] }>()
);
export const UPDATE_USER_FAIL = createAction(
    `${actionPrefixUser} Update user object fail`,
    props<{ error: any }>()
);

export const CREATE_USER = createAction(
    `${actionPrefixUser} Create user object`,
    props<{ user: fromModel.User }>()
);
export const CREATE_USER_SUCCESS = createAction(
    `${actionPrefixUser} Create user object success`,
    props<{ user: fromModel.User[] }>()
);
export const CREATE_USER_FAIL = createAction(
    `${actionPrefixUser} Create user object fail`,
    props<{ error: any }>()
);

export const GET_PDF_DOCS_BY_USER_ID = createAction(
    `${actionNamePdfDocs} Get pdfDocs by user id from the API`,
    props<{ userId: string }>()
);
export const GET_PDF_DOCS_BY_USER_ID_SUCCESS = createAction(
    `${actionNamePdfDocs} Get pdfDocs by user id from the API success`,
    props<{ pdfDocs: fromModel.PdfDocs[] }>()
);
export const GET_PDF_DOCS_BY_USER_ID_FAIL = createAction(
    `${actionNamePdfDocs} Get pdfDocs by user id from the API fail`,
    props<{ error: any }>()
);
//#endregion

//#region Roles
export const GET_ROLES = createAction(`${actionPrefixRole} Get all roles from the API`);
export const GET_ROLES_SUCCESS = createAction(
    `${actionPrefixRole} Get all roles from the API success`,
    props<{ roles: fromModel.Role[] }>()
);
export const GET_ROLES_FAIL = createAction(
    `${actionPrefixRole} Get all roles from the API fail`,
    props<{ error: any }>()
);

export const GET_ROLES_BY_ID = createAction(
    `${actionPrefixRole} Get roles by id from the API`,
    props<{ roleId: string }>()
);
export const GET_ROLES_BY_ID_SUCCESS = createAction(
    `${actionPrefixRole} Get roles by id from the API success`,
    props<{ role: fromModel.Role[] }>()
);
export const GET_ROLES_BY_ID_FAIL = createAction(
    `${actionPrefixRole} Get roles by id from the API fail`,
    props<{ error: any }>()
);

export const GET_ROLES_BY_NAME = createAction(
    `${actionPrefixRole} Get roles by name from the API`,
    props<{ name: string }>()
);
export const GET_ROLES_BY_NAME_SUCCESS = createAction(
    `${actionPrefixRole} Get roles by name from the API success`,
    props<{ isValid: boolean | string }>()
);
export const GET_ROLES_BY_NAME_FAIL = createAction(
    `${actionPrefixRole} Get roles by name from the API fail`,
    props<{ error: any }>()
);

export const UPDATE_ROLE = createAction(
    `${actionPrefixRole} Update role object`,
    props<{ role: fromModel.Role }>()
);
export const UPDATE_ROLE_SUCCESS = createAction(
    `${actionPrefixRole} Update role object success`,
    props<{ role: fromModel.Role[] }>()
);
export const UPDATE_ROLE_FAIL = createAction(
    `${actionPrefixRole} Update role object fail`,
    props<{ error: any }>()
);

export const CREATE_ROLE = createAction(
    `${actionPrefixRole} Create role object`,
    props<{ role: fromModel.Role }>()
);
export const CREATE_ROLE_SUCCESS = createAction(
    `${actionPrefixRole} Create role object success`,
    props<{ role: fromModel.Role[] }>()
);
export const CREATE_ROLE_FAIL = createAction(
    `${actionPrefixRole} Create role object fail`,
    props<{ error: any }>()
);
//#endregion
