//#region Imports
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpResponse } from '@angular/common/http';
import * as fromService from './auth.service';
import { tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import * as fromStore from '@core/store';
//#endregion

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(
        private authService: fromService.AuthService,
        private store: Store<fromStore.CoreState>
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        const authToken = this.authService.getToken();
        const reqUrl = req.url.split('/');
        if (reqUrl[reqUrl.length - 1] === 'create-procedure') {
            req = req.clone({
                setHeaders: {
                    Authorization: 'Bearer ' + authToken
                }
            });
        } else {
            req = req.clone({
                setHeaders: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + authToken
                }
            });
        }
        return next.handle(req).pipe(
            tap({
                next: (event) => {
                    if (event instanceof HttpResponse) {
                        const userData = localStorage.getItem('access_token');
                        if (userData) {
                            localStorage.setItem('access_token', userData);
                            this.store.dispatch(
                                fromStore.LOGIN_SUCCESS({
                                    token: userData
                                })
                            );
                        }
                    }
                }
            })
        );
    }
}
