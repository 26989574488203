//#region Imports
import * as fromActions from './../actions';
import { createReducer, on } from '@ngrx/store';
import * as fromModel from '@shared/models';
//#endregion

export interface PdfDocsState {
    documents: { [pdfdocsId: string]: fromModel.PdfDocs };
    total: number;
    selectedPdfDocs: fromModel.PdfDocs | null;

    isLoading: boolean;
    isLoaded: boolean;
    error: fromModel.ErrorModel | null;
}

export const initialState: PdfDocsState = {
    documents: {},
    total: 0,
    selectedPdfDocs: null,

    isLoaded: false,
    isLoading: false,
    error: null
};

const PdfDocsReducerState = createReducer(
    initialState,
    on(fromActions.GET_PDF_DOCS_QUERY_PARAMS, (state: PdfDocsState) => {
        return {
            ...state,
            documents: {},
            isLoaded: false,
            isLoading: true,
            error: null
        };
    }),
    on(fromActions.GET_PDF_DOCS_QUERY_PARAMS_SUCCESS, (state: PdfDocsState, payload: any) => {
        const getPdfDocs: fromModel.PdfDocs[] = payload.pdfDocs.msg;
        const total: number = payload.pdfDocs.total;
        const entities = getPdfDocs.reduce(
            (entity: { [pdfdocsId: string]: fromModel.PdfDocs }, pdfDocs: fromModel.PdfDocs) => {
                return {
                    ...entity,
                    [pdfDocs.pdfdocsId]: pdfDocs
                };
            },
            {}
        );
        return {
            ...state,
            documents: entities,
            total,
            isLoaded: true,
            isLoading: false,
            error: null
        };
    }),
    on(fromActions.GET_PDF_DOCS_QUERY_PARAMS_FAIL, (state: PdfDocsState, payload: any) => {
        const error: fromModel.ErrorModel = payload.error;
        return {
            ...state,
            pdfDocs: {},
            total: 0,
            isLoaded: false,
            isLoading: false,
            error
        };
    })
);

/**
 *
 * @param {PdfDocsState} state
 * @param {PdfDocsActions} action
 * @return {PdfDocsReducerState} PdfDocsReducerState
 */
export function pdfDocsReducer(state: PdfDocsState, action: any) {
    return PdfDocsReducerState(state, action);
}
