import { ArticleEffects } from './article.effects';
import { UserEffects } from './user.effects';
import { DepartmentEffects } from './department.effects';
import { PositionEffects } from './position.effects';
import { ClientEffects } from './client.effects';
import { AuthEffects } from './auth.effects';
import { QRCodeEffects } from './qrcode.effects';
import { ReportEffects } from './report.effects';
import { PdfDocsEffects } from './pdf-docs.effects';

export * from './article.effects';
export * from './user.effects';
export * from './department.effects';
export * from './position.effects';
export * from './client.effects';
export * from './auth.effects';
export * from './qrcode.effects';
export * from './report.effects';
export * from './pdf-docs.effects';

export const effects: any[] = [
    ArticleEffects,
    UserEffects,
    DepartmentEffects,
    PositionEffects,
    ClientEffects,
    AuthEffects,
    QRCodeEffects,
    ReportEffects,
    PdfDocsEffects
];
