//#region Imports
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { PdfDocsService } from '@core/http/services/pdf-docs.service';
import * as fromActions from '../actions';
import * as fromMappings from '@core/mappings';
import * as fromModel from '@shared/models';
//#endregion

@Injectable()
export class PdfDocsEffects {
    loadDepartmentsWithQueryParams$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.GET_PDF_DOCS_QUERY_PARAMS),
            mergeMap((props: { searchQuery: fromModel.ListQueryParams }) =>
                this.pdfDocsService.getPdfDocsWithQueryParams(props.searchQuery).pipe(
                    map((pdfDocs: fromModel.PdfDocs[]) => {
                        return fromActions.GET_PDF_DOCS_QUERY_PARAMS_SUCCESS({ pdfDocs });
                    }),
                    catchError((error: Error) => {
                        return of(
                            fromActions.GET_PDF_DOCS_QUERY_PARAMS_FAIL({
                                error: fromMappings.ToError(error)
                            })
                        );
                    })
                )
            )
        )
    );
    //#endregion

    constructor(
        private actions$: Actions,
        private pdfDocsService: PdfDocsService
    ) {}
}
