//#region Imports
import { Injectable } from '@angular/core';
import { environment as env } from '@env/environment';
import { HttpClient } from '@angular/common/http';

import { map } from 'rxjs/operators';
import * as fromModel from '@shared/models';
import { Observable } from 'rxjs';
import { createSearchQueryParams } from '@shared/functions/functions';
//#endregion

@Injectable({
    providedIn: 'root'
})
export class QRCodeService {
    endpoint: string = env.server;

    constructor(private http: HttpClient) {}

    //#region QRCode
    getQRCodesWithQueryParams(
        searchQuery: fromModel.ListQueryParams
    ): Observable<fromModel.QRCode[]> {
        return this.http.get<fromModel.QRCode[]>(
            `${this.endpoint}get-qrcodes-by-query${createSearchQueryParams(searchQuery)}`
        );
    }

    getQRCodes(): Observable<fromModel.QRCode[]> {
        return this.http.get<fromModel.QRCode[]>(`${this.endpoint}get-qrcodes`);
    }

    getQRCodeById(qrcodeId: string): Observable<fromModel.QRCode[]> {
        return this.http.get<fromModel.QRCode[]>(
            `${this.endpoint}read-qrcode?qrCodeId=${qrcodeId}`
        );
    }

    createQRCode(qrcode: fromModel.QRCode): Observable<any> {
        return this.http.post<fromModel.QRCode>(`${this.endpoint}create-qrcode`, qrcode);
    }
    //#endregion
}
