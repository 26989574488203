//#region Imports
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { NotificationService } from '@core/notification/notification.service';
import { ReportsService } from '@core/http/services/reports.service';
import { ReportSearchQuery, ReportData, UserEmailNotify, ListQueryParams } from '@shared/models';
import { ToError } from '@core/mappings';
import {
    CREATE_REPORT,
    CREATE_REPORT_SUCCESS,
    CREATE_REPORT_FAIL,
    CREATE_USER_EMAIL_NOTIFY,
    CREATE_USER_EMAIL_NOTIFY_SUCCESS,
    CREATE_USER_EMAIL_NOTIFY_FAIL,
    GET_USER_EMAIL_NOTIFY,
    GET_USER_EMAIL_NOTIFY_SUCCESS,
    GET_USER_EMAIL_NOTIFY_FAIL,
    GET_EMAIL_NOTIFY,
    GET_EMAIL_NOTIFY_SUCCESS,
    GET_EMAIL_NOTIFY_FAIL
} from '../actions';
//#endregion

@Injectable()
export class ReportEffects {
    createReport$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CREATE_REPORT),
            mergeMap((props: { reportQuery: ReportSearchQuery }) =>
                this.reportService.createReport(props.reportQuery).pipe(
                    map((reportData: ReportData[]) => {
                        this.notificationService.success('success.report.reportCreated');
                        return CREATE_REPORT_SUCCESS({ reportData });
                    }),
                    catchError((error: Error) => {
                        this.notificationService.error('error.report.reportFailed');
                        return of(CREATE_REPORT_FAIL({ error: ToError(error) }));
                    })
                )
            )
        )
    );

    userEmailNotify$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CREATE_USER_EMAIL_NOTIFY),
            mergeMap((props: { userSetup: UserEmailNotify }) =>
                this.reportService.userEmailNotify(props.userSetup).pipe(
                    map((setupData: any) => {
                        // this.notificationService.success('success.report.reportCreated');
                        return CREATE_USER_EMAIL_NOTIFY_SUCCESS({ setupData });
                    }),
                    catchError((error: Error) => {
                        this.notificationService.error(ToError(error).message);
                        return of(
                            CREATE_USER_EMAIL_NOTIFY_FAIL({
                                error: ToError(error)
                            })
                        );
                    })
                )
            )
        )
    );

    getUserEmailNotify$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GET_EMAIL_NOTIFY),
            mergeMap((props: { searchQuery: ListQueryParams }) =>
                this.reportService.getUserEmailNotify(props.searchQuery).pipe(
                    map((setupData: UserEmailNotify[]) => {
                        return GET_EMAIL_NOTIFY_SUCCESS({ setupData });
                    }),
                    catchError((error: Error) => {
                        return of(
                            GET_EMAIL_NOTIFY_FAIL({
                                error: ToError(error)
                            })
                        );
                    })
                )
            )
        )
    );

    getUserEmailNotifyByUserId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GET_USER_EMAIL_NOTIFY),
            mergeMap((props: { userId: string }) =>
                this.reportService.getUserEmailNotifyByUserId(props.userId).pipe(
                    map((setupData: UserEmailNotify[]) => {
                        return GET_USER_EMAIL_NOTIFY_SUCCESS({ setupData });
                    }),
                    catchError((error: Error) => {
                        return of(
                            GET_USER_EMAIL_NOTIFY_FAIL({
                                error: ToError(error)
                            })
                        );
                    })
                )
            )
        )
    );
    //#endregion

    constructor(
        private actions$: Actions,
        private reportService: ReportsService,
        private notificationService: NotificationService
    ) {}
}
