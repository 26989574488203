import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'ngForSortByDate'
})
export class NgForSortByDatePipe implements PipeTransform {
    transform(value: any[], ...args: any[]): any {
        return value.sort((a1, a2) => {
            return new Date(a1[args[0]]).getTime() - new Date(a2[args[0]]).getTime();
        });
    }
}

