//#region Imports
import { Injectable } from '@angular/core';
import { environment as env } from '@env/environment';
import { HttpClient } from '@angular/common/http';

import { map } from 'rxjs/operators';
import * as fromModel from '@shared/models';
import { Observable } from 'rxjs';
import { createSearchQueryParams } from '@shared/functions/functions';
//#endregion

@Injectable({
    providedIn: 'root'
})
export class PositionService {
    endpoint: string = env.server;

    constructor(private http: HttpClient) {}

    //#region Position
    getPositionsWithQueryParams(
        searchQuery: fromModel.ListQueryParams
    ): Observable<fromModel.Position[]> {
        return this.http.get<fromModel.Position[]>(
            `${this.endpoint}get-positions-by-query${createSearchQueryParams(searchQuery)}`
        );
    }

    getPositions(): Observable<fromModel.Position[]> {
        return this.http.get<fromModel.Position[]>(`${this.endpoint}get-positions`);
    }

    getPositionById(positionId: string): Observable<fromModel.Position[]> {
        return this.http.get<fromModel.Position[]>(
            `${this.endpoint}read-position?positionId=${positionId}`
        );
    }

    getPositionByUserId(userId: string): Observable<fromModel.Position[]> {
        return this.http.get<fromModel.Position[]>(
            `${this.endpoint}get-position-by-user?userId=${userId}`
        );
    }

    updatePosition(position: fromModel.Position): Observable<any> {
        return this.http.put<fromModel.Position>(
            `${this.endpoint}update-position?positionId=${position.positionId}`,
            position
        );
    }

    createPosition(position: fromModel.Position): Observable<any> {
        return this.http.post<fromModel.Position>(`${this.endpoint}create-position`, position);
    }
    //#endregion
}
