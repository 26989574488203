//#region Imports
import * as fromActions from './../actions';
import { createReducer, on } from '@ngrx/store';
import * as fromModel from '@shared/models';
//#endregion

export interface QRCodeState {
    qrcodes: { [qrCodeId: string]: fromModel.QRCode };
    total: number;
    selectedQRCode: fromModel.QRCode | null;

    isLoading: boolean;
    isLoaded: boolean;
    error: fromModel.ErrorModel | null;
}

export const initialState: QRCodeState = {
    qrcodes: {},
    total: 0,
    selectedQRCode: null,

    isLoaded: false,
    isLoading: false,
    error: null
};

const qrcodeReducerState = createReducer(
    initialState,
    on(
        fromActions.GET_QRCODE_QUERY_PARAMS,
        fromActions.GET_QRCODE,
        fromActions.GET_QRCODE_BY_ID,
        fromActions.CREATE_QRCODE,
        (state: QRCodeState) => {
            return {
                ...state,
                isLoaded: false,
                isLoading: true,
                error: null
            };
        }
    ),
    on(fromActions.GET_QRCODE_QUERY_PARAMS_SUCCESS, (state: QRCodeState, payload: any) => {
        const getQRCode: fromModel.QRCode[] = payload.qrCodes.msg;
        const total: number = payload.qrCodes.total;
        const entities = getQRCode.reduce(
            (
                entity: { [qrCodeId: string]: fromModel.QRCode },
                qrcode: fromModel.QRCode
            ) => {
                return {
                    ...entity,
                    [qrcode.qrCodeId]: qrcode
                };
            },
            {}
        );
        return {
            ...state,
            qrcodes: entities,
            total,
            isLoaded: true,
            isLoading: false,
            error: null
        };
    }),
    on(fromActions.GET_QRCODE_QUERY_PARAMS_FAIL, (state: QRCodeState, payload: any) => {
        const error: fromModel.ErrorModel = payload.error;
        return {
            ...state,
            qrcodes: {},
            total: 0,
            isLoaded: false,
            isLoading: false,
            error
        };
    }),

    on(fromActions.GET_QRCODE_SUCCESS, (state: QRCodeState, payload: any) => {
        const getQRCode: fromModel.QRCode[] = payload.qrCodes.msg;
        const entities = getQRCode.reduce(
            (
                entity: { [qrCodeId: string]: fromModel.QRCode },
                qrcode: fromModel.QRCode
            ) => {
                return {
                    ...entity,
                    [qrcode.qrCodeId]: qrcode
                };
            },
            {
                ...state.qrcodes
            }
        );
        return {
            ...state,
            qrcodes: entities,
            isLoaded: true,
            isLoading: false,
            error: null
        };
    }),
    on(
        fromActions.GET_QRCODE_FAIL,
        fromActions.GET_QRCODE_BY_ID_FAIL,
        fromActions.CREATE_QRCODE_FAIL,
        (state: QRCodeState, payload: any) => {
            const error: fromModel.ErrorModel = payload.error;
            return {
                ...state,
                isLoaded: false,
                isLoading: false,
                error
            };
        }
    ),

    on(fromActions.GET_QRCODE_BY_ID_SUCCESS, (state: QRCodeState, payload: any) => {
        return {
            ...state,
            selectedQRCode: payload.qrCode.msg[0],
            isLoaded: true,
            isLoading: false,
            error: null
        };
    }),

    on(fromActions.CREATE_QRCODE_SUCCESS, (state: QRCodeState, payload: any) => {
        const createQRCode: fromModel.QRCode = payload.qrCodes.msg[0];
        const qrcodes = {
            ...state.qrcodes,
            [createQRCode.qrCodeId]: createQRCode
        };
        return {
            ...state,
            qrcodes,
            isLoaded: true,
            isLoading: false,
            error: null
        };
    })
);

/**
 *
 * @param {QRCodeState} state
 * @param {QRCodeActions} action
 * @return {qrcodeReducerState} qrcodeReducerState
 */
export function qrcodeReducer(state: QRCodeState, action: any) {
    return qrcodeReducerState(state, action);
}
