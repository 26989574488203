<div class="login-wrap">
    <mat-card>
        <mat-card-title> Register </mat-card-title>
        <mat-card-subtitle> Register user to the application </mat-card-subtitle>
        <mat-card-content>
            <form [formGroup]="registerForm" class="login-container" (ngSubmit)="register()">
                <div>
                    <mat-form-field>
                        <mat-label>First name</mat-label>
                        <input
                            matInput
                            type="text"
                            placeholder="First name"
                            formControlName="firstName"
                        />
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>Last name</mat-label>
                        <input
                            matInput
                            type="text"
                            placeholder="Last name"
                            formControlName="lastName"
                        />
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>Middle name</mat-label>
                        <input
                            matInput
                            type="text"
                            placeholder="Middle name"
                            formControlName="middleName"
                        />
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>Username</mat-label>
                        <input
                            matInput
                            type="text"
                            placeholder="Username"
                            formControlName="username"
                        />
                    </mat-form-field>
                </div>

                <div>
                    <mat-form-field>
                        <mat-label>Birthday</mat-label>
                        <input
                            matInput
                            [min]="minDate"
                            [max]="maxDate"
                            [matDatepicker]="picker"
                            formControlName="birthday"
                        />
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker disabled="false"></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>Phone</mat-label>
                        <input matInput type="text" placeholder="Phone" formControlName="phone" />
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>Mobile</mat-label>
                        <input matInput type="text" placeholder="Mobile" formControlName="mobile" />
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>Email</mat-label>
                        <input matInput type="email" placeholder="Email" formControlName="email" />
                    </mat-form-field>
                </div>

                <div>
                    <mat-form-field>
                        <mat-label>Password</mat-label>
                        <input
                            matInput
                            type="password"
                            placeholder="Password"
                            formControlName="password"
                        />
                    </mat-form-field>

                    <!-- <mat-form-field>
                        <mat-label>Gender</mat-label>
                        <input matInput type="number" placeholder="Gender" formControlName="gender">
                    </mat-form-field> -->
                    <mat-form-field>
                        <mat-label>Choose Gender</mat-label>
                        <mat-select formControlName="gender">
                            <mat-option *ngFor="let gnd of gender" [value]="gnd.value">
                                {{ gnd.viewValue }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>Address</mat-label>
                        <input
                            matInput
                            type="text"
                            placeholder="Address"
                            formControlName="address"
                        />
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>Occupation</mat-label>
                        <input
                            matInput
                            type="text"
                            placeholder="Occupation"
                            formControlName="occupation"
                        />
                    </mat-form-field>
                </div>

                <div>
                    <mat-form-field>
                        <mat-label>Work experience</mat-label>
                        <input
                            matInput
                            type="text"
                            placeholder="Work experience"
                            formControlName="workExperience"
                        />
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>Note</mat-label>
                        <input matInput type="text" placeholder="Note" formControlName="note" />
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>Choose roles</mat-label>
                        <mat-select multiple formControlName="roles">
                            <mat-option *ngFor="let rol of test" [value]="rol.roleId">
                                {{ rol.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-checkbox formControlName="active">Active</mat-checkbox>
                </div>

                <p>
                    <button mat-raised-button value="register">Register</button>
                </p>
            </form>
            <!-- <button mat-raised-button (click)="logOut()">Logout</button> -->
        </mat-card-content>
    </mat-card>
</div>

<!-- <button mat-raised-button (click)="logIn()">Login</button> -->
