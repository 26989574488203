//#region Imports
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from './modules/material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ImageCropperModule } from 'ngx-image-cropper';
import * as fromComponent from './components';
import * as directive from '@shared/directives';
import * as fromPipe from '@shared/pipe';
import { QRCodeModule } from 'angularx-qrcode';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NgxChartsModule } from '@swimlane/ngx-charts';
//#endregion

@NgModule({
    declarations: [...fromComponent.components, ...directive.directive, ...fromPipe.pipes],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        TranslateModule,
        ImageCropperModule,
        QRCodeModule,
        NgxSkeletonLoaderModule,
        NgxChartsModule
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        TranslateModule,
        ImageCropperModule,
        QRCodeModule,
        NgxSkeletonLoaderModule,
        NgxChartsModule,
        ...directive.directive,
        ...fromPipe.pipes,
        ...fromComponent.components
    ],
    providers: []
})
export class SharedModule {}
