//#region Imports
import { createSelector } from '@ngrx/store';
import { toObjectArray } from '@shared/functions/functions';
import * as fromFeature from './../reducers';
//#endregion

const positionState = createSelector(
    fromFeature.getCoreState,
    (state: fromFeature.CoreState) => state.position
);

const getAllPositions = createSelector(positionState, (state) => toObjectArray(state.positions));

const getAllPositionsSimple = createSelector(positionState, (state) =>
    toObjectArray(state.positionSimple)
);

const getPositionById = createSelector(positionState, (state) => state.selectedPosition);

const getPositionTotalNumberOfEntities = createSelector(positionState, (state) => state.total);

const getPositionIsLoaded = createSelector(positionState, (state) => state.isLoaded);

const getPositionIsLoading = createSelector(positionState, (state) => state.isLoading);

const getPositionError = createSelector(positionState, (state) => state.error);

export const PositionSelectors = {
    positionState,
    getAllPositions,
    getAllPositionsSimple,
    getPositionById,
    getPositionTotalNumberOfEntities,
    getPositionIsLoaded,
    getPositionIsLoading,
    getPositionError
};
