//#region Imports
import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromStore from '@core/store';
import * as fromModel from '@shared/models';
import { isEmpty } from 'lodash-es';
import { Observable } from 'rxjs';
import { AuthSelectors } from '@core/store';
//#endregion

interface RoleLevel {
    name: string;
    level: number;
}

/**
 * This directive check if logged in user have access to the resorce.
 */
@Directive({
    selector: '[ecHasAccess]'
})
export class HasAccessDirective {
    @Input() set ecHasAccess(roleLevel: number) {
        this.checkClaims(roleLevel);
    }

    /**
     * This function create array of user roles
     * formated as {name: string, level: number}
     * @param {fromModel.User} user Logged in user
     * @return {RoleLevel} roleLevel Array of role levels
     */
    private getRoleValues(user: fromModel.User): RoleLevel {
        const roleLevels: RoleLevel = { name: user.roleName, level: user.roleLevel };
        return roleLevels;
    }

    /**
     * This function gets user roles from the logged-in user
     * and if the user has a high enough role level for the resource
     * returns it and if not clears the view.
     * @param {number} roleLevel level of user role
     * @return {void}
     */
    private checkClaims(roleLevel: any): void {
        const loggedInUser$: Observable<fromModel.User> = this.store.select<any>(
            AuthSelectors.getLoggedInUser
        );
        let userClaims: RoleLevel;
        loggedInUser$.pipe().subscribe((user: fromModel.User) => {
            if (isEmpty(user)) {
                return null;
            }
            userClaims = this.getRoleValues(user);
        });

        if (userClaims.level >= roleLevel.role) {
            this.viewContainer.createEmbeddedView(this.templateRef);
            // break;
        } else {
            this.viewContainer.clear();
        }
    }

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private store: Store<fromStore.CoreState>
    ) {}
}
