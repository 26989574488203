//#region Imports
import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import * as fromRouter from '@ngrx/router-store';
import { routerReducer } from '@ngrx/router-store';

import { authReducer, AuthState } from './auth.reducer';
import { userReducer, UserState } from './user.reducer';
import { articleReducer, ArticleState } from './article.reducer';
import { departmentReducer, DepartmentState } from './department.reducer';
import { positionReducer, PositionState } from './position.reducer';
import { clientReducer, ClientState } from './client.reducer';
import { qrcodeReducer, QRCodeState } from './qrcode.reducer';
import { reportReducer, ReportState } from './report.reducer';
import { pdfDocsReducer, PdfDocsState } from './pdf-docs.reducer';
import { globalReducer, GlobalState } from './global.reducer';
//#endregion

export interface CoreState {
    router: fromRouter.RouterReducerState<any>;
    global: GlobalState;
    loggedIn: AuthState;
    user: UserState;
    client: ClientState;
    department: DepartmentState;
    position: PositionState;
    article: ArticleState;
    qrCode: QRCodeState;
    report: ReportState;
    pdfDocs: PdfDocsState;
}

export const reducers: ActionReducerMap<CoreState> = {
    router: routerReducer,
    global: globalReducer,
    loggedIn: authReducer,
    user: userReducer,
    client: clientReducer,
    department: departmentReducer,
    position: positionReducer,
    article: articleReducer,
    qrCode: qrcodeReducer,
    report: reportReducer,
    pdfDocs: pdfDocsReducer
};

export const getCoreState = createFeatureSelector<CoreState>('core');
