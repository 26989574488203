//#region Imports
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, UrlSegment } from '@angular/router';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';

import * as fromStore from '@core/store';
import { tap } from 'rxjs/operators';
import { AuthSelectors } from '@core/store';
//#endregion

@Injectable()
export class AdminGuard  {
    constructor(private store: Store<fromStore.CoreState>, private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.store.pipe(
            select(AuthSelectors.isAdminSupervisor),
            tap((loggedIn) => {
                if (!loggedIn) {
                    this.router.navigate(['article-charge']);
                }
            })
        );
    }
    canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> {
        return this.store.pipe(
            select(AuthSelectors.isAdminSupervisor),
            tap((loggedIn) => {
                if (!loggedIn) {
                    this.router.navigate(['article-charge']);
                }
            })
        );
    }
}
