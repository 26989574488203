import { createAction, props } from '@ngrx/store';
import * as fromModel from '@shared/models';

const actionName = '[Client]';

export const GET_CLIENT_QUERY_PARAMS = createAction(
    `${actionName} Get clients by query params from the API`,
    props<{ searchQuery: fromModel.ListQueryParams }>()
);
export const GET_CLIENT_QUERY_PARAMS_SUCCESS = createAction(
    `${actionName} Get clients by query params from the API success`,
    props<{ clients: fromModel.Client[] }>()
);
export const GET_CLIENT_QUERY_PARAMS_FAIL = createAction(
    `${actionName} Get clients by query params from the API fail`,
    props<{ error: any }>()
);

export const GET_CLIENT = createAction(
    `${actionName} Get all clients from the API`,
    props<{ active: number }>()
);
export const GET_CLIENT_SUCCESS = createAction(
    `${actionName} Get all clients from the API success`,
    props<{ clients: fromModel.Client[] }>()
);
export const GET_CLIENT_FAIL = createAction(
    `${actionName} Get all clients from the API fail`,
    props<{ error: any }>()
);

export const GET_CLIENT_BY_ID = createAction(
    `${actionName} Get client by id from the API`,
    props<{ clientId: string }>()
);
export const GET_CLIENT_BY_ID_SUCCESS = createAction(
    `${actionName} Get client by id from the API success`,
    props<{ client: fromModel.Client[] }>()
);
export const GET_CLIENT_BY_ID_FAIL = createAction(
    `${actionName} Get client by id from the API fail`,
    props<{ error: any }>()
);

export const GET_CLIENT_BY_USER_ID = createAction(
    `${actionName} Get client by user id from the API`,
    props<{ userId: string }>()
);
export const GET_CLIENT_BY_USER_ID_SUCCESS = createAction(
    `${actionName} Get client by user id from the API success`,
    props<{ clients: fromModel.Client[] }>()
);
export const GET_CLIENT_BY_USER_ID_FAIL = createAction(
    `${actionName} Get client by user id from the API fail`,
    props<{ error: any }>()
);

export const UPDATE_CLIENT = createAction(
    `${actionName} Update client object`,
    props<{ client: fromModel.Client }>()
);
export const UPDATE_CLIENT_SUCCESS = createAction(
    `${actionName} Update client object success`,
    props<{ client: fromModel.Client[] }>()
);
export const UPDATE_CLIENT_FAIL = createAction(
    `${actionName} Update client object success fail`,
    props<{ error: any }>()
);

export const CREATE_CLIENT = createAction(
    `${actionName} Create client object`,
    props<{ client: fromModel.Client }>()
);
export const CREATE_CLIENT_SUCCESS = createAction(
    `${actionName} Create client object success`,
    props<{ client: fromModel.Client[] }>()
);
export const CREATE_CLIENT_FAIL = createAction(
    `${actionName} Create client object success fail`,
    props<{ error: any }>()
);
