//#region Imports
import * as fromActions from './../actions';
import { createReducer, on } from '@ngrx/store';
import * as fromModel from '@shared/models';
//#endregion

export interface UserState {
    roles: { [roleId: string]: fromModel.Role };
    totalRole: number;
    selectedRole: fromModel.Role | null;
    roleNameCheck: boolean;

    users: { [userId: string]: fromModel.User };
    usersSimple: { [userId: string]: fromModel.User };
    userPdf: { [pdfdocsId: string]: fromModel.PdfDocs };
    userChargedItems: { [articleId: string]: fromModel.UserActiveError };
    totalUser: number;
    selectedUser: fromModel.User | null;
    usernameCheck: boolean;
    fullNameCheck: boolean;

    isExternalScan: boolean;
    isUserLoading: boolean;
    isUserLoaded: boolean;
    isRoleLoading: boolean;
    isRoleLoaded: boolean;
    isPdfDocsLoading: boolean;
    isPdfDocsLoaded: boolean;
    isChargedItemsLoading: boolean;
    isChargedItemsLoaded: boolean;
    error: fromModel.ErrorModel | null;
}

export const initialState: UserState = {
    roles: {},
    users: {},
    usersSimple: {},
    userPdf: {},
    userChargedItems: {},
    usernameCheck: false,
    fullNameCheck: null,
    roleNameCheck: false,
    selectedRole: null,
    selectedUser: null,
    totalRole: 0,
    totalUser: 0,
    isExternalScan: null,
    isUserLoaded: false,
    isUserLoading: false,
    isRoleLoaded: false,
    isRoleLoading: false,
    isPdfDocsLoaded: false,
    isPdfDocsLoading: false,
    isChargedItemsLoading: false,
    isChargedItemsLoaded: false,
    error: null
};

const userReducerState = createReducer(
    initialState,
    //#region Roles
    on(
        fromActions.GET_ROLES,
        fromActions.GET_ROLES_BY_ID,
        fromActions.UPDATE_ROLE,
        fromActions.CREATE_ROLE,
        (state: UserState) => {
            return {
                ...state,
                isRoleLoaded: false,
                isRoleLoading: true,
                error: null
            };
        }
    ),
    on(fromActions.GET_ROLES_SUCCESS, (state: UserState, payload: any) => {
        const getRoles: fromModel.Role[] = payload.roles.msg;
        const entities = getRoles.reduce(
            (entity: { [id: number]: fromModel.Role }, role: fromModel.Role) => {
                return {
                    ...entity,
                    [role.roleId]: role
                };
            },
            {
                ...state.roles
            }
        );
        return {
            ...state,
            roles: entities,
            roleChanges: {},
            isRoleLoaded: true,
            isRoleLoading: false,
            error: null
        };
    }),
    on(
        fromActions.GET_ROLES_FAIL,
        fromActions.GET_ROLES_BY_ID_FAIL,
        fromActions.UPDATE_ROLE_FAIL,
        fromActions.CREATE_ROLE_FAIL,
        (state: UserState, payload: any) => {
            const error: fromModel.ErrorModel = payload.error;
            return {
                ...state,
                isRoleLoaded: false,
                isRoleLoading: false,
                error
            };
        }
    ),

    on(fromActions.GET_ROLES_BY_ID_SUCCESS, (state: UserState, payload: any) => {
        return {
            ...state,
            selectedRole: payload.role.msg[0],
            isRoleLoaded: true,
            isRoleLoading: false,
            error: null
        };
    }),

    on(fromActions.GET_ROLES_BY_NAME, (state: UserState) => {
        return {
            ...state,
            roleNameCheck: false,
            isRoleLoaded: false,
            isRoleLoading: true,
            error: null
        };
    }),
    on(fromActions.GET_ROLES_BY_NAME_SUCCESS, (state: UserState, payload: any) => {
        return {
            ...state,
            roleNameCheck: payload.isValid.msg[0],
            isRoleLoaded: true,
            isRoleLoading: false,
            error: null
        };
    }),
    on(fromActions.GET_ROLES_BY_NAME_FAIL, (state: UserState, payload: any) => {
        const error: fromModel.ErrorModel = payload.error;
        return {
            ...state,
            roleNameCheck: payload.role.msg,
            isRoleLoaded: false,
            isRoleLoading: false,
            error
        };
    }),

    on(
        fromActions.CREATE_ROLE_SUCCESS,
        fromActions.UPDATE_ROLE_SUCCESS,
        (state: UserState, payload: any) => {
            const role: fromModel.Role = payload.role.msg[0];
            const roles = {
                ...state.roles,
                [role.roleId]: role
            };
            return {
                ...state,
                roles,
                isRoleLoaded: true,
                isRoleLoading: false,
                error: null
            };
        }
    ),
    //#endregion

    //#region User
    on(fromActions.GET_USER_QUERY_PARAMS, (state: UserState) => {
        return {
            ...state,
            userChargedItems: {},
            total: 0,
            isUserLoaded: false,
            isUserLoading: true,
            error: null
        };
    }),
    on(fromActions.GET_USER_QUERY_PARAMS_SUCCESS, (state: UserState, payload: any) => {
        const getUsers: fromModel.User[] = payload.users.msg;
        const total: number = payload.users.total;
        const entities = getUsers.reduce(
            (entity: { [id: number]: fromModel.User }, user: fromModel.User) => {
                return {
                    ...entity,
                    [user.userId]: user
                };
            },
            {}
        );
        return {
            ...state,
            users: entities,
            userChargedItems: {},
            userCon: {},
            userWP: {},
            userCOI: {},
            userSP: {},
            userWExp: {},
            userDaysOff: {},
            selectedUserComment: {},
            totalUser: total,
            isUserLoaded: true,
            isUserLoading: false,
            error: null
        };
    }),
    on(fromActions.GET_USER_QUERY_PARAMS_FAIL, (state: UserState, payload: any) => {
        const error: fromModel.ErrorModel = payload.error;
        return {
            ...state,
            users: {},
            userChargedItems: {},
            totalUser: 0,
            isUserLoaded: false,
            isUserLoading: false,
            error
        };
    }),

    on(
        fromActions.GET_USER,
        fromActions.GET_USER_BY_ID,
        fromActions.GET_USER_BY_EXTERNAL_ID,
        fromActions.GET_USER_BY_DEPARTMENT_ID,
        fromActions.GET_USER_BY_CLIENT_DEPARTMENT_ID,
        fromActions.UPDATE_USER,
        fromActions.CREATE_USER,
        (state: UserState) => {
            return {
                ...state,
                isUserLoaded: false,
                isUserLoading: true,
                error: null
            };
        }
    ),
    on(fromActions.GET_PDF_DOCS_BY_USER_ID, (state: UserState) => {
        return {
            ...state,
            userPdf: {},
            isPdfDocsLoaded: false,
            isPdfDocsLoading: true,
            error: null
        };
    }),

    on(fromActions.GET_PDF_DOCS_BY_USER_ID_SUCCESS, (state: UserState, payload: any) => {
        const pdfDocs: fromModel.PdfDocs[] = payload.pdfDocs?.msg;
        const entities = pdfDocs.reduce(
            (entity: { [pdfdocsId: string]: fromModel.PdfDocs }, pdfDocs: fromModel.PdfDocs) => {
                return {
                    ...entity,
                    [pdfDocs.pdfdocsId]: pdfDocs
                };
            },
            {}
        );
        return {
            ...state,
            userPdf: entities,
            isPdfDocsLoaded: true,
            isPdfDocsLoading: false,
            error: null
        };
    }),
    on(fromActions.GET_USER_SUCCESS, (state: UserState, payload: any) => {
        const getUsers: fromModel.User[] = payload.userSimple.msg;
        const entities = getUsers.reduce(
            (entity: { [id: number]: fromModel.User }, user: fromModel.User) => {
                return {
                    ...entity,
                    [user.userId]: user
                };
            },
            {
                ...state.usersSimple
            }
        );

        return {
            ...state,
            usersSimple: entities,
            isUserLoaded: true,
            isUserLoading: false,
            error: null
        };
    }),
    on(
        fromActions.GET_USER_FAIL,
        fromActions.GET_USER_BY_ID_FAIL,
        fromActions.GET_USER_BY_EXTERNAL_ID_FAIL,
        fromActions.UPDATE_USER_FAIL,
        fromActions.CREATE_USER_FAIL,
        (state: UserState, payload: any) => {
            const error: fromModel.ErrorModel = payload.error;
            return {
                ...state,
                isUserLoaded: false,
                isUserLoading: false,
                error
            };
        }
    ),
    on(fromActions.GET_PDF_DOCS_BY_USER_ID_FAIL, (state: UserState, payload: any) => {
        const error: fromModel.ErrorModel = payload.error;
        return {
            ...state,
            userPdf: {},
            isPdfDocsLoaded: false,
            isPdfDocsLoading: false,
            error
        };
    }),

    on(fromActions.UPDATE_USER_ACTIVE, (state: UserState) => {
        return {
            ...state,
            userChargedItems: {},
            isChargedItemsLoaded: false,
            isChargedItemsLoading: true,
            error: null
        };
    }),
    on(fromActions.UPDATE_USER_ACTIVE_FAIL, (state: UserState, payload: any) => {
        const error: fromModel.ErrorModel = payload.error.error;
        const chargedArticles = payload.error.error.value;
        const entities = chargedArticles.reduce(
            (
                entity: { [articleId: string]: fromModel.UserActiveError },
                userActive: fromModel.UserActiveError
            ) => {
                return {
                    ...entity,
                    [userActive.articleId]: userActive
                };
            },
            {}
        );

        return {
            ...state,
            userChargedItems: entities,
            isChargedItemsLoaded: true,
            isChargedItemsLoading: false,
            error
        };
    }),

    on(fromActions.GET_USER_BY_ID_SUCCESS, (state: UserState, payload: any) => {
        return {
            ...state,
            selectedUser: payload.user.msg[0],
            isExternalScan: false,
            isUserLoaded: true,
            isUserLoading: false,
            error: null
        };
    }),

    on(fromActions.GET_USER_BY_EXTERNAL_ID_SUCCESS, (state: UserState, payload: any) => {
        return {
            ...state,
            selectedUser: payload.user.msg[0],
            isExternalScan: true,
            isUserLoaded: true,
            isUserLoading: false,
            error: null
        };
    }),

    on(fromActions.GET_USER_BY_DEPARTMENT_ID_SUCCESS, (state: UserState, payload: any) => {
        const getUsers: fromModel.User[] = payload.users.msg;
        const entities = getUsers.reduce(
            (entity: { [id: number]: fromModel.User }, user: fromModel.User) => {
                return {
                    ...entity,
                    [user.userId]: user
                };
            },
            {}
        );
        return {
            ...state,
            users: entities,
            isUserLoaded: true,
            isUserLoading: false,
            error: null
        };
    }),
    on(fromActions.GET_USER_BY_DEPARTMENT_ID_FAIL, (state: UserState, payload: any) => {
        const error: fromModel.ErrorModel = payload.error;
        return {
            ...state,
            users: {},
            isUserLoaded: false,
            isUserLoading: false,
            error
        };
    }),

    on(fromActions.GET_USER_BY_CLIENT_DEPARTMENT_ID_SUCCESS, (state: UserState, payload: any) => {
        const getUsers: fromModel.User[] = payload.users.msg;
        const entities = getUsers.reduce(
            (entity: { [id: number]: fromModel.User }, user: fromModel.User) => {
                return {
                    ...entity,
                    [user.userId]: user
                };
            },
            {}
        );
        return {
            ...state,
            usersSimple: entities,
            isUserLoaded: true,
            isUserLoading: false,
            error: null
        };
    }),
    on(fromActions.GET_USER_BY_CLIENT_DEPARTMENT_ID_FAIL, (state: UserState, payload: any) => {
        const error: fromModel.ErrorModel = payload.error;
        return {
            ...state,
            usersSimple: {},
            isUserLoaded: false,
            isUserLoading: false,
            error
        };
    }),

    on(fromActions.GET_USER_BY_USERNAME, (state: UserState) => {
        return {
            ...state,
            usernameCheck: false,
            isUserLoaded: false,
            isUserLoading: true,
            error: null
        };
    }),
    on(fromActions.GET_USER_BY_USERNAME_SUCCESS, (state: UserState, payload: any) => {
        return {
            ...state,
            usernameCheck: payload.user.msg[0],
            isUserLoaded: true,
            isUserLoading: false,
            error: null
        };
    }),
    on(fromActions.GET_USER_BY_USERNAME_FAIL, (state: UserState, payload: any) => {
        const error: fromModel.ErrorModel = payload.error;
        return {
            ...state,
            usernameCheck: false,
            isUserLoaded: false,
            isUserLoading: false,
            error
        };
    }),

    on(fromActions.GET_USER_BY_FULL_NAME, (state: UserState) => {
        return {
            ...state,
            fullNameCheck: null,
            isUserLoaded: false,
            isUserLoading: true,
            error: null
        };
    }),
    on(fromActions.GET_USER_BY_FULL_NAME_SUCCESS, (state: UserState, payload: any) => {
        return {
            ...state,
            fullNameCheck: payload.user.msg,
            isUserLoaded: true,
            isUserLoading: false,
            error: null
        };
    }),
    on(fromActions.GET_USER_BY_FULL_NAME_FAIL, (state: UserState, payload: any) => {
        const error: fromModel.ErrorModel = payload.error;
        return {
            ...state,
            fullNameCheck: false,
            isUserLoaded: false,
            isUserLoading: false,
            error
        };
    }),

    on(
        fromActions.CREATE_USER_SUCCESS,
        fromActions.UPDATE_USER_SUCCESS,
        (state: UserState, payload: any) => {
            const user: fromModel.User = payload.user.msg[0];
            const users = {
                ...state.users,
                [user.userId]: user
            };
            return {
                ...state,
                users,
                isUserLoaded: true,
                isUserLoading: false,
                error: null
            };
        }
    )
    //#endregion
);

/**
 *
 * @param {UserState} state
 * @param {any} action
 * @return {userReducerState} userReducerState
 */
export function userReducer(state: UserState, action: any) {
    return userReducerState(state, action);
}
