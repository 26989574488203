//#region Imports
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MainComponent } from './main.component';
import { StoreModule } from '@ngrx/store';
import { reducers, metaReducers } from './reducers';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule, RouterState } from '@ngrx/router-store';
import { SharedModule } from './shared/shared.module';
import { AuthInterceptor } from './core/http/services/authconfig.interceptor';
import { CoreModule } from './core/core.module';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MenuListItemComponent } from './main-menu/menu-list-item/menu-list-item.component';
import * as fromAuth from './pages/auth';
// import { NgxKjuaModule } from 'ngx-kjua';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { AdminGuard } from '@shared/guards/admin-guard.guard';
import { SupervisorGuard } from '@shared/guards/supervisor.guard';
import { OperativeGuard } from '@shared/guards/operative.guard';
//#endregion

/**
 * Return translate loader
 * @param {HttpClient} http
 * @return {TranslateHttpLoader}
 */
export function httpTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

/**
 * Method to set languages before app starts
 * @param {TranslateService} translate translate service
 * @return {Promise} language
 */
export function appInitializerFactory(translate: TranslateService): () => Promise<any> {
    return () => {
        translate.setDefaultLang('en');
        return translate.use('en').toPromise();
    };
}

@NgModule({
    declarations: [AppComponent, MainComponent, MenuListItemComponent, ...fromAuth.component],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        AppRoutingModule,
        HttpClientModule,
        SharedModule,
        CoreModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: httpTranslateLoader,
                deps: [HttpClient]
            }
        }),
        StoreModule.forRoot(reducers, {
            metaReducers,
            runtimeChecks: {
                strictStateImmutability: true,
                strictActionImmutability: true,
                strictStateSerializability: true,
                strictActionSerializability: false
            }
        }),
        StoreDevtoolsModule.instrument({
            maxAge: 25,
            logOnly: environment.production,
            connectInZone: true
        }),
        EffectsModule.forRoot([]),
        StoreRouterConnectingModule.forRoot({
            routerState: RouterState.Minimal
        }),
        NgxWebstorageModule.forRoot()
    ],
    providers: [
        AdminGuard,
        SupervisorGuard,
        OperativeGuard,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializerFactory,
            deps: [TranslateService],
            multi: true
        }
    ],
    bootstrap: [MainComponent]
})
export class AppModule {}
