//#region Imports
import { createSelector } from '@ngrx/store';
import { toObjectArray } from '@shared/functions/functions';
import * as fromFeature from './../reducers';
//#endregion

const qrCodeState = createSelector(
    fromFeature.getCoreState,
    (state: fromFeature.CoreState) => state.qrCode
);

const getAllQRCodes = createSelector(qrCodeState, (state) => toObjectArray(state.qrcodes));

const getQRCodeById = createSelector(qrCodeState, (state) => state.selectedQRCode);

const getQRCodeTotalNumberOfEntities = createSelector(qrCodeState, (state) => state.total);

const getQRCodeIsLoaded = createSelector(qrCodeState, (state) => state.isLoaded);

const getQRCodeIsLoading = createSelector(qrCodeState, (state) => state.isLoading);

const getQRCodeError = createSelector(qrCodeState, (state) => state.error);

export const QRCodeSelectors = {
    qrCodeState,
    getAllQRCodes,
    getQRCodeById,
    getQRCodeTotalNumberOfEntities,
    getQRCodeIsLoaded,
    getQRCodeIsLoading,
    getQRCodeError
};
