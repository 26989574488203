//#region Imports
import * as fromModel from '@shared/models';
import { isNil } from 'lodash-es';
//#endregion

/**
 * This function map server error to UI error
 * @param {any} error
 * @return {fromModel.ErrorModel} mapped error
 */
export function ToError(error: any): fromModel.ErrorModel {
    if (isNil(error)) {
        return null;
    }
    return {
        message: error?.error?.msg ? error?.error?.msg : error?.name,
        success: error?.error?.success ? error?.error?.success : error?.ok,
        status: error?.status ? error?.status : 0
    };
}
