//#region Imports
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import * as fromService from '@core/http/services';
import { select, Store } from '@ngrx/store';
import * as fromStore from '@core/store';
import { tap } from 'rxjs/operators';
import { AuthSelectors } from '@core/store';
//#endregion

@Injectable({
    providedIn: 'root'
})
export class AuthGuard  {
    constructor(
        public authService: fromService.AuthService,
        public router: Router,
        private store: Store<fromStore.CoreState>
    ) {}

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.store.pipe(
            select(AuthSelectors.isTokenExpired),
            tap((userStatus) => {
                if (!userStatus) {
                    this.authService.doLogout();
                    return;
                }
            })
        );
    }
}
