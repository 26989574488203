import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { UI_CONSTANTS } from '@shared/config/constants';
import { isNil } from 'lodash-es';
import { Dimensions, ImageCroppedEvent, ImageTransform, OutputFormat } from 'ngx-image-cropper';

@Component({
    selector: 'ec-image-cropper',
    templateUrl: './image-cropper.component.html',
    styleUrls: ['./image-cropper.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageCropperComponent {
    //#region Variables
    aspectRatio = 3 / 4;
    resizeToWidth = 800;
    cropperMinWidth = 64;
    maintainAspectRatio = true;
    onlyScaleDown = true;
    roundCropper = false;
    alignImage: any = 'center';
    format: OutputFormat = 'png';
    imageChangedEvent: any = '';
    croppedImage: any = '';
    canvasRotation = 0;
    rotation = 0;
    scale = 1;
    showCropper = false;
    containWithinAspectRatio = false;
    transform: ImageTransform = {};

    fillAspectRatio: string = this.translateService.instant('imageCropper.fillAspectRatio');
    containAspectRatio: string = this.translateService.instant(
        'imageCropper.containWithinAspectRatio'
    );

    isArticle = false;
    maxImageSize = 0;
    currentImageSize = '0';
    isImageBig = false;
    //#endregion

    constructor(
        public translateService: TranslateService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.maxImageSize = this.data?.imageSize
            ? this.data.imageSize
            : UI_CONSTANTS.MAX_IMAGE_SIZE_BIG;
    }

    fileChangeEvent(event: any): void {
        this.imageChangedEvent = event;
    }

    imageCropped(event: ImageCroppedEvent) {
        this.currentImageSize = this.formatBytes(this.checkFileSize(event));

        if (this.checkFileSize(event) > this.maxImageSize) {
            this.isImageBig = true;
            return;
        }
        this.isImageBig = false;
        this.croppedImage = event.base64;
    }

    private checkFileSize(event): number {
        if (isNil(event) || isNil(event.base64)) {
            return null;
        }
        const base64str = event.base64.substring(event.base64.indexOf(',') + 1);
        const decoded = atob(base64str);

        return decoded.length;
    }

    public formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return '0 Bytes';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    imageLoaded() {
        this.showCropper = true;
    }

    cropperReady(sourceImageDimensions: Dimensions) {
        // console.log('Cropper ready', sourceImageDimensions);
    }

    loadImageFailed() {
        // console.log('Load failed');
    }

    rotateLeft() {
        this.canvasRotation--;
        this.flipAfterRotate();
    }

    rotateRight() {
        this.canvasRotation++;
        this.flipAfterRotate();
    }

    private flipAfterRotate() {
        const flippedH = this.transform.flipH;
        const flippedV = this.transform.flipV;
        this.transform = {
            ...this.transform,
            flipH: flippedV,
            flipV: flippedH
        };
    }

    flipHorizontal() {
        this.transform = {
            ...this.transform,
            flipH: !this.transform.flipH
        };
    }

    flipVertical() {
        this.transform = {
            ...this.transform,
            flipV: !this.transform.flipV
        };
    }

    resetImage() {
        this.scale = 1;
        this.rotation = 0;
        this.canvasRotation = 0;
        this.transform = {};
    }

    zoomOut() {
        this.scale -= 0.1;
        this.transform = {
            ...this.transform,
            scale: this.scale
        };
    }

    zoomIn() {
        this.scale += 0.1;
        this.transform = {
            ...this.transform,
            scale: this.scale
        };
    }

    toggleContainWithinAspectRatio() {
        this.containWithinAspectRatio = !this.containWithinAspectRatio;
    }

    updateRotation() {
        this.transform = {
            ...this.transform,
            rotate: this.rotation
        };
    }
}
