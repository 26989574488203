import { UI_CONSTANTS } from '@shared/config/constants';
import * as fromModel from '@shared/models';
import * as luxon from 'luxon';

/**
 * This function filter array of objects by search string
 * @param {string} search search string
 * @param {any[]} array array of objects
 * @return {any} array of objects that contains search string in name parameter
 */
export const filter = <T extends { name: string }>(search: string, array: T[]): T[] => {
    const filterValue = search.toLowerCase();

    return array.filter((option: T) => option.name.toLowerCase().indexOf(filterValue) === 0);
};

export const createSearchQueryParams = (searchQuery: fromModel.ListQueryParams): string => {
    const page = `?page=${searchQuery.offset}`;
    const itemsPerPage = `&itemsPerPage=${searchQuery.itemsPerPage}`;
    const sortValue = `&sortValue=${searchQuery.sortValue}`;
    const sortDirection = `&sortDirection=${searchQuery.sortDirection}`;
    const articleType = checkIfExist(searchQuery, 'articleType');
    const filter = checkIfExist(searchQuery, 'filter');
    const active = checkIfExist(searchQuery, 'active');
    const pdfType = checkIfExist(searchQuery, 'pdfType');
    const searchString: string =
        page + itemsPerPage + sortValue + sortDirection + articleType + filter + active + pdfType;

    return searchString;
};

/**
 *
 * @param params
 * @param param
 * @returns
 */
function checkIfExist(params: fromModel.ListQueryParams, param: string): string {
    if ((params[param] !== undefined && params[param] !== null) || params[param] === 0) {
        return `&${param}=${params[param] as string}`;
    }
    return '';
}

/**
 * This function creates array of day objects between two dates
 * @param {luxon.Interval} interval interval between selected days
 */
export function* days(interval: luxon.Interval): Generator<any, void, unknown> {
    let cursor = interval.start.startOf('day');
    while (cursor < interval.end) {
        yield cursor;
        cursor = cursor.plus({ days: 1 });
    }
}

/**
 * This function return height and width of an image
 * @param {File} file image file
 * @return {Promise} natural height and width of image
 */
export function getImageDimensions(file: any) {
    return new Promise((resolved, reject) => {
        const i = new Image();
        i.onload = () => {
            const naturalWidth = i.naturalWidth < 600 ? i.naturalWidth : 600;
            const naturalHeight = i.naturalHeight < 800 ? i.naturalHeight : 800;

            resolved({ w: naturalWidth, h: naturalHeight });
        };
        i.onerror = (error) => {
            reject(error);
        };
        i.src = file;
    });
}

export const toObjectArray = <T>(obj: Record<string, T>): T[] =>
    Object.keys(obj).map((key) => obj[key]);

/**
 *
 * @param returnType
 * @returns
 */
export function mapReturnTypeToString(returnType: number): string {
    let statusText = 'article.articleCharge.chargeType.readyToCharge';

    switch (returnType) {
        case UI_CONSTANTS.CHARGE_STATUS.RETURN:
            statusText = 'article.articleCharge.chargeType.return';
            break;
        case UI_CONSTANTS.CHARGE_STATUS.CHARGE:
            statusText = 'article.articleCharge.chargeType.charge';
            break;
        case UI_CONSTANTS.CHARGE_STATUS.SENT_TO_WASH:
            statusText = 'article.articleCharge.chargeType.sentToWash';
            break;
        case UI_CONSTANTS.CHARGE_STATUS.READY_TO_CHARGE:
            statusText = 'article.articleCharge.chargeType.readyToCharge';
            break;
        case UI_CONSTANTS.CHARGE_STATUS.USER_RETURNED_ARTICLE:
            statusText = 'article.articleCharge.chargeType.returnSingle';
            break;
        case UI_CONSTANTS.CHARGE_STATUS.LOST:
            statusText = 'article.articleCharge.chargeType.lost';
            break;
        case UI_CONSTANTS.CHARGE_STATUS.DAMAGED:
            statusText = 'article.articleCharge.chargeType.damaged';
            break;
        default:
            return statusText;
    }
    return statusText;
}

/**
 *
 * @param returnType
 * @returns
 */
export function mapUserArticleReturnTypeToString(returnType: number): string {
    let statusText = 'article.articleCharge.chargeType.readyToCharge';

    switch (returnType) {
        case UI_CONSTANTS.ARTICLE_USER_STATUS.RETURN:
            statusText = 'article.articleCharge.chargeType.returnSingle';
            break;
        case UI_CONSTANTS.ARTICLE_USER_STATUS.CHARGE:
            statusText = 'article.articleCharge.chargeType.charge';
            break;
        case UI_CONSTANTS.ARTICLE_USER_STATUS.LOST:
            statusText = 'article.articleCharge.chargeType.lost';
            break;
        case UI_CONSTANTS.ARTICLE_USER_STATUS.DAMAGED:
            statusText = 'article.articleCharge.chargeType.damaged';
            break;
        default:
            return statusText;
    }
    return statusText;
}

/**
 *
 * @param status
 * @returns
 */
export function mapColorValueToString(status: number): string {
    let statusText = 'article.colors.white';

    switch (status) {
        case UI_CONSTANTS.COLORS.WHITE:
            statusText = 'article.colors.white';
            break;
        case UI_CONSTANTS.COLORS.BLACK:
            statusText = 'article.colors.black';
            break;
        case UI_CONSTANTS.COLORS.BLUE:
            statusText = 'article.colors.blue';
            break;
        case UI_CONSTANTS.COLORS.DARK_BLUE:
            statusText = 'article.colors.darkBlue';
            break;
        case UI_CONSTANTS.COLORS.BEIGE:
            statusText = 'article.colors.beige';
            break;
        case UI_CONSTANTS.COLORS.YELLOW:
            statusText = 'article.colors.yellow';
            break;
        case UI_CONSTANTS.COLORS.GREEN:
            statusText = 'article.colors.green';
            break;
        case UI_CONSTANTS.COLORS.LIGHT_GRAY:
            statusText = 'article.colors.lightGray';
            break;
        case UI_CONSTANTS.COLORS.DARK_GRAY:
            statusText = 'article.colors.darkGray';
            break;
        case UI_CONSTANTS.COLORS.RED:
            statusText = 'article.colors.red';
            break;
        default:
            return statusText;
    }
    return statusText;
}
