import { createAction, props } from '@ngrx/store';
import * as fromModel from '@shared/models';

const actionName = '[Department]';

export const GET_DEPARTMENT_QUERY_PARAMS = createAction(
    `${actionName} Get departments by query params from the API`,
    props<{ searchQuery: fromModel.ListQueryParams }>()
);
export const GET_DEPARTMENT_QUERY_PARAMS_SUCCESS = createAction(
    `${actionName} Get departments by query params from the API success`,
    props<{ departments: fromModel.Department[] }>()
);
export const GET_DEPARTMENT_QUERY_PARAMS_FAIL = createAction(
    `${actionName} Get departments by query params from the API fail`,
    props<{ error: any }>()
);

export const GET_DEPARTMENT = createAction(
    `${actionName} Get all departments from the API`,
    props<{ active: number }>()
);
export const GET_DEPARTMENT_SUCCESS = createAction(
    `${actionName} Get all departments from the API success`,
    props<{ departments: fromModel.Department[] }>()
);
export const GET_DEPARTMENT_FAIL = createAction(
    `${actionName} Get all departments from the API fail`,
    props<{ error: any }>()
);

export const GET_DEPARTMENT_BY_ID = createAction(
    `${actionName} Get department by id from the API`,
    props<{ departmentId: string }>()
);
export const GET_DEPARTMENT_BY_ID_SUCCESS = createAction(
    `${actionName} Get department by id from the API success`,
    props<{ department: fromModel.Department[] }>()
);
export const GET_DEPARTMENT_BY_ID_FAIL = createAction(
    `${actionName} Get department by id from the API fail`,
    props<{ error: any }>()
);

export const GET_DEPARTMENT_BY_USER_ID = createAction(
    `${actionName} Get department by user id from the API`,
    props<{ userId: string }>()
);
export const GET_DEPARTMENT_BY_USER_ID_SUCCESS = createAction(
    `${actionName} Get department by user id from the API success`,
    props<{ departments: fromModel.Department[] }>()
);
export const GET_DEPARTMENT_BY_USER_ID_FAIL = createAction(
    `${actionName} Get department by user id from the API fail`,
    props<{ error: any }>()
);

export const GET_DEPARTMENT_BY_CLIENT_ID = createAction(
    `${actionName} Get department by client id from the API`,
    props<{ clientId: string }>()
);
export const GET_DEPARTMENT_BY_CLIENT_ID_SUCCESS = createAction(
    `${actionName} Get department by client id from the API success`,
    props<{ departments: fromModel.Department[] }>()
);
export const GET_DEPARTMENT_BY_CLIENT_ID_FAIL = createAction(
    `${actionName} Get department by client id from the API fail`,
    props<{ error: any }>()
);

export const UPDATE_DEPARTMENT = createAction(
    `${actionName} Update department object`,
    props<{ department: fromModel.Department }>()
);
export const UPDATE_DEPARTMENT_SUCCESS = createAction(
    `${actionName} Update department object success`,
    props<{ department: fromModel.Department[] }>()
);
export const UPDATE_DEPARTMENT_FAIL = createAction(
    `${actionName} Update department object success fail`,
    props<{ error: any }>()
);

export const CREATE_DEPARTMENT = createAction(
    `${actionName} Create department object`,
    props<{ department: fromModel.Department }>()
);
export const CREATE_DEPARTMENT_SUCCESS = createAction(
    `${actionName} Create department object success`,
    props<{ department: fromModel.Department[] }>()
);
export const CREATE_DEPARTMENT_FAIL = createAction(
    `${actionName} Create department object success fail`,
    props<{ error: any }>()
);
