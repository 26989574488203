/* eslint-disable @typescript-eslint/indent */
//#region Imports
import { createReducer, on } from '@ngrx/store';
import * as fromActions from './../actions';
import * as fromModel from '@shared/models';
import { jwtDecode } from 'jwt-decode';
//#endregion

export interface AuthState {
    isAuthenticated: boolean;
    loggedInUser: fromModel.User;
    expireAt: number;
    issuedAt: number;
    error: any;
}

export const initialState: AuthState = {
    isAuthenticated: false,
    loggedInUser: null,
    expireAt: null,
    issuedAt: null,
    error: null
};

const authReducerState = createReducer(
    initialState,
    on(fromActions.LOGIN, (state: AuthState) => {
        return state;
    }),
    on(fromActions.LOGIN_SUCCESS, (state: AuthState, payload: any) => {
        const token: string = payload.token.msg ? payload.token.msg.token : payload.token;
        const decodeToken: { exp: number; iat: number; token: fromModel.User } = jwtDecode(token);

        return {
            ...state,
            isAuthenticated: true,
            loggedInUser: decodeToken.token,
            expireAt: decodeToken.exp,
            issuedAt: decodeToken.iat,
            error: null
        };
    }),
    on(fromActions.LOGIN_FAIL, (state: AuthState, payload: any) => {
        const error: fromModel.ErrorModel = payload.error;
        return {
            ...state,
            isAuthenticated: false,
            error: error
        };
    }),
    on(fromActions.LOGOUT, (state: AuthState) => {
        return {
            ...state,
            isAuthenticated: false,
            loggedInUser: null,
            error: null
        };
    }),
    on(fromActions.SET_TOKEN, (state: AuthState, payload: any) => {
        const token: string = payload.token;
        const decodeToken: { exp: number; iat: number; token: fromModel.User } = jwtDecode(token);

        const isTokenExpired = Date.now() >= decodeToken.exp * 1000;
        return {
            ...state,
            isAuthenticated: !isTokenExpired ? true : false,
            loggedInUser: !isTokenExpired ? decodeToken.token : null,
            expireAt: !isTokenExpired ? decodeToken.exp : null,
            issuedAt: !isTokenExpired ? decodeToken.iat : null,
            error: null
        };
    })
);

/**
 * Some comment
 * @param {AuthState} state
 * @param {any} action
 * @return {authReducerState}
 */
export function authReducer(state: AuthState, action: any) {
    return authReducerState(state, action);
}
