//#region Imports
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RegisterComponent } from './pages/auth/register/register.component';
import { LoginComponent } from './pages/auth/login/login.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { AppComponent } from './app.component';
import { AdminGuard } from '@shared/guards/admin-guard.guard';
//#endregion

const routes: Routes = [
    {
        path: '',
        component: AppComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: 'register',
                pathMatch: 'full',
                component: RegisterComponent
            },
            {
                path: 'article',
                canActivate: [AdminGuard],
                loadChildren: () =>
                    import('./pages/article/article.module').then((m) => m.ArticleModule)
            },
            {
                path: 'article-charge',
                loadChildren: () =>
                    import('./pages/article-charge/article-charge.module').then(
                        (m) => m.ArticleChargeModule
                    )
            },
            {
                path: 'article-temporary',
                loadChildren: () =>
                    import('./pages/article-temporary/article-temporary.module').then(
                        (m) => m.ArticleTemporaryModule
                    )
            },
            {
                path: 'article-group',
                canActivate: [AdminGuard],
                loadChildren: () =>
                    import('./pages/article-group/article-group.module').then(
                        (m) => m.ArticleGroupModule
                    )
            },
            {
                path: 'department',
                canActivate: [AdminGuard],
                loadChildren: () =>
                    import('./pages/department/department.module').then((m) => m.DepartmentModule)
            },
            {
                path: 'position',
                canActivate: [AdminGuard],
                loadChildren: () =>
                    import('./pages/position/position.module').then((m) => m.PositionModule)
            },
            {
                path: 'client',
                canActivate: [AdminGuard],
                loadChildren: () =>
                    import('./pages/client/client.module').then((m) => m.ClientModule)
            },
            {
                path: 'user',
                canActivate: [AdminGuard],
                loadChildren: () => import('./pages/user/user.module').then((m) => m.UserModule)
            },
            {
                path: 'qrcode',
                canActivate: [AdminGuard],
                loadChildren: () =>
                    import('./pages/qrcode/qrcode.module').then((m) => m.QrcodeModule)
            },
            {
                path: 'report',
                canActivate: [AdminGuard],
                loadChildren: () =>
                    import('./pages/report/report.module').then((m) => m.ReportModule)
            },
            {
                path: '',
                redirectTo: 'user',
                pathMatch: 'full'
            }
        ]
    },
    // {
    //     path: '',
    //     redirectTo: 'login',
    //     pathMatch: 'full'
    // },
    {
        path: 'login',
        pathMatch: 'full',
        component: LoginComponent
    },
    {
        path: '**',
        redirectTo: 'login',
        pathMatch: 'full'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true })],
    exports: [RouterModule]
})
export class AppRoutingModule {}
