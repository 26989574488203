<div [class]="'theme-wrapper ' + theme$">
    <mat-toolbar class="ec-menu-toolbar">
        @if (!isOperative) {
        <button mat-icon-button (click)="drawer.toggle()">
            <mat-icon aria-label="Menu icon" fontIcon="menu"></mat-icon>
        </button>
        }

        <div class="ec-logo">
            <img
                src="./../assets/images/EcoClean Main - Laundry Logo - Horizontal - Color - 500x150 - Transparent.png"
                alt="Logo image"
            />
        </div>

        <div>
            <!-- Settings menu -->
            <button mat-icon-button [matMenuTriggerFor]="settings">
                <mat-icon aria-hidden="false" aria-label="Settings">settings</mat-icon>
            </button>

            <!-- Settings main menu -->
            <mat-menu #settings="matMenu">
                <!-- Logged in user image -->
                <div class="ec-menu-user-details">
                    <div class="ec-mud-image-cropper">
                        <img
                            class="ec-mud-image"
                            src="https://via.placeholder.com/150"
                            alt="User thumbnail image"
                        />
                    </div>
                    <p class="ec-mud-title">
                        {{ loggedUser.firstName }} {{ loggedUser.lastName }} -
                        {{ loggedUser.username }}
                    </p>
                    <mat-divider></mat-divider>
                </div>
                <!-- User profile -->
                <!-- <button mat-menu-item>
                    <mat-icon>badge</mat-icon>
                    {{ 'profile.title' | translate }}
                </button> -->
                <!-- Language menu -->
                <button mat-menu-item [matMenuTriggerFor]="language">
                    <mat-icon>language</mat-icon>
                    {{ 'lang.title' | translate }}
                </button>
                <!-- NOTE: Theme menu -->
                <!-- <button mat-menu-item [matMenuTriggerFor]="themeChange">
                    <mat-icon>dark_mode</mat-icon>
                    {{ 'themes.title' | translate }}
                </button> -->
                <!-- Keyboard shortcuts -->
                <!-- <button
                    mat-menu-item
                    [keyboardShortcut]="keyboardShortcutHelp"
                    (click)="onKeyboardShortcut()"
                >
                    <mat-icon>keyboard</mat-icon>
                    {{ 'keyboardShortcuts.title' | translate }}
                </button> -->
                <!-- Logout -->
                <button mat-menu-item (click)="logOut()">
                    <mat-icon>logout</mat-icon>
                    Logout
                </button>
            </mat-menu>

            <!-- Sub-menu for languages -->
            <mat-menu #language="matMenu">
                @for (lang of languagesFancyNames; track lang.value) {
                <button mat-menu-item (click)="switchLang(lang.value)">
                    <mat-icon>translate</mat-icon>
                    {{ lang.viewValue }}
                </button>
                }
            </mat-menu>

            <!-- Sub-menu for themes -->
            <mat-menu #themeChange="matMenu">
                @for (theme of themesArray; track theme.value) {
                <button mat-menu-item (click)="switchTheme(theme.value)">
                    <mat-icon>{{ theme.icon }}</mat-icon>
                    {{ theme.view | translate }}
                </button>
                }
            </mat-menu>
            <!-- END OF Settings menu -->
        </div>
    </mat-toolbar>

    <mat-drawer-container [hasBackdrop]="sideNavBack">
        <mat-drawer class="sidenav-wrap" #drawer [mode]="sideNavType">
            <mat-nav-list>
                @for (route of routes; track route) {
                <ec-menu-list-item
                    [item]="route"
                    [loggedInUser]="loggedUser"
                    (selectedMenuItem)="onSelectedMenuItem($event)"
                ></ec-menu-list-item>
                }
            </mat-nav-list>
        </mat-drawer>

        <mat-drawer-content>
            <router-outlet></router-outlet>
        </mat-drawer-content>
    </mat-drawer-container>

    <mat-toolbar class="ec-footer">
        <span class="ec-text"> &#64;{{ currentYear }} {{ 'ec-title' | translate }}&trade; </span>
        <span class="ec-logo">
            <img src="./../assets/images/EcoClean All Logos Vector.png" alt="Logo image" />
        </span>
    </mat-toolbar>
</div>
