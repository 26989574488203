//#region Imports
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { UserService } from '@core/http/services/user.service';
import * as fromActions from './../actions';
import * as fromMappings from '@core/mappings';
import * as fromModel from '@shared/models';
//#endregion

@Injectable()
export class UserEffects {
    //#region Roles
    loadRoles$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.GET_ROLES),
            mergeMap(() =>
                this.userService.getRoles().pipe(
                    map((roles: fromModel.Role[]) => {
                        return fromActions.GET_ROLES_SUCCESS({ roles });
                    }),
                    catchError((error: Error) => {
                        return of(
                            fromActions.GET_ROLES_FAIL({ error: fromMappings.ToError(error) })
                        );
                    })
                )
            )
        )
    );

    getRoleById$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.GET_ROLES_BY_ID),
            mergeMap((props: { roleId: string }) =>
                this.userService.getRoleById(props.roleId).pipe(
                    map((role: fromModel.Role[]) => {
                        return fromActions.GET_ROLES_BY_ID_SUCCESS({ role });
                    }),
                    catchError((error: Error) => {
                        return of(
                            fromActions.GET_ROLES_BY_ID_FAIL({ error: fromMappings.ToError(error) })
                        );
                    })
                )
            )
        )
    );

    getRoleByName$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.GET_ROLES_BY_NAME),
            mergeMap((props: { name: string }) =>
                this.userService.getRoleByName(props.name).pipe(
                    map((isValid: boolean) => {
                        return fromActions.GET_ROLES_BY_NAME_SUCCESS({ isValid });
                    }),
                    catchError((error: Error) => {
                        return of(
                            fromActions.GET_ROLES_BY_NAME_FAIL({
                                error: fromMappings.ToError(error)
                            })
                        );
                    })
                )
            )
        )
    );

    updateRole$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.UPDATE_ROLE),
            mergeMap((props: { role: fromModel.Role }) =>
                this.userService.updateRole(props.role).pipe(
                    map((role: fromModel.Role[]) => {
                        return fromActions.UPDATE_ROLE_SUCCESS({ role });
                    }),
                    catchError((error: Error) => {
                        return of(
                            fromActions.UPDATE_ROLE_FAIL({ error: fromMappings.ToError(error) })
                        );
                    })
                )
            )
        )
    );

    createRole$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.CREATE_ROLE),
            mergeMap((props: { role: fromModel.Role }) =>
                this.userService.createRole(props.role).pipe(
                    map((role: fromModel.Role[]) => {
                        return fromActions.CREATE_ROLE_SUCCESS({ role });
                    }),
                    catchError((error: Error) => {
                        return of(
                            fromActions.CREATE_ROLE_FAIL({ error: fromMappings.ToError(error) })
                        );
                    })
                )
            )
        )
    );
    //#endregion

    //#region User
    loadUsersWithQueryParams$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.GET_USER_QUERY_PARAMS),
            mergeMap((props: { searchQuery: fromModel.ListQueryParams }) =>
                this.userService.getUsersWithQueryParams(props.searchQuery).pipe(
                    map((users: fromModel.User[]) => {
                        return fromActions.GET_USER_QUERY_PARAMS_SUCCESS({ users });
                    }),
                    catchError((error: Error) => {
                        return of(
                            fromActions.GET_USER_QUERY_PARAMS_FAIL({
                                error: fromMappings.ToError(error)
                            })
                        );
                    })
                )
            )
        )
    );

    loadUsers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.GET_USER),
            mergeMap((props: { active: number }) =>
                this.userService.getUsers(props.active).pipe(
                    map((user: fromModel.UserSimple[]) => {
                        return fromActions.GET_USER_SUCCESS({ userSimple: user });
                    }),
                    catchError((error: Error) => {
                        return of(
                            fromActions.GET_USER_FAIL({ error: fromMappings.ToError(error) })
                        );
                    })
                )
            )
        )
    );

    getUserById$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.GET_USER_BY_ID),
            mergeMap((props: { userId: string }) =>
                this.userService.getUserById(props.userId).pipe(
                    map((user: fromModel.User[]) => {
                        return fromActions.GET_USER_BY_ID_SUCCESS({ user });
                    }),
                    catchError((error: Error) => {
                        return of(
                            fromActions.GET_USER_BY_ID_FAIL({ error: fromMappings.ToError(error) })
                        );
                    })
                )
            )
        )
    );

    getUserByExternalId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.GET_USER_BY_EXTERNAL_ID),
            mergeMap((props: { externalId: string }) =>
                this.userService.getUserByExternalId(props.externalId).pipe(
                    map((user: fromModel.User[]) => {
                        return fromActions.GET_USER_BY_EXTERNAL_ID_SUCCESS({ user });
                    }),
                    catchError((error: Error) => {
                        return of(
                            fromActions.GET_USER_BY_EXTERNAL_ID_FAIL({
                                error: fromMappings.ToError(error)
                            })
                        );
                    })
                )
            )
        )
    );

    getUserByDepartmentId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.GET_USER_BY_DEPARTMENT_ID),
            mergeMap((props: { departmentId: string }) =>
                this.userService.getUserByDepartmentId(props.departmentId).pipe(
                    map((users: fromModel.User[]) => {
                        return fromActions.GET_USER_BY_DEPARTMENT_ID_SUCCESS({ users });
                    }),
                    catchError((error: Error) => {
                        return of(
                            fromActions.GET_USER_BY_DEPARTMENT_ID_FAIL({
                                error: fromMappings.ToError(error)
                            })
                        );
                    })
                )
            )
        )
    );

    getUserByClientDepartmentId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.GET_USER_BY_CLIENT_DEPARTMENT_ID),
            mergeMap((props: { clientId: string; departmentId: string }) =>
                this.userService
                    .getUserByClientDepartmentId(props.clientId, props.departmentId)
                    .pipe(
                        map((users: fromModel.User[]) => {
                            return fromActions.GET_USER_BY_CLIENT_DEPARTMENT_ID_SUCCESS({ users });
                        }),
                        catchError((error: Error) => {
                            return of(
                                fromActions.GET_USER_BY_CLIENT_DEPARTMENT_ID_FAIL({
                                    error: fromMappings.ToError(error)
                                })
                            );
                        })
                    )
            )
        )
    );

    getUserByUsername$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.GET_USER_BY_USERNAME),
            mergeMap((props: { username: string }) =>
                this.userService.getUserByUsername(props.username).pipe(
                    map((user: fromModel.User[]) => {
                        return fromActions.GET_USER_BY_USERNAME_SUCCESS({ user });
                    }),
                    catchError((error: Error) => {
                        return of(
                            fromActions.GET_USER_BY_USERNAME_FAIL({
                                error: fromMappings.ToError(error)
                            })
                        );
                    })
                )
            )
        )
    );

    getUserByFullName$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.GET_USER_BY_FULL_NAME),
            mergeMap((props: { firstName: string; lastName: string }) =>
                this.userService.getUserByFullName(props.firstName, props.lastName).pipe(
                    map((user: fromModel.User[]) => {
                        return fromActions.GET_USER_BY_FULL_NAME_SUCCESS({ user });
                    }),
                    catchError((error: Error) => {
                        return of(
                            fromActions.GET_USER_BY_FULL_NAME_FAIL({
                                error: fromMappings.ToError(error)
                            })
                        );
                    })
                )
            )
        )
    );

    getPdfByUserId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.GET_PDF_DOCS_BY_USER_ID),
            mergeMap((props: { userId: string }) =>
                this.userService.getPdfDocsByUserId(props.userId).pipe(
                    map((pdfDocs: fromModel.PdfDocs[]) => {
                        return fromActions.GET_PDF_DOCS_BY_USER_ID_SUCCESS({ pdfDocs });
                    }),
                    catchError((error: Error) => {
                        return of(
                            fromActions.GET_PDF_DOCS_BY_USER_ID_FAIL({
                                error: fromMappings.ToError(error)
                            })
                        );
                    })
                )
            )
        )
    );

    updateUserPassword$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.UPDATE_USER_PASSWORD),
            mergeMap(
                (props: { value: { userId: string; password: string; confirmPassword: string } }) =>
                    this.userService.updateUserPassword(props.value).pipe(
                        map((user: fromModel.User[]) => {
                            return fromActions.UPDATE_USER_SUCCESS({ user });
                        }),
                        catchError((error: Error) => {
                            return of(
                                fromActions.UPDATE_USER_FAIL({ error: fromMappings.ToError(error) })
                            );
                        })
                    )
            )
        )
    );

    updateUserActive$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.UPDATE_USER_ACTIVE),
            mergeMap((props: { value: fromModel.UserActive }) =>
                this.userService.updateUserActive(props.value).pipe(
                    map((user: fromModel.User[]) => {
                        return fromActions.UPDATE_USER_SUCCESS({ user });
                    }),
                    catchError((error: any) => {
                        return of(fromActions.UPDATE_USER_ACTIVE_FAIL({ error }));
                    })
                )
            )
        )
    );

    updateUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.UPDATE_USER),
            mergeMap((props: { user: fromModel.User }) =>
                this.userService.updateUser(props.user).pipe(
                    map((user: fromModel.User[]) => {
                        return fromActions.UPDATE_USER_SUCCESS({ user });
                    }),
                    catchError((error: Error) => {
                        return of(
                            fromActions.UPDATE_USER_FAIL({ error: fromMappings.ToError(error) })
                        );
                    })
                )
            )
        )
    );

    createUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.CREATE_USER),
            mergeMap((props: { user: fromModel.User }) => {
                return this.userService.createUser(props.user).pipe(
                    map((user: fromModel.User[]) => {
                        return fromActions.CREATE_USER_SUCCESS({ user });
                    }),
                    catchError((error: Error) => {
                        return of(
                            fromActions.CREATE_USER_FAIL({ error: fromMappings.ToError(error) })
                        );
                    })
                );
            })
        )
    );
    //#endregion

    constructor(private actions$: Actions, private userService: UserService) {}
}
