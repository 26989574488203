import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { AuthService } from '@core/http/services/auth.service';
import * as fromActions from '../actions';
import { Router } from '@angular/router';
import * as fromMappings from '@core/mappings';
import { NotificationService } from '@core/notification/notification.service';
import * as fromModel from '@shared/models';
import { jwtDecode } from 'jwt-decode';
import { LocalStorageService } from 'ngx-webstorage';

@Injectable()
export class AuthEffects {
    // TODO: any
    login$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.LOGIN),
            mergeMap((props: { username: string; password: string; admin: boolean }) =>
                this.authService.signIn(props).pipe(
                    map((user: any) => {
                        localStorage.setItem('access_token', user.msg.token);
                        this.router.navigate(['user']);

                        const decodeToken: { exp: number; iat: number; token: fromModel.User } =
                            jwtDecode(user.msg.token);

                        this.localSt.store('selectedLang', decodeToken.token.lang);

                        return fromActions.LOGIN_SUCCESS({ token: user });
                    }),
                    catchError((error: Error) => {
                        this.notificationService.error(fromMappings.ToError(error).message);
                        return of(fromActions.LOGIN_FAIL({ error: fromMappings.ToError(error) }));
                    })
                )
            )
        )
    );

    constructor(
        private actions$: Actions,
        public router: Router,
        private authService: AuthService,
        private notificationService: NotificationService,
        private localSt: LocalStorageService
    ) {}
}
