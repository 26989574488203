//#region Imports
import { Component, OnInit } from '@angular/core';
import * as services from '@core/http/services';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as fromModel from '@shared/models';
import { Store } from '@ngrx/store';

import * as fromStore from '@core/store';
import { Observable } from 'rxjs';
import { isNil } from 'lodash-es';
import { UserSelectors } from '@core/store';
import { UI_CONSTANTS } from '@shared/config/constants';
//#endregion

@Component({
    selector: 'ec-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
    //#region Variables
    panelOpenState = false;
    registerForm: FormGroup;
    isSubmitted = false;
    minDate: Date;
    maxDate: Date;
    test;
    roles$: Observable<fromModel.Role[]> = this.store.select(UserSelectors.getAllRoles);
    users$: Observable<fromModel.User[]> = this.store.select(UserSelectors.getAllUsers);

    roles: any[] = [
        { value: 'administrator', viewValue: 'Administrator' },
        { value: 'manager', viewValue: 'Manager' },
        { value: 'housekeeping', viewValue: 'Housekeeping' }
    ];

    gender: any[] = [
        { value: '0', viewValue: 'Female' },
        { value: '1', viewValue: 'Male' },
        { value: '2', viewValue: 'Other' }
    ];
    //#endregion

    //#region Getters
    get formControls() {
        return this.registerForm.controls;
    }

    get birthday() {
        return this.registerForm.get('birthday');
    }
    //#endregion

    constructor(
        private service: services.AuthService,
        private formBuilder: FormBuilder,
        private store: Store<fromStore.CoreState>
    ) {
        const currentYear = new Date().getFullYear();
        this.minDate = new Date(currentYear - 100, 0, 1);
        this.maxDate = new Date(currentYear + 1, 11, 31);
    }

    ngOnInit(): void {
        this.createForm();
        this.store.dispatch(fromStore.GET_ROLES());
        this.store.dispatch(fromStore.GET_USER({ active: UI_CONSTANTS.ACTIVE_STATE.ACTIVE }));

        this.roles$.subscribe((data: fromModel.Role[]) => {
            if (isNil(data)) {
                return;
            }
            this.test = data;
        });
    }

    private createForm() {
        const { required } = Validators;
        this.registerForm = this.formBuilder.group({
            firstName: '',
            lastName: '',
            middleName: '',
            username: ['', required],
            birthday: '',
            phone: '',
            mobile: '',
            email: '',
            password: ['', required],
            gender: null,
            address: '',
            occupation: '',
            workExperience: '',
            note: '',
            active: '',
            roles: null
        });
        this.birthday.disable();
    }

    register() {
        const { value, status } = this.registerForm;
        if (status === 'VALID') {
            // const createUser: fromModel.User = {
            //     firstName: value.firstName,
            //     lastName: value.lastName,
            //     middleName: value.middleName,
            //     username: value.username,
            //     birthday: isNil(this.registerForm.getRawValue().birthday) ? null : this.registerForm.getRawValue().birthday,
            //     phone: value.phone,
            //     mobile: value.mobile,
            //     email: value.email,
            //     password: value.password,
            //     gender: Number(value.gender),
            //     address: value.address,
            //     occupation: value.occupation,
            //     workExperience: value.workExperience,
            //     note: value.note,
            //     active: value.active ? 1 : 0,
            //     roleId: ['1d93d7f3-281d-416c-957f-f3a676a862e1', '2d93d7f3-281d-416c-957f-f3a676a862e1']
            // }
            // this.service.register(createUser).subscribe();
        }
    }

    logOut() {
        this.service.doLogout();
    }
}
