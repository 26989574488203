//#region Imports
import { Injectable } from '@angular/core';
import { environment as env } from '@env/environment';
import { HttpClient } from '@angular/common/http';

import * as fromModel from '@shared/models';
import { Observable } from 'rxjs';
import { createSearchQueryParams } from '@shared/functions/functions';
//#endregion

@Injectable({
    providedIn: 'root'
})
export class PdfDocsService {
    endpoint: string = env.server;

    constructor(private http: HttpClient) {}

    getPdfDocsWithQueryParams(
        searchQuery: fromModel.ListQueryParams
    ): Observable<fromModel.PdfDocs[]> {
        return this.http.get<fromModel.PdfDocs[]>(
            `${this.endpoint}get-pdf-docs-by-query${createSearchQueryParams(searchQuery)}`
        );
    }
}

