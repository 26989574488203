//#region Imports
import * as fromActions from './../actions';
import { createReducer, on } from '@ngrx/store';
import * as fromModel from '@shared/models';
//#endregion

export interface ClientState {
    clients: { [clientId: string]: fromModel.Client };
    clientSimple: { [clientId: string]: fromModel.Client };
    total: number;
    selectedClient: fromModel.Client | null;

    isLoading: boolean;
    isLoaded: boolean;
    error: fromModel.ErrorModel | null;
}

export const initialState: ClientState = {
    clients: {},
    clientSimple: {},
    total: 0,
    selectedClient: null,

    isLoaded: false,
    isLoading: false,
    error: null
};

const clientReducerState = createReducer(
    initialState,
    on(
        fromActions.GET_CLIENT_QUERY_PARAMS,
        fromActions.GET_CLIENT_BY_ID,
        fromActions.GET_CLIENT_BY_USER_ID,
        fromActions.UPDATE_CLIENT,
        fromActions.CREATE_CLIENT,
        (state: ClientState) => {
            return {
                ...state,
                isLoaded: false,
                isLoading: true,
                error: null
            };
        }
    ),
    on(fromActions.GET_CLIENT_QUERY_PARAMS_SUCCESS, (state: ClientState, payload: any) => {
        const getClient: fromModel.Client[] = payload.clients.msg;
        const total: number = payload.clients.total;
        const entities = getClient.reduce(
            (
                entity: { [clientId: string]: fromModel.Client },
                client: fromModel.Client
            ) => {
                return {
                    ...entity,
                    [client.clientId]: client
                };
            },
            {}
        );
        return {
            ...state,
            clients: entities,
            total,
            isLoaded: true,
            isLoading: false,
            error: null
        };
    }),

    on(fromActions.GET_CLIENT_QUERY_PARAMS_FAIL, (state: ClientState, payload: any) => {
        const error: fromModel.ErrorModel = payload.error;
        return {
            ...state,
            clients: {},
            total: 0,
            isLoaded: false,
            isLoading: false,
            error
        };
    }),

    on(fromActions.GET_CLIENT, (state: ClientState, payload: any) => {
        return {
            ...state,
            clientSimple: {},
            isLoaded: false,
            isLoading: true
        };
    }),
    on(fromActions.GET_CLIENT_SUCCESS, (state: ClientState, payload: any) => {
        const getClient: fromModel.Client[] = payload.clients.msg;
        const entities = getClient.reduce(
            (
                entity: { [clientId: string]: fromModel.Client },
                client: fromModel.Client
            ) => {
                return {
                    ...entity,
                    [client.clientId]: client
                };
            },
            {
                ...state.clientSimple
            }
        );
        return {
            ...state,
            clientSimple: entities,
            isLoaded: true,
            isLoading: false,
            error: null
        };
    }),

    on(
        fromActions.GET_CLIENT_FAIL,
        fromActions.GET_CLIENT_BY_ID_FAIL,
        fromActions.GET_CLIENT_BY_USER_ID_FAIL,
        fromActions.UPDATE_CLIENT_FAIL,
        fromActions.CREATE_CLIENT_FAIL,
        (state: ClientState, payload: any) => {
            const error: fromModel.ErrorModel = payload.error;
            return {
                ...state,
                isLoaded: false,
                isLoading: false,
                error
            };
        }
    ),

    on(fromActions.GET_CLIENT_BY_ID_SUCCESS, (state: ClientState, payload: any) => {
        return {
            ...state,
            selectedClient: payload.client.msg[0],
            isLoaded: true,
            isLoading: false,
            error: null
        };
    }),

    on(fromActions.GET_CLIENT_BY_USER_ID_SUCCESS, (state: ClientState, payload: any) => {
        const getClient: fromModel.Client[] = payload.clients.msg;
        const entities = getClient.reduce((idx, client) => {
            return {
                ...idx,
                [client.clientId]: client
            };
        }, {});
        return {
            ...state,
            clients: entities,
            isLoaded: true,
            isLoading: false,
            error: null
        };
    }),

    on(
        fromActions.CREATE_CLIENT_SUCCESS,
        fromActions.UPDATE_CLIENT_SUCCESS,
        (state: ClientState, payload: any) => {
            const client: fromModel.Client = payload.client.msg[0];
            const clients = {
                ...state.clients,
                [client.clientId]: client
            };
            return {
                ...state,
                clients,
                isLoaded: true,
                isLoading: false,
                error: null
            };
        }
    )
);

/**
 *
 * @param {ClientState} state
 * @param {ClientActions} action
 * @return {clientReducerState} clientReducerState
 */
export function clientReducer(state: ClientState, action: any) {
    return clientReducerState(state, action);
}
