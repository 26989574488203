//#region Imports
import * as fromActions from './../actions';
import { createReducer, on } from '@ngrx/store';
import * as fromModel from '@shared/models';
//#endregion

export interface ArticleState {
    group: { [articleGroupId: string]: fromModel.ArticleGroup };
    groupSimple: { [articleGroupId: string]: fromModel.ArticleGroup };
    selectedArticleGroup: fromModel.ArticleGroup;
    totalGroup: number;
    articles: { [articleId: string]: fromModel.Article };
    articleHistory: { [articleId: string]: fromModel.ArticleHistory };
    articleUserHistory: { [articleId: string]: fromModel.ArticleHistory };
    articleSimple: { [articleId: string]: fromModel.Article };
    articleTemp: { [articleId: string]: fromModel.Article };
    articleTempSimple: { [articleId: string]: fromModel.ArticleTempSimple };
    total: number;
    selectedArticle: fromModel.Article | null;

    chargedArticles: { [articleChargeId: string]: fromModel.ArticleCharge };
    articlesForWash: { [articleChargeId: string]: fromModel.ArticleWash };
    chargedArticlesHistory: any;
    userLastCharge: fromModel.ArticleCharge | null;
    chargedTempArticles: any;

    multipleEditArticles: fromModel.ArticleMultipleChange[];

    shouldUpdateList: boolean;
    isGroupLoading: boolean;
    isGroupLoaded: boolean;
    isArticleLoading: boolean;
    isArticleLoaded: boolean;
    error: fromModel.ErrorModel | null;
    chargeFailMsg: string;
}

export const initialState: ArticleState = {
    group: {},
    groupSimple: {},
    selectedArticleGroup: null,
    articles: {},
    articleHistory: {},
    articleUserHistory: {},
    articleSimple: {},
    articleTemp: {},
    articleTempSimple: {},
    chargedArticles: {},
    articlesForWash: {},
    chargedTempArticles: {},
    chargedArticlesHistory: {},

    multipleEditArticles: [],

    shouldUpdateList: true,
    userLastCharge: null,
    selectedArticle: null,
    totalGroup: 0,
    total: 0,
    isGroupLoaded: false,
    isGroupLoading: false,
    isArticleLoaded: false,
    isArticleLoading: false,
    error: null,
    chargeFailMsg: ''
};

const articleReducerState = createReducer(
    initialState,
    //#region Articles
    on(
        fromActions.GET_ARTICLE_QUERY_PARAMS,
        fromActions.GET_ARTICLE_TEMP_QUERY_PARAMS,
        fromActions.GET_ARTICLE,
        fromActions.GET_ARTICLE_TEMP_SIMPLE,
        fromActions.GET_ARTICLE_HISTORY,
        fromActions.GET_ARTICLE_USER_HISTORY,
        fromActions.GET_ARTICLE_BY_ID,
        fromActions.GET_ARTICLE_BY_USER_ID,
        fromActions.GET_CHARGE_ARTICLE_BY_ARTICLE_ID,
        fromActions.GET_CHARGED_TEMP_ARTICLE_BY_USER_ID,
        fromActions.GET_ARTICLE_BY_DEPARTMENT_ID,
        fromActions.GET_ARTICLE_BY_CLIENT_ID,
        fromActions.GET_ARTICLE_FOR_WRITE_OFF,
        fromActions.ADD_USER_ARTICLE,
        fromActions.ARTICLE_REMOVE_USER,
        fromActions.UPDATE_ARTICLE,
        fromActions.CREATE_ARTICLE,
        fromActions.CREATE_TEMPORARY_ARTICLE,
        fromActions.ARTICLE_CHARGE,
        fromActions.GET_ARTICLE_CHARGE_BY_STATUS,
        fromActions.ARTICLE_SEND_TO_WASH,
        fromActions.ARTICLE_RETURN_FROM_WASH,
        fromActions.ARTICLE_GROUP_RETURN,
        (state: ArticleState) => {
            return {
                ...state,
                isArticleLoaded: false,
                isArticleLoading: true,
                error: null
            };
        }
    ),
    on(
        fromActions.GET_ARTICLE_FAIL,
        fromActions.GET_ARTICLE_BY_ID_FAIL,
        fromActions.GET_ARTICLE_BY_DEPARTMENT_ID_FAIL,
        fromActions.ADD_USER_ARTICLE_FAIL,
        fromActions.ARTICLE_REMOVE_USER_FAIL,
        fromActions.UPDATE_ARTICLE_FAIL,
        fromActions.CREATE_ARTICLE_FAIL,
        fromActions.GET_ARTICLE_CHARGE_BY_STATUS_FAIL,
        fromActions.GET_ARTICLE_FOR_WRITE_OFF_FAIL,
        (state: ArticleState, payload: any) => {
            const error: fromModel.ErrorModel = payload.error;
            return {
                ...state,
                isArticleLoaded: false,
                isArticleLoading: false,
                error
            };
        }
    ),
    on(fromActions.GET_ARTICLE_HISTORY_FAIL, (state: ArticleState, payload: any) => {
        const error: fromModel.ErrorModel = payload.error;
        return {
            ...state,
            articleHistory: {},
            isArticleLoaded: false,
            isArticleLoading: false,
            error
        };
    }),
    on(fromActions.GET_ARTICLE_USER_HISTORY_FAIL, (state: ArticleState, payload: any) => {
        const error: fromModel.ErrorModel = payload.error;
        return {
            ...state,
            articleUserHistory: {},
            isArticleLoaded: false,
            isArticleLoading: false,
            error
        };
    }),

    on(fromActions.GET_ARTICLE_QUERY_PARAMS_SUCCESS, (state: ArticleState, payload: any) => {
        const getArticle: fromModel.Article[] = payload.articles.msg;
        const total: number = payload.articles.total ? payload.articles.total : 0;
        const entities = getArticle.reduce(
            (entity: { [articleId: string]: fromModel.Article }, article: fromModel.Article) => {
                return {
                    ...entity,
                    [article.articleId]: article
                };
            },
            {}
        );
        return {
            ...state,
            articles: entities,
            chargedArticles: {},
            chargedArticlesHistory: {},
            articleWarehouseByDate: {},
            userWarehouseByDate: {},
            articleItems: {},
            articleServiceNotes: {},
            type: {},
            total,
            isArticleLoaded: true,
            isArticleLoading: false,
            error: null
        };
    }),
    on(fromActions.GET_ARTICLE_QUERY_PARAMS_FAIL, (state: ArticleState, payload: any) => {
        const error: fromModel.ErrorModel = payload.error;
        return {
            ...state,
            articles: {},
            total: 0,
            isArticleLoaded: false,
            isArticleLoading: false,
            error
        };
    }),

    on(fromActions.CLEAR_ARTICLES, (state: ArticleState) => {
        return {
            ...state,
            articles: {},
            isArticleLoaded: true,
            isArticleLoading: false,
            error: null
        };
    }),
    on(fromActions.GET_ARTICLE_FOR_SEARCH, (state: ArticleState) => {
        return {
            ...state,
            articles: {},
            isArticleLoaded: false,
            isArticleLoading: true,
            error: null
        };
    }),

    on(fromActions.GET_ARTICLE_SUCCESS, (state: ArticleState, payload: any) => {
        const getArticle: fromModel.Article[] = payload.articles.msg;
        const entities = getArticle.reduce(
            (entity: { [articleId: string]: fromModel.Article }, article: fromModel.Article) => {
                return {
                    ...entity,
                    [article.articleId]: article
                };
            },
            {
                ...state.articleSimple
            }
        );
        return {
            ...state,
            articleSimple: entities,
            chargedArticles: {},
            articleItems: {},
            type: {},
            isArticleLoaded: true,
            isArticleLoading: false,
            error: null
        };
    }),

    on(fromActions.GET_ARTICLE_HISTORY_SUCCESS, (state: ArticleState, payload: any) => {
        const getArticle: fromModel.ArticleHistory[] = payload.articles;
        const entities = getArticle.reduce(
            (
                entity: { [articleId: string]: fromModel.ArticleHistory },
                article: fromModel.ArticleHistory
            ) => {
                return {
                    ...entity,
                    [article.id]: article
                };
            },
            {}
        );
        return {
            ...state,
            articleHistory: entities,
            isArticleLoaded: true,
            isArticleLoading: false,
            error: null
        };
    }),

    on(fromActions.GET_ARTICLE_USER_HISTORY_SUCCESS, (state: ArticleState, payload: any) => {
        const getArticle: fromModel.ArticleHistory[] = payload.articles;
        const entities = getArticle.reduce(
            (
                entity: { [articleId: string]: fromModel.ArticleHistory },
                article: fromModel.ArticleHistory
            ) => {
                return {
                    ...entity,
                    [article.userArticleId]: article
                };
            },
            {}
        );
        return {
            ...state,
            articleUserHistory: entities,
            isArticleLoaded: true,
            isArticleLoading: false,
            error: null
        };
    }),

    on(fromActions.GET_ARTICLE_TEMP_SIMPLE_SUCCESS, (state: ArticleState, payload: any) => {
        const getArticle: fromModel.ArticleTempSimple[] = payload.articles.msg;
        const entities = getArticle.reduce(
            (
                entity: { [articleId: string]: fromModel.ArticleTempSimple },
                article: fromModel.ArticleTempSimple
            ) => {
                return {
                    ...entity,
                    [article.articleId]: article
                };
            },
            {
                ...state.articleTempSimple
            }
        );
        return {
            ...state,
            articleTempSimple: entities,
            isArticleLoaded: true,
            isArticleLoading: false,
            error: null
        };
    }),

    on(fromActions.GET_ARTICLE_TEMP_SIMPLE_FAIL, (state: ArticleState, payload: any) => {
        const error: fromModel.ErrorModel = payload.error;
        return {
            ...state,
            articleTempSimple: {},
            isArticleLoaded: false,
            isArticleLoading: false,
            error
        };
    }),

    on(fromActions.GET_ARTICLE_BY_ID_SUCCESS, (state: ArticleState, payload: any) => {
        return {
            ...state,
            selectedArticle: payload.article.msg[0],
            isArticleLoaded: true,
            isArticleLoading: false,
            error: null
        };
    }),

    on(
        fromActions.GET_CHARGE_ARTICLE_BY_ARTICLE_ID_SUCCESS,
        (state: ArticleState, payload: any) => {
            return {
                ...state,
                selectedArticle: payload.article,
                isArticleLoaded: true,
                isArticleLoading: false,
                error: null
            };
        }
    ),

    on(fromActions.GET_CHARGE_ARTICLE_BY_ARTICLE_ID_FAIL, (state: ArticleState, payload: any) => {
        return {
            ...state,
            selectedArticle: {} as fromModel.Article,
            isArticleLoaded: true,
            isArticleLoading: false,
            error: null
        };
    }),

    on(fromActions.GET_ARTICLE_CHARGE_BY_STATUS_SUCCESS, (state: ArticleState, payload: any) => {
        const getArticle: fromModel.ArticleWash[] = payload.articles;
        const entities = getArticle.reduce(
            (
                entity: { [articleId: string]: fromModel.ArticleWash },
                article: fromModel.ArticleWash
            ) => {
                return {
                    ...entity,
                    [article.articleChargeId]: article
                };
            },
            {}
        );
        return {
            ...state,
            articlesForWash: entities,
            isArticleLoaded: true,
            isArticleLoading: false,
            error: null
        };
    }),

    on(
        fromActions.GET_ARTICLE_BY_USER_ID_SUCCESS,
        fromActions.ARTICLE_CHARGE_SUCCESS,
        fromActions.ARTICLE_REMOVE_USER_SUCCESS,
        fromActions.VERIFY_USER_ARTICLES_SUCCESS,
        (state: ArticleState, payload: any) => {
            const getArticle: fromModel.Article[] = payload.articles.msg;
            const entities = getArticle.reduce((idx, article) => {
                return {
                    ...idx,
                    [article.articleId]: article
                };
            }, {});
            return {
                ...state,
                articleSimple: entities,
                isArticleLoaded: true,
                isArticleLoading: false,
                error: null
            };
        }
    ),
    on(
        fromActions.GET_ARTICLE_BY_USER_ID_FAIL,
        fromActions.ARTICLE_CHARGE_FAIL,
        (state: ArticleState, payload: any) => {
            const error: fromModel.ErrorModel = payload.error;
            return {
                ...state,
                articleSimple: {},
                isArticleLoaded: false,
                isArticleLoading: false,
                error
            };
        }
    ),

    on(
        fromActions.GET_CHARGED_TEMP_ARTICLE_BY_USER_ID_SUCCESS,
        (state: ArticleState, payload: any) => {
            const getArticle: fromModel.Article[] = payload.articles;
            const entities = getArticle.reduce((idx, article) => {
                return {
                    ...idx,
                    [article.id]: article
                };
            }, {});
            return {
                ...state,
                chargedTempArticles: entities,
                isArticleLoaded: true,
                isArticleLoading: false,
                error: null
            };
        }
    ),
    on(
        fromActions.GET_CHARGED_TEMP_ARTICLE_BY_USER_ID_FAIL,
        (state: ArticleState, payload: any) => {
            const error: fromModel.ErrorModel = payload.error;
            return {
                ...state,
                chargedTempArticles: {},
                isArticleLoaded: false,
                isArticleLoading: false,
                error
            };
        }
    ),

    on(fromActions.GET_ARTICLE_BY_DEPARTMENT_ID_SUCCESS, (state: ArticleState, payload: any) => {
        const getArticle: fromModel.Article[] = payload.articles.msg;
        const entities = getArticle.reduce(
            (entity: { [articleId: string]: fromModel.Article }, article: fromModel.Article) => {
                return {
                    ...entity,
                    [article.articleId]: article
                };
            },
            {
                ...state.articles
            }
        );

        return {
            ...state,
            articles: entities,
            isArticleLoaded: true,
            isArticleLoading: false,
            error: null
        };
    }),

    on(fromActions.GET_ARTICLE_BY_CLIENT_ID_SUCCESS, (state: ArticleState, payload: any) => {
        const getArticle: fromModel.Article[] = payload.articles;

        const entities = getArticle.reduce(
            (entity: { [articleId: string]: fromModel.Article }, article: fromModel.Article) => {
                return {
                    ...entity,
                    [article.articleId]: article
                };
            },
            {
                ...state.articleTemp
            }
        );

        return {
            ...state,
            articleTemp: entities,
            chargedTempArticles: {},
            isArticleLoaded: true,
            isArticleLoading: false,
            error: null,
            chargeFailMsg: payload.chargeFailMsg
        };
    }),
    on(fromActions.GET_ARTICLE_BY_CLIENT_ID_FAIL, (state: ArticleState, payload: any) => {
        const error: fromModel.ErrorModel = payload.error;
        return {
            ...state,
            articleTemp: {},
            isArticleLoaded: false,
            isArticleLoading: false,
            error
        };
    }),

    on(fromActions.GET_ARTICLE_FOR_WRITE_OFF_SUCCESS, (state: ArticleState, payload: any) => {
        const getArticle: fromModel.Article[] = payload.articles;

        const entities = getArticle.reduce(
            (entity: { [articleId: string]: fromModel.Article }, article: fromModel.Article) => {
                return {
                    ...entity,
                    [article.articleId]: article
                };
            },
            {}
        );

        return {
            ...state,
            articles: entities,
            isArticleLoaded: true,
            isArticleLoading: false,
            error: null
        };
    }),

    on(fromActions.ADD_USER_ARTICLE_SUCCESS, (state: ArticleState, payload: any) => {
        const updateArticle: fromModel.Article = payload.articles.msg[0];
        const articles = {
            ...state.articles,
            [updateArticle.articleId]: updateArticle
        };
        return {
            ...state,
            articles,
            isArticleLoaded: true,
            isArticleLoading: false,
            error: null
        };
    }),

    on(fromActions.UPDATE_ARTICLE_SUCCESS, (state: ArticleState, payload: any) => {
        const article: fromModel.Article = payload.article.msg[0];

        const articles = {
            ...state.articles,
            [article.articleId]: article
        };
        return {
            ...state,
            articles,
            shouldUpdateList: false,
            isArticleLoaded: true,
            isArticleLoading: false,
            error: null
        };
    }),

    on(fromActions.CREATE_ARTICLE_SUCCESS, (state: ArticleState, payload: any) => {
        const article: fromModel.Article = payload.article.msg[0];

        const articles = {
            ...state.articles,
            [article.articleId]: article
        };
        return {
            ...state,
            articles,
            shouldUpdateList: true,
            isArticleLoaded: true,
            isArticleLoading: false,
            error: null
        };
    }),

    on(fromActions.GET_ARTICLE_CHARGE_BY_ARTICLE, (state: ArticleState) => {
        return {
            ...state,
            chargedArticles: {},
            isArticleLoaded: false,
            isArticleLoading: true,
            error: null
        };
    }),
    on(fromActions.GET_ARTICLE_CHARGE_BY_ARTICLE_SUCCESS, (state: ArticleState, payload: any) => {
        const getArticle: fromModel.ArticleCharge[] = payload.articles.msg;
        const entities = getArticle.reduce(
            (
                entity: {
                    [id: number]: fromModel.ArticleCharge;
                },
                article: fromModel.ArticleCharge
            ) => {
                return {
                    ...entity,
                    [article.id]: article
                };
            },
            {
                ...state.chargedArticles
            }
        );
        return {
            ...state,
            chargedArticles: entities,
            isArticleLoaded: true,
            isArticleLoading: false,
            error: null
        };
    }),
    on(fromActions.GET_ARTICLE_CHARGE_BY_ARTICLE_FAIL, (state, payload: any) => {
        const error: fromModel.ErrorModel = payload.error;
        return {
            ...state,
            chargedArticles: {},
            isArticleLoaded: false,
            isArticleLoading: false,
            error
        };
    }),

    on(fromActions.CLEAR_TEMP_ARTICLE, (state: ArticleState) => {
        return {
            ...state,
            articleTemp: {},
            isArticleLoaded: false,
            isArticleLoading: true,
            error: null
        };
    }),

    on(fromActions.CLEAR_ARTICLE_SIMPLE, (state: ArticleState) => {
        return {
            ...state,
            articleSimple: {},
            isArticleLoaded: false,
            isArticleLoading: false,
            error: null
        };
    }),

    on(fromActions.CLEAR_SELECTED_ARTICLE, (state: ArticleState) => {
        return {
            ...state,
            selectedArticle: null,
            isArticleLoaded: false,
            isArticleLoading: false,
            error: null
        };
    }),

    on(fromActions.SET_MULTIPLE_EDIT_ARTICLES, (state: ArticleState, payload: any) => {
        const articles: fromModel.ArticleMultipleChange[] = payload.article;

        return {
            ...state,
            multipleEditArticles: articles,
            isArticleLoaded: false,
            isArticleLoading: false,
            error: null
        };
    }),

    on(fromActions.CLEAR_MULTIPLE_EDIT_ARTICLES, (state: ArticleState) => {
        return {
            ...state,
            multipleEditArticles: [],
            isArticleLoaded: false,
            isArticleLoading: false,
            error: null
        };
    }),
    //#endregion

    //#region Article Group
    on(
        fromActions.GET_ARTICLE_GROUP_QUERY_PARAMS,
        fromActions.GET_ARTICLE_GROUP,
        fromActions.GET_ARTICLE_GROUP_BY_ID,
        fromActions.UPDATE_ARTICLE_GROUP,
        fromActions.CREATE_ARTICLE_GROUP,
        (state: ArticleState) => {
            return {
                ...state,
                isGroupLoaded: false,
                isGroupLoading: true,
                error: null
            };
        }
    ),

    on(
        fromActions.GET_ARTICLE_GROUP_FAIL,
        fromActions.GET_ARTICLE_GROUP_BY_ID_FAIL,
        fromActions.UPDATE_ARTICLE_GROUP_FAIL,
        fromActions.CREATE_ARTICLE_GROUP_FAIL,
        (state: ArticleState, payload: any) => {
            const error: fromModel.ErrorModel = payload.error;
            return {
                ...state,
                isGroupLoaded: false,
                isGroupLoading: false,
                error
            };
        }
    ),

    on(fromActions.GET_ARTICLE_GROUP_QUERY_PARAMS_SUCCESS, (state: ArticleState, payload: any) => {
        const getArticle: fromModel.ArticleGroup[] = payload.articleGroups.msg;
        const totalGroup: number = payload.articleGroups.total ? payload.articleGroups.total : 0;
        const entities = getArticle.reduce(
            (
                entity: { [articleGroupId: string]: fromModel.ArticleGroup },
                article: fromModel.ArticleGroup
            ) => {
                return {
                    ...entity,
                    [article.articleGroupId]: article
                };
            },
            {}
        );
        return {
            ...state,
            group: entities,
            totalGroup,
            isGroupLoaded: true,
            isGroupLoading: false,
            error: null
        };
    }),

    on(fromActions.GET_ARTICLE_GROUP_QUERY_PARAMS_FAIL, (state: ArticleState, payload: any) => {
        const error: fromModel.ErrorModel = payload.error;
        return {
            ...state,
            group: {},
            totalGroup: 0,
            isGroupLoaded: false,
            isGroupLoading: false,
            error
        };
    }),

    on(fromActions.GET_ARTICLE_GROUP_SUCCESS, (state: ArticleState, payload: any) => {
        const getArticle: fromModel.ArticleGroup[] = payload.articleGroups.msg;
        const entities = getArticle.reduce(
            (
                entity: { [articleGroupId: string]: fromModel.ArticleGroup },
                article: fromModel.ArticleGroup
            ) => {
                return {
                    ...entity,
                    [article.articleGroupId]: article
                };
            },
            {
                ...state.groupSimple
            }
        );
        return {
            ...state,
            groupSimple: entities,
            isGroupLoaded: true,
            isGroupLoading: false,
            error: null
        };
    }),

    on(fromActions.GET_ARTICLE_GROUP_BY_ID_SUCCESS, (state: ArticleState, payload: any) => {
        return {
            ...state,
            selectedArticleGroup: payload.articleGroups.msg[0],
            isGroupLoaded: true,
            isGroupLoading: false,
            error: null
        };
    }),

    on(
        fromActions.CREATE_ARTICLE_GROUP_SUCCESS,
        fromActions.UPDATE_ARTICLE_GROUP_SUCCESS,
        (state: ArticleState, payload: any) => {
            const createArticleGroup: fromModel.ArticleGroup = payload.articleGroups.msg[0];
            const group = {
                ...state.group,
                [createArticleGroup.articleGroupId]: createArticleGroup
            };
            return {
                ...state,
                group,
                isGroupLoaded: true,
                isGroupLoading: false,
                error: null
            };
        }
    ),

    on(fromActions.ARTICLE_GROUP_RETURN_SUCCESS, (state: ArticleState, payload: any) => {
        return {
            ...state,
            selectedArticle: null,
            isArticleLoaded: true,
            isArticleLoading: false,
            error: null
        };
    })
    //#endregion
);

/**
 *
 * @param {ArticleState} state
 * @param {ArticleActions} action
 * @return {articleReducerState} articleReducerState
 */
export function articleReducer(state: ArticleState, action: any) {
    return articleReducerState(state, action);
}
