//#region Imports
import { Injectable } from '@angular/core';
import { environment as env } from '@env/environment';
import { HttpClient } from '@angular/common/http';

// import * as fromModel from '@shared/models';
import { Observable } from 'rxjs';
import { createSearchQueryParams } from '@shared/functions/functions';
import {
    Article,
    ArticleChangeActive,
    ArticleCharge,
    ArticleGroup,
    ArticleHistory,
    ArticleRemoveUser,
    ArticleTempSimple,
    ArticleTemporaryRemoveSendToAPI,
    ArticleUpdateMultiple,
    ArticleWash,
    ListQueryParams,
    UserArticle
} from '@shared/models';
//#endregion

@Injectable({
    providedIn: 'root'
})
export class ArticleService {
    endpoint: string = env.server;

    constructor(private http: HttpClient) {}

    //#region Article
    getArticlesWithQueryParams(searchQuery: ListQueryParams): Observable<Article[]> {
        return this.http.post<Article[]>(`${this.endpoint}get-articles-by-query`, {
            searchQuery
        });
    }
    getArticlesTempWithQueryParams(searchQuery: ListQueryParams): Observable<Article[]> {
        return this.http.get<Article[]>(
            `${this.endpoint}get-articles-temp-by-query${createSearchQueryParams(searchQuery)}`
        );
    }

    getArticlesForSearch(searchQuery: ListQueryParams): Observable<Article[]> {
        return this.http.post<Article[]>(`${this.endpoint}get-articles-for-search`, {
            searchQuery
        });
    }

    getArticles(): Observable<Article[]> {
        return this.http.get<Article[]>(`${this.endpoint}get-articles-simple`);
    }

    getArticlesTempSimple(): Observable<ArticleTempSimple[]> {
        return this.http.get<ArticleTempSimple[]>(`${this.endpoint}get-articles-temp-simple`);
    }

    getArticleHistory(articleId: string): Observable<{ success: boolean; msg: ArticleHistory[] }> {
        return this.http.get<{ success: boolean; msg: ArticleHistory[] }>(
            `${this.endpoint}get-article-history?articleId=${articleId}`
        );
    }

    getArticleUserHistory(
        articleId: string
    ): Observable<{ success: boolean; msg: ArticleHistory[] }> {
        return this.http.get<{ success: boolean; msg: ArticleHistory[] }>(
            `${this.endpoint}get-article-user-history?articleId=${articleId}`
        );
    }

    getArticleById(articleId: string): Observable<Article[]> {
        return this.http.get<Article[]>(`${this.endpoint}read-article?articleId=${articleId}`);
    }

    // getArticleByNameSize(name: string, size: string, color: number): Observable<Article[]> {
    //     return this.http.get<Article[]>(
    //         `${this.endpoint}get-article-by-name?name=${name}&size=${size}&color=${color}`
    //     );
    // }

    getArticleByUserId(userId: string): Observable<Article[]> {
        return this.http.get<Article[]>(`${this.endpoint}get-article-by-user?userId=${userId}`);
    }

    getChargedArticleByArticleId(
        articleId: string
    ): Observable<{ success: boolean; msg: Article[] }> {
        return this.http.get<{ success: boolean; msg: Article[] }>(
            `${this.endpoint}get-article-charged-by-article?articleId=${articleId}`
        );
    }

    getChargedTempArticleByUserId(
        userId: string
    ): Observable<{ success: boolean; msg: Article[] }> {
        return this.http.get<{ success: boolean; msg: Article[] }>(
            `${this.endpoint}get-charged-temp-article-by-user?userId=${userId}`
        );
    }

    getArticleByDepartmentId(departmentId: string): Observable<Article[]> {
        return this.http.get<Article[]>(
            `${this.endpoint}get-article-by-department?departmentId=${departmentId}`
        );
    }

    getArticleByClientId(
        clientId: string,
        active: number
    ): Observable<{ success: boolean; msg: Article[] }> {
        return this.http.get<{ success: boolean; msg: Article[] }>(
            `${this.endpoint}get-article-by-client?clientId=${clientId}&active=${active}`
        );
    }

    getArticleByStatusActive(
        status: number,
        active: number
    ): Observable<{ success: boolean; msg: Article[] }> {
        return this.http.get<{ success: boolean; msg: Article[] }>(
            `${this.endpoint}get-article-by-status-active?status=${status}&active=${active}`
        );
    }

    addUserArticle(articleId: string, userId: string): Observable<Article[]> {
        const articleUser = {
            articleId,
            userId
        };
        return this.http.put<Article[]>(`${this.endpoint}add-user-article`, articleUser);
    }

    removeUserArticle(userArticles: ArticleRemoveUser): Observable<UserArticle[]> {
        return this.http.put<UserArticle[]>(`${this.endpoint}remove-user-article`, userArticles);
    }

    verifyUserArticle(userArticle: {
        articles: Article[];
        user: string;
        pdf: any;
    }): Observable<any> {
        // const temp = { user: userArticle.user, articles: userArticle.articles };

        return this.http.put<UserArticle[]>(`${this.endpoint}verify-user-article`, userArticle);
    }

    updateArticle(article: Article): Observable<Article[]> {
        return this.http.put<Article[]>(
            `${this.endpoint}update-article?articleId=${article.articleId}`,
            article
        );
    }

    updateMultipleArticles(articles: ArticleUpdateMultiple, pdf: any): Observable<Article[]> {
        return this.http.put<Article[]>(`${this.endpoint}update-multiple-article`, {
            articles,
            pdf
        });
    }

    updateArticleActiveStatus(article: ArticleChangeActive): Observable<Article[]> {
        return this.http.put<Article[]>(
            `${this.endpoint}update-article-active-status?articleId=${article.articleId}`,
            article
        );
    }

    createArticle(article: Article): Observable<Article[]> {
        return this.http.post<Article[]>(`${this.endpoint}create-article`, article);
    }

    createTemporaryArticle(article: Article): Observable<Article[]> {
        return this.http.post<Article[]>(`${this.endpoint}create-article-temp`, article);
    }

    writeOffArticle(articles: Article[], pdf): Observable<{ success: boolean; msg: Article[] }> {
        return this.http.post<{ success: boolean; msg: Article[] }>(
            `${this.endpoint}article-write-off`,
            { articles, pdf }
        );
    }
    //#endregion

    //#region Article charge
    chargeArticle(articleCharge: ArticleCharge, printData: any): Observable<UserArticle[]> {
        return this.http.post<UserArticle[]>(`${this.endpoint}create-article-charge`, {
            articleCharge,
            printData
        });
    }

    chargeArticleTemp(
        articleCharge: ArticleCharge,
        printData: any
    ): Observable<{ userId: string; msg: Article[] }> {
        return this.http.post<{ userId: string; msg: Article[] }>(
            `${this.endpoint}charge-article-temp`,
            { articleCharge, printData }
        );
    }

    getArticleChargeByStatus(status: number): Observable<{ success: boolean; msg: ArticleWash[] }> {
        return this.http.get<{ success: boolean; msg: ArticleWash[] }>(
            `${this.endpoint}article-charge-status?status=${status}`
        );
    }

    sendToWash(
        articles: ArticleWash[],
        status: number,
        pdf
    ): Observable<{ success: boolean; msg: ArticleWash[] }> {
        return this.http.post<{ success: boolean; msg: ArticleWash[] }>(
            `${this.endpoint}article-send-to-wash`,
            { articles, status, pdf }
        );
    }

    returnFromAWash(
        articles: ArticleWash[],
        status: number,
        pdf
    ): Observable<{ success: boolean; msg: ArticleWash[] }> {
        return this.http.post<{ success: boolean; msg: ArticleWash[] }>(
            `${this.endpoint}article-return-from-wash`,
            { articles, status, pdf }
        );
    }

    adjustTemporaryArticleQuantity(
        articles: ArticleTemporaryRemoveSendToAPI
    ): Observable<{ success: boolean; msg: ArticleHistory[] }> {
        return this.http.post<{ success: boolean; msg: ArticleHistory[] }>(
            `${this.endpoint}adjust-temporary-article-quantity`,
            { quantityObject: articles }
        );
    }

    articleGroupReturn(
        articles: ArticleWash[],
        pdf: any
    ): Observable<{ success: boolean; msg: boolean }> {
        return this.http.post<{ success: boolean; msg: boolean }>(
            `${this.endpoint}return-multiple-articles`,
            { articles, pdf }
        );
    }

    // thermalPrinterPrint(data: any): Observable<{ success: boolean; msg: boolean }> {
    //     return this.http.post<{ success: boolean; msg: boolean }>(
    //         `${this.endpoint}print-thermal-printer`,
    //         {
    //             data
    //         }
    //     );
    // }

    // getArticleChargeByArticleId(
    //     articleId: string,
    //     itemsPerPage: number,
    //     page: number,
    //     searchString: string
    // ): Observable<ArticleCharge[]> {
    //     const article = `?articleId=${articleId}`;
    //     const itemsPerPageParam = `&itemsPerPage=${itemsPerPage}`;
    //     const pageParam = `&page=${page}`;
    //     const searchStringParam = `&searchString=${searchString}`;
    //     const param = article + itemsPerPageParam + pageParam + searchStringParam;
    //     return this.http.get<ArticleCharge[]>(
    //         `${this.endpoint}get-article-charge-by-article-id${param}`
    //     );
    // }
    //#endregion

    //#region Article Group
    getArticleGroupsWithQueryParams(searchQuery: ListQueryParams): Observable<ArticleGroup[]> {
        return this.http.get<ArticleGroup[]>(
            `${this.endpoint}get-article-groups-by-query${createSearchQueryParams(searchQuery)}`
        );
    }

    getArticleGroups(): Observable<ArticleGroup[]> {
        return this.http.get<ArticleGroup[]>(`${this.endpoint}get-article-groups-simple`);
    }

    getArticleGroupById(articleGroupId: string): Observable<ArticleGroup[]> {
        return this.http.get<ArticleGroup[]>(
            `${this.endpoint}read-article-group?articleGroupId=${articleGroupId}`
        );
    }

    updateArticleGroup(articleGroup: ArticleGroup): Observable<ArticleGroup[]> {
        return this.http.put<ArticleGroup[]>(
            `${this.endpoint}update-article-group?articleGroupId=${articleGroup.articleGroupId}`,
            articleGroup
        );
    }

    createArticleGroup(articleGroup: ArticleGroup): Observable<ArticleGroup[]> {
        return this.http.post<ArticleGroup[]>(`${this.endpoint}create-article-group`, articleGroup);
    }
    //#endregion
}
