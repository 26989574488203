//#region Imports
import { Injectable } from '@angular/core';
import { environment as env } from '@env/environment';
import { HttpClient } from '@angular/common/http';

import { map } from 'rxjs/operators';
import * as fromModel from '@shared/models';
import { Observable } from 'rxjs';
import { createSearchQueryParams } from '@shared/functions/functions';
//#endregion

@Injectable({
    providedIn: 'root'
})
export class DepartmentService {
    endpoint: string = env.server;

    constructor(private http: HttpClient) {}

    //#region Department
    getDepartmentsWithQueryParams(
        searchQuery: fromModel.ListQueryParams
    ): Observable<fromModel.Department[]> {
        return this.http.get<fromModel.Department[]>(
            `${this.endpoint}get-departments-by-query${createSearchQueryParams(searchQuery)}`
        );
    }

    getDepartments(active: number): Observable<fromModel.Department[]> {
        return this.http.get<fromModel.Department[]>(
            `${this.endpoint}get-departments?active=${active}`
        );
    }

    getDepartmentById(departmentId: string): Observable<fromModel.Department[]> {
        return this.http.get<fromModel.Department[]>(
            `${this.endpoint}read-department?departmentId=${departmentId}`
        );
    }

    getDepartmentByUserId(userId: string): Observable<fromModel.Department[]> {
        return this.http.get<fromModel.Department[]>(
            `${this.endpoint}get-department-by-user?userId=${userId}`
        );
    }

    getDepartmentByClientId(clientId: string): Observable<fromModel.Department[]> {
        return this.http.get<fromModel.Department[]>(
            `${this.endpoint}get-department-by-client?clientId=${clientId}`
        );
    }

    updateDepartment(department: fromModel.Department): Observable<any> {
        return this.http.put<fromModel.Department>(
            `${this.endpoint}update-department?departmentId=${department.departmentId}`,
            department
        );
    }

    createDepartment(department: fromModel.Department): Observable<any> {
        return this.http.post<fromModel.Department>(
            `${this.endpoint}create-department`,
            department
        );
    }
    //#endregion
}
