import { createAction, props } from '@ngrx/store';
import * as fromModel from '@shared/models';

const actionName = '[Position]';

export const GET_POSITION_QUERY_PARAMS = createAction(
    `${actionName} Get positions by query params from the API`,
    props<{ searchQuery: fromModel.ListQueryParams }>()
);
export const GET_POSITION_QUERY_PARAMS_SUCCESS = createAction(
    `${actionName} Get positions by query params from the API success`,
    props<{ positions: fromModel.Position[] }>()
);
export const GET_POSITION_QUERY_PARAMS_FAIL = createAction(
    `${actionName} Get positions by query params from the API fail`,
    props<{ error: any }>()
);

export const GET_POSITION = createAction(`${actionName} Get all positions from the API`);
export const GET_POSITION_SUCCESS = createAction(
    `${actionName} Get all positions from the API success`,
    props<{ positions: fromModel.Position[] }>()
);
export const GET_POSITION_FAIL = createAction(
    `${actionName} Get all positions from the API fail`,
    props<{ error: any }>()
);

export const GET_POSITION_BY_ID = createAction(
    `${actionName} Get position by id from the API`,
    props<{ positionId: string }>()
);
export const GET_POSITION_BY_ID_SUCCESS = createAction(
    `${actionName} Get position by id from the API success`,
    props<{ position: fromModel.Position[] }>()
);
export const GET_POSITION_BY_ID_FAIL = createAction(
    `${actionName} Get position by id from the API fail`,
    props<{ error: any }>()
);

export const GET_POSITION_BY_USER_ID = createAction(
    `${actionName} Get position by user id from the API`,
    props<{ userId: string }>()
);
export const GET_POSITION_BY_USER_ID_SUCCESS = createAction(
    `${actionName} Get position by user id from the API success`,
    props<{ positions: fromModel.Position[] }>()
);
export const GET_POSITION_BY_USER_ID_FAIL = createAction(
    `${actionName} Get position by user id from the API fail`,
    props<{ error: any }>()
);

export const UPDATE_POSITION = createAction(
    `${actionName} Update position object`,
    props<{ position: fromModel.Position }>()
);
export const UPDATE_POSITION_SUCCESS = createAction(
    `${actionName} Update position object success`,
    props<{ position: fromModel.Position[] }>()
);
export const UPDATE_POSITION_FAIL = createAction(
    `${actionName} Update position object success fail`,
    props<{ error: any }>()
);

export const CREATE_POSITION = createAction(
    `${actionName} Create position object`,
    props<{ position: fromModel.Position }>()
);
export const CREATE_POSITION_SUCCESS = createAction(
    `${actionName} Create position object success`,
    props<{ position: fromModel.Position[] }>()
);
export const CREATE_POSITION_FAIL = createAction(
    `${actionName} Create position object success fail`,
    props<{ error: any }>()
);
