//#region Imports
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { QRCodeService } from '@core/http/services/qrcode.service';
import * as fromActions from '../actions';
import * as fromMappings from '@core/mappings';
import * as fromModel from '@shared/models';
//#endregion

@Injectable()
export class QRCodeEffects {
    loadQRCodesWithQueryParams$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.GET_QRCODE_QUERY_PARAMS),
            mergeMap((props: { searchQuery: fromModel.ListQueryParams }) =>
                this.qrcodeService.getQRCodesWithQueryParams(props.searchQuery).pipe(
                    map((qrCodes: fromModel.QRCode[]) => {
                        return fromActions.GET_QRCODE_QUERY_PARAMS_SUCCESS({ qrCodes });
                    }),
                    catchError((error: Error) => {
                        return of(
                            fromActions.GET_QRCODE_QUERY_PARAMS_FAIL({
                                error: fromMappings.ToError(error)
                            })
                        );
                    })
                )
            )
        )
    );

    loadQRCodes$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.GET_QRCODE),
            mergeMap(() =>
                this.qrcodeService.getQRCodes().pipe(
                    map((qrCodes: fromModel.QRCode[]) => {
                        return fromActions.GET_QRCODE_SUCCESS({ qrCodes });
                    }),
                    catchError((error: Error) => {
                        return of(
                            fromActions.GET_QRCODE_FAIL({ error: fromMappings.ToError(error) })
                        );
                    })
                )
            )
        )
    );

    getQRCodeById$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.GET_QRCODE_BY_ID),
            mergeMap((props: { qrCodeId: string }) =>
                this.qrcodeService.getQRCodeById(props.qrCodeId).pipe(
                    map((qrCode: fromModel.QRCode[]) => {
                        return fromActions.GET_QRCODE_BY_ID_SUCCESS({ qrCode });
                    }),
                    catchError((error: any) => {
                        return of(
                            fromActions.GET_QRCODE_BY_ID_FAIL({
                                error: fromMappings.ToError(error)
                            })
                        );
                    })
                )
            )
        )
    );

    createQRCode$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.CREATE_QRCODE),
            mergeMap((props: { qrCode: fromModel.QRCode }) =>
                this.qrcodeService.createQRCode(props.qrCode).pipe(
                    map((qrCodes: fromModel.QRCode[]) => {
                        return fromActions.CREATE_QRCODE_SUCCESS({ qrCodes });
                    }),
                    catchError((error: Error) => {
                        return of(
                            fromActions.CREATE_QRCODE_FAIL({ error: fromMappings.ToError(error) })
                        );
                    })
                )
            )
        )
    );
    //#endregion

    constructor(
        private actions$: Actions,
        private qrcodeService: QRCodeService
    ) {}
}
