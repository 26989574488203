import { createAction, props } from '@ngrx/store';

const actionPrefix = '[Auth]';

//#region Login/Register
export const LOGIN = createAction(
    `${actionPrefix} Login user to the application`,
    props<{ username: string; password: string; admin: boolean }>()
);
export const LOGIN_SUCCESS = createAction(
    `${actionPrefix} Login user to the application success`,
    props<{ token: any }>()
);
export const LOGIN_FAIL = createAction(
    `${actionPrefix} Login user to the application fail`,
    props<{ error: any }>()
);

export const LOGOUT = createAction(`${actionPrefix} Logout user from the application`);
export const REGISTER = createAction(`${actionPrefix} Register user to the application`);

export const SET_TOKEN = createAction(
    `${actionPrefix} Set token to store`,
    props<{ token: string }>()
);
//#endregion
