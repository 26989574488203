import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'articleHistoryStatus'
})
export class ArticleHistoryStatusPipe implements PipeTransform {
    transform(value: unknown): string {
        let result = '';
        if (Number(value) === 1) {
            result = 'pipe.pastUser';
        }
        if (Number(value) === 0) {
            result = 'pipe.currentUser';
        }

        return result;
    }
}

