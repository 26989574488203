import { Pipe, PipeTransform } from '@angular/core';
import { UI_CONSTANTS } from '@shared/config/constants';

@Pipe({
    name: 'active'
})
export class ActivePipe implements PipeTransform {
    transform(value: unknown): string {
        let result = '';
        if (Number(value) === UI_CONSTANTS.ACTIVE_STATE.NOT_ACTIVE) {
            result = 'pipe.inactive';
        }
        if (Number(value) === UI_CONSTANTS.ACTIVE_STATE.ACTIVE) {
            result = 'pipe.active';
        }
        if (Number(value) === UI_CONSTANTS.ACTIVE_STATE.WRITE_OFF) {
            result = 'pipe.writeOff';
        }

        return result;
    }
}
