import { ActivePipe } from './active.pipe';
import { ArticleChargeStatusPipe } from './article-charge-status.pipe';
import { ArticleUserChargeStatusPipe } from './article-user-charge-status.pipe';
import { ArticleHistoryStatusPipe } from './article-history-status.pipe';
import { ArticleColorPipe } from './article-color.pipe';
import { NgForSortByDatePipe } from './ng-for-sort-by-date.pipe';

export * from './active.pipe';
export * from './article-charge-status.pipe';
export * from './article-user-charge-status.pipe'
export * from './article-history-status.pipe';
export * from './article-color.pipe';
export * from './ng-for-sort-by-date.pipe';

export const pipes: any[] = [
    ActivePipe,
    ArticleChargeStatusPipe,
    ArticleUserChargeStatusPipe,
    ArticleHistoryStatusPipe,
    ArticleColorPipe,
    NgForSortByDatePipe
];
