<div>
    <h2 mat-dialog-title>{{ 'pdfDocs.titleAll' | translate }}</h2>

    <mat-dialog-content class="ec-item-box-wrap">
        @for (item of inputData.chargedItems; track item) {
        <mat-card
            appearance="outlined"
            class="ec-item-box"
            [ngClass]="{
                'ec-item-box-alt': item.status === 1
            }"
        >
            <ec-box-item [title]="'article.index'" [text]="item?.id"> </ec-box-item>
            <ec-box-item [title]="'article.name'" [text]="item?.name"> </ec-box-item>
            <ec-box-item [title]="'common.quantity'" [text]="item?.quantity"> </ec-box-item>
            <ec-box-item
                [title]="'article.status'"
                [text]="item?.status | articleChargeStatus | translate"
            >
            </ec-box-item>
            <ec-box-item [title]="'common.createdAt'" [text]="item?.createdAt"> </ec-box-item>
            <ec-box-item [title]="'common.createdBy'" [text]="item?.createdName"> </ec-box-item>
        </mat-card>
        }
    </mat-dialog-content>

    <mat-dialog-actions class="ec-confirm-popover-actions">
        <button mat-raised-button class="ec-confirm-popover-btn-cancel" (click)="onPrint()">
            <mat-icon aria-hidden="false" aria-label="Print icon"> print </mat-icon>
        </button>

        <button mat-raised-button class="ec-confirm-popover-btn-cancel" (click)="onNoClick()">
            {{ 'button.cancel' | translate }}
        </button>
    </mat-dialog-actions>
</div>
