//#region Imports
import { Injectable } from '@angular/core';
import { environment as env } from '@env/environment';
import { HttpClient } from '@angular/common/http';

import { map } from 'rxjs/operators';
import * as fromModel from '@shared/models';
import { Observable } from 'rxjs';
import { createSearchQueryParams } from '@shared/functions/functions';
//#endregion

@Injectable({
    providedIn: 'root'
})
export class ClientService {
    endpoint: string = env.server;

    constructor(private http: HttpClient) {}

    //#region Client
    getClientsWithQueryParams(
        searchQuery: fromModel.ListQueryParams
    ): Observable<fromModel.Client[]> {
        return this.http.get<fromModel.Client[]>(
            `${this.endpoint}get-clients-by-query${createSearchQueryParams(searchQuery)}`
        );
    }

    getClients(active: number): Observable<fromModel.Client[]> {
        return this.http.get<fromModel.Client[]>(`${this.endpoint}get-clients?active=${active}`);
    }

    getClientById(clientId: string): Observable<fromModel.Client[]> {
        return this.http.get<fromModel.Client[]>(
            `${this.endpoint}read-client?clientId=${clientId}`
        );
    }

    getClientByUserId(userId: string): Observable<fromModel.Client[]> {
        return this.http.get<fromModel.Client[]>(
            `${this.endpoint}get-client-by-user?userId=${userId}`
        );
    }

    updateClient(client: fromModel.Client): Observable<any> {
        return this.http.put<fromModel.Client>(
            `${this.endpoint}update-client?clientId=${client.clientId}`,
            client
        );
    }

    createClient(client: fromModel.Client): Observable<any> {
        return this.http.post<fromModel.Client>(`${this.endpoint}create-client`, client);
    }
    //#endregion
}
