<div class="login-wrap">
    <mat-card class="login-container">
        <mat-card-title>
            {{ 'auth.login.title' | translate }}
        </mat-card-title>
        <mat-card-subtitle>
            {{ 'auth.login.subTitle' | translate }}
        </mat-card-subtitle>
        <mat-card-content>
            <form [formGroup]="loginForm" (ngSubmit)="logIn()">
                <div class="login-row">
                    <mat-form-field>
                        <mat-label>{{ 'auth.login.username' | translate }}</mat-label>
                        <input
                            matInput
                            type="username"
                            placeholder="{{ 'auth.login.username' | translate }}"
                            formControlName="username"
                        />
                    </mat-form-field>

                    @if (isSubmitted && formControls.username.errors) {
                    <div class="help-block">
                        @if (formControls.username.errors.required) {
                        <div>
                            {{ 'error.usernameError' | translate }}
                        </div>
                        }
                    </div>
                    }

                    <mat-form-field>
                        <mat-label>{{ 'auth.login.password' | translate }}</mat-label>
                        <input
                            matInput
                            type="password"
                            placeholder="{{ 'auth.login.password' | translate }}"
                            formControlName="password"
                        />
                    </mat-form-field>

                    @if (isSubmitted && formControls.password.errors) {
                    <div class="help-block">
                        @if (formControls.password.errors.required) {
                        <div>
                            {{ 'error.passwordError' | translate }}
                        </div>
                        }
                    </div>
                    }
                </div>

                <p>
                    <button mat-raised-button color="accent" (click)="logIn()">
                        {{ 'button.login' | translate }}
                    </button>
                </p>
            </form>
        </mat-card-content>
    </mat-card>
</div>
