//#region Imports
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment as env } from '@env/environment';
// import { StorageMap } from '@ngx-pwa/local-storage';
import * as fromModel from '@shared/models';
import { isNil } from 'lodash-es';
import { LocalStorageService } from 'ngx-webstorage';
// import { Store } from '@ngrx/store';
// import * as fromStore from '@core/store';
// import * as jwt_decode from 'jwt-decode';
//#endregion

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    endpoint: string = env.server;
    headers = new HttpHeaders().set('Content-Type', 'application/json');
    currentUser = {};

    constructor(
        private localSt: LocalStorageService,
        private http: HttpClient,
        public router: Router // private storage: StorageMap, private store: Store<fromStore.CoreState>
    ) {}

    // Sign-up
    signUp(user: any): Observable<any> {
        const api = `${this.endpoint}/register-user`;
        return this.http.post(api, user);
    }

    // Sign-in
    signIn(userData: any): Observable<any> {
        return this.http
            .post(`${this.endpoint}login`, userData)
            .pipe(map((loginData: any) => loginData));
    }

    register(user: fromModel.User) {
        return this.http.post(`${this.endpoint}register`, user).pipe(
            map((registerData: any) => {
                this.router.navigate(['user']);
                return registerData;
            })
        );
    }

    getToken(): string {
        const token: any = isNil(localStorage.getItem('access_token'))
            ? ''
            : localStorage.getItem('access_token');
        return token;
    }

    get isLoggedIn(): boolean {
        const authToken = localStorage.getItem('access_token');
        return authToken !== null ? true : false;
    }

    doLogout() {
        const removeToken = localStorage.removeItem('access_token');
        this.localSt.clear('selectedLang');
        if (removeToken == null) {
            this.router.navigate(['login']);
        }
    }

    // User profile
    getUserProfile(id: any): Observable<any> {
        const api = `${this.endpoint}/user-profile/${id}`;
        return this.http.get(api, { headers: this.headers });
    }
}
