//#region Imports
import { createSelector } from '@ngrx/store';
import { toObjectArray } from '@shared/functions/functions';
import * as fromFeature from './../reducers';
//#endregion

const userState = createSelector(
    fromFeature.getCoreState,
    (state: fromFeature.CoreState) => state.user
);

const getAllRoles = createSelector(userState, (state) => {
    return toObjectArray(state.roles);
});

const getRoleById = createSelector(userState, (state) => state.selectedRole);

const getAllUsers = createSelector(userState, (state) => toObjectArray(state.users));

const getAllUsersSimple = createSelector(userState, (state) => toObjectArray(state.usersSimple));

const getUserPdfDocs = createSelector(userState, (state) => toObjectArray(state.userPdf));

const getUserChargedItems = createSelector(userState, (state) =>
    toObjectArray(state.userChargedItems)
);

const getUserById = createSelector(userState, (state) => state.selectedUser);

const getUserByUsername = createSelector(userState, (state) => state.usernameCheck);

const checkUserFullNameExists = createSelector(userState, (state) => state.fullNameCheck);

const getRoleByName = createSelector(userState, (state) => state.roleNameCheck);

const getRoleTotalNumberOfEntities = createSelector(userState, (state) => state.totalRole);

const getUserTotalNumberOfEntities = createSelector(userState, (state) => state.totalUser);

const getUserExternalScan = createSelector(userState, (state) => state.isExternalScan);

const getUserIsLoaded = createSelector(userState, (state) => state.isUserLoaded);

const getUserIsLoading = createSelector(userState, (state) => state.isUserLoading);

const getRoleIsLoaded = createSelector(userState, (state) => state.isRoleLoaded);

const getRoleIsLoading = createSelector(userState, (state) => state.isRoleLoading);

const getPdfDocsIsLoaded = createSelector(userState, (state) => state.isPdfDocsLoaded);

const getPdfDocsIsLoading = createSelector(userState, (state) => state.isPdfDocsLoading);

const getChargedItemsIsLoaded = createSelector(userState, (state) => state.isChargedItemsLoaded);

const getChargedItemsIsLoading = createSelector(userState, (state) => state.isChargedItemsLoading);

const getUserError = createSelector(userState, (state) => state.error);

export const UserSelectors = {
    userState,
    getAllRoles,
    getRoleById,
    getAllUsers,
    getAllUsersSimple,
    getUserById,
    getUserByUsername,
    getUserPdfDocs,
    getUserChargedItems,
    checkUserFullNameExists,
    getRoleByName,
    getRoleTotalNumberOfEntities,
    getUserTotalNumberOfEntities,
    getUserExternalScan,
    getUserIsLoaded,
    getUserIsLoading,
    getRoleIsLoaded,
    getRoleIsLoading,
    getPdfDocsIsLoaded,
    getPdfDocsIsLoading,
    getChargedItemsIsLoaded,
    getChargedItemsIsLoading,
    getUserError
};
