import { Injectable } from '@angular/core';
import { navigationItems } from './menu-navigation-items';
import { NavItem } from './../menu-list-item/menu-list-item.component';
import * as fromModel from '@shared/models';

@Injectable({ providedIn: 'root' })
export class AppMenuService {
    navigationEverything = navigationItems;

    /**
     * @param user
     * @returns navigation menu items based on user type and roles
     */
    public getNavigationMenuForUser = (user: fromModel.User): NavItem[] => {
        if (!user) {
            return [];
        }

        const userRoles: string = user.roleName;

        const usersMenu: NavItem[] = [];
        const navigationEverythingCopy = [...this.navigationEverything];

        navigationEverythingCopy.forEach((menuItem) => {
            if (this.showMenuItem(userRoles, menuItem.roleCanSee)) {
                const usersMenuItem = this.getMenuItem(menuItem, userRoles);
                usersMenu.push(usersMenuItem);
            }
        });

        return usersMenu;
    };

    /**
     * @param originalMenuItem
     * @param userRoles
     * @returns recursive function (fetch all children)
     */
    private getMenuItem = (originalMenuItem: NavItem, userRoles: string): NavItem => {
        const clonedItem = this.cloneNavItem(originalMenuItem);
        clonedItem.children = [];

        const originalSubItems = originalMenuItem.children;
        if (originalSubItems && originalSubItems.length > 0) {
            originalSubItems.forEach((subItem) => {
                const clonedSubItem = this.getMenuItem(subItem, userRoles);
                if (this.showMenuItem(userRoles, clonedSubItem.roleCanSee)) {
                    clonedItem.children.push(clonedSubItem);
                }
            });
        }

        return clonedItem;
    };

    /**
     * @param userRoles
     * @param roleCanSee
     * @returns boolean value that indicates if menu item should be shown
     */
    private showMenuItem = (userRoles: string, roleCanSee: string[]): boolean => {
        if (roleCanSee && roleCanSee.length == 0) {
            return true;
        }

        const exists = roleCanSee.some((elem) => userRoles.includes(elem));

        return exists;
    };

    /**
     * @param menuItem
     * @returns cloned menu item that is further user as temp menu item for recursive function
     */
    private cloneNavItem = (menuItem: NavItem): NavItem => {
        const clonedItem = { ...menuItem };

        return clonedItem;
    };
}
