//#region Imports
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import * as fromStore from '@core/store';
//#endregion

@Component({
    selector: 'ec-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    //#region Variables
    panelOpenState = false;
    loginForm: FormGroup;
    isSubmitted = false;
    //#endregion

    get formControls() {
        return this.loginForm.controls;
    }

    constructor(private store: Store<fromStore.CoreState>, private formBuilder: FormBuilder) {}

    public ngOnInit(): void {
        const { required } = Validators;
        this.loginForm = this.formBuilder.group({
            username: ['', required],
            password: ['', required]
        });
    }

    public logIn(): void {
        const { status, value } = this.loginForm;
        if (status) {
            this.store.dispatch(fromStore.LOGIN(value));
        }
    }
}
