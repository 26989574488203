//#region Imports
import { Injectable } from '@angular/core';
import { environment as env } from '@env/environment';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { ListQueryParams, ReportData, ReportSearchQuery, UserEmailNotify } from '@shared/models';
import { createSearchQueryParams } from '@shared/functions/functions';
//#endregion

@Injectable({
    providedIn: 'root'
})
export class ReportsService {
    endpoint: string = env.server;

    constructor(private http: HttpClient) {}

    //#region report API
    createReport(searchQuery: ReportSearchQuery): Observable<ReportData[]> {
        return this.http.post<ReportData[]>(`${this.endpoint}create-report`, searchQuery);
    }

    userEmailNotify(userSetup: UserEmailNotify): Observable<any> {
        return this.http.post<any>(`${this.endpoint}user-email-notify`, userSetup);
    }

    getUserEmailNotify(searchQuery: ListQueryParams): Observable<UserEmailNotify[]> {
        return this.http.get<UserEmailNotify[]>(
            `${this.endpoint}get-user-email-notify${createSearchQueryParams(searchQuery)}`
        );
    }

    getUserEmailNotifyByUserId(userId: string): Observable<UserEmailNotify[]> {
        return this.http.get<UserEmailNotify[]>(
            `${this.endpoint}user-email-notify?userId=${userId}`
        );
    }
    //#endregion
}
