import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'ec-confirm-dialog',
    template: `
        <div class="ec-confirm-popover-wrap">
            <div class="ec-confirm-popover-header">
                <mat-icon class="ec-confirm-popover-icon" aria-label="Report problem">
                    {{ data.icon }}
                </mat-icon>
                <h2 class="ec-confirm-popover-title">{{ data.title | translate }}</h2>
            </div>

            <div class="ec-confirm-popover-content">
                <div class="ec-confirm-popover-text">
                    <p>{{ data.text | translate }}</p>
                </div>

                <div class="ec-confirm-popover-actions">
                    <button
                        mat-raised-button
                        class="ec-confirm-popover-btn-ok"
                        [mat-dialog-close]="true"
                        cdkFocusInitial
                    >
                        {{ btnOk | translate }}
                    </button>
                    <button
                        mat-raised-button
                        class="ec-confirm-popover-btn-cancel"
                        (click)="onNoClick()"
                    >
                        {{ btnCan | translate }}
                    </button>
                </div>
            </div>
        </div>
    `
})
export class ConfirmDialogComponent {
    //#region Variables
    btnOk = 'button.confirm';
    btnCan = 'button.cancel';
    //#endregion

    constructor(public dialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) public data: any) {}

    onNoClick(): void {
        this.dialogRef.close();
    }
}
