import { createAction, props } from '@ngrx/store';
import * as fromModel from '@shared/models';

const actionName = '[QRCode]';

export const GET_QRCODE_QUERY_PARAMS = createAction(
    `${actionName} Get qrcodes by query params from the API`,
    props<{ searchQuery: fromModel.ListQueryParams }>()
);
export const GET_QRCODE_QUERY_PARAMS_SUCCESS = createAction(
    `${actionName} Get qrcodes by query params from the API success`,
    props<{ qrCodes: fromModel.QRCode[] }>()
);
export const GET_QRCODE_QUERY_PARAMS_FAIL = createAction(
    `${actionName} Get qrcodes by query params from the API fail`,
    props<{ error: any }>()
);

export const GET_QRCODE = createAction(`${actionName} Get all qrcodes from the API`);
export const GET_QRCODE_SUCCESS = createAction(
    `${actionName} Get all qrcodes from the API success`,
    props<{ qrCodes: fromModel.QRCode[] }>()
);
export const GET_QRCODE_FAIL = createAction(
    `${actionName} Get all qrcodes from the API fail`,
    props<{ error: any }>()
);

export const GET_QRCODE_BY_ID = createAction(
    `${actionName} Get qrcode by id from the API`,
    props<{ qrCodeId: string }>()
);
export const GET_QRCODE_BY_ID_SUCCESS = createAction(
    `${actionName} Get qrcode by id from the API success`,
    props<{ qrCode: fromModel.QRCode[] }>()
);
export const GET_QRCODE_BY_ID_FAIL = createAction(
    `${actionName} Get qrcode by id from the API fail`,
    props<{ error: any }>()
);

export const CREATE_QRCODE = createAction(
    `${actionName} Create qrcode object`,
    props<{ qrCode: fromModel.QRCode }>()
);
export const CREATE_QRCODE_SUCCESS = createAction(
    `${actionName} Create qrcode object success`,
    props<{ qrCodes: fromModel.QRCode[] }>()
);
export const CREATE_QRCODE_FAIL = createAction(
    `${actionName} Create qrcode object success fail`,
    props<{ error: any }>()
);
