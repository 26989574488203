//#region Imports
import { createSelector } from '@ngrx/store';
import { toObjectArray } from '@shared/functions/functions';
import * as fromFeature from './../reducers';
//#endregion

const clientState = createSelector(
    fromFeature.getCoreState,
    (state: fromFeature.CoreState) => state.client
);

const getAllClients = createSelector(clientState, (state) => toObjectArray(state.clients));

const getAllClientsSimple = createSelector(clientState, (state) =>
    toObjectArray(state.clientSimple)
);

const getClientById = createSelector(clientState, (state) => state.selectedClient);

const getClientTotalNumberOfEntities = createSelector(clientState, (state) => state.total);

const getClientIsLoaded = createSelector(clientState, (state) => state.isLoaded);

const getClientIsLoading = createSelector(clientState, (state) => state.isLoading);

const getClientError = createSelector(clientState, (state) => state.error);

export const ClientSelectors = {
    clientState,
    getAllClients,
    getAllClientsSimple,
    getClientById,
    getClientTotalNumberOfEntities,
    getClientIsLoaded,
    getClientIsLoading,
    getClientError
};
