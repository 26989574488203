//#region Imports
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { ArticleService } from '@core/http/services/article.service';
import * as fromActions from './../actions';
import * as fromMappings from '@core/mappings';
import * as fromStore from '@core/store';
import { Store } from '@ngrx/store';
import {
    Article,
    ArticleChangeActive,
    ArticleCharge,
    ArticleGroup,
    ArticleHistory,
    ArticleRemoveUser,
    ArticleTempSimple,
    ArticleTemporaryRemoveSendToAPI,
    ArticleUpdateMultiple,
    ArticleWash,
    ListQueryParams,
    PdfDocs,
    UserArticle
} from '@shared/models';
import { cloneDeep } from 'lodash-es';
import { Router } from '@angular/router';
import { NotificationService } from '@core/notification/notification.service';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
//#endregion

@Injectable()
export class ArticleEffects {
    loadArticlesWithQueryParams$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.GET_ARTICLE_QUERY_PARAMS),
            mergeMap((props: { searchQuery: ListQueryParams }) =>
                this.articleService.getArticlesWithQueryParams(props.searchQuery).pipe(
                    map((articles: Article[]) => {
                        return fromActions.GET_ARTICLE_QUERY_PARAMS_SUCCESS({ articles });
                    }),
                    catchError((error: Error) => {
                        return of(
                            fromActions.GET_ARTICLE_QUERY_PARAMS_FAIL({
                                error: fromMappings.ToError(error)
                            })
                        );
                    })
                )
            )
        )
    );

    loadArticlesTempWithQueryParams$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.GET_ARTICLE_TEMP_QUERY_PARAMS),
            mergeMap((props: { searchQuery: ListQueryParams }) =>
                this.articleService.getArticlesTempWithQueryParams(props.searchQuery).pipe(
                    map((articles: Article[]) => {
                        return fromActions.GET_ARTICLE_QUERY_PARAMS_SUCCESS({ articles });
                    }),
                    catchError((error: Error) => {
                        return of(
                            fromActions.GET_ARTICLE_QUERY_PARAMS_FAIL({
                                error: fromMappings.ToError(error)
                            })
                        );
                    })
                )
            )
        )
    );

    loadArticlesSearch$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.GET_ARTICLE_FOR_SEARCH),
            mergeMap((props: { searchQuery: ListQueryParams }) =>
                this.articleService.getArticlesForSearch(props.searchQuery).pipe(
                    map((articles: Article[]) => {
                        return fromActions.GET_ARTICLE_QUERY_PARAMS_SUCCESS({ articles });
                    }),

                    catchError((error: Error) => {
                        return of(
                            fromActions.GET_ARTICLE_QUERY_PARAMS_FAIL({
                                error: fromMappings.ToError(error)
                            })
                        );
                    })
                )
            )
        )
    );

    loadArticles$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.GET_ARTICLE),
            mergeMap(() =>
                this.articleService.getArticles().pipe(
                    map((articles: Article[]) => {
                        return fromActions.GET_ARTICLE_SUCCESS({ articles });
                    }),
                    catchError((error: Error) => {
                        return of(
                            fromActions.GET_ARTICLE_FAIL({ error: fromMappings.ToError(error) })
                        );
                    })
                )
            )
        )
    );

    loadArticlesTempSimple$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.GET_ARTICLE_TEMP_SIMPLE),
            mergeMap(() =>
                this.articleService.getArticlesTempSimple().pipe(
                    map((articles: ArticleTempSimple[]) => {
                        return fromActions.GET_ARTICLE_TEMP_SIMPLE_SUCCESS({ articles });
                    }),
                    catchError((error: Error) => {
                        return of(
                            fromActions.GET_ARTICLE_TEMP_SIMPLE_FAIL({
                                error: fromMappings.ToError(error)
                            })
                        );
                    })
                )
            )
        )
    );

    getArticleHistory$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.GET_ARTICLE_HISTORY),
            mergeMap((props: { articleId: string }) =>
                this.articleService.getArticleHistory(props.articleId).pipe(
                    map((articles: { success: boolean; msg: ArticleHistory[] }) => {
                        return fromActions.GET_ARTICLE_HISTORY_SUCCESS({ articles: articles.msg });
                    }),
                    catchError((error: Error) => {
                        return of(
                            fromActions.GET_ARTICLE_HISTORY_FAIL({
                                error: fromMappings.ToError(error)
                            })
                        );
                    })
                )
            )
        )
    );

    getArticleUserHistory$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.GET_ARTICLE_USER_HISTORY),
            mergeMap((props: { articleId: string }) =>
                this.articleService.getArticleUserHistory(props.articleId).pipe(
                    map((articles: { success: boolean; msg: ArticleHistory[] }) => {
                        return fromActions.GET_ARTICLE_USER_HISTORY_SUCCESS({
                            articles: articles.msg
                        });
                    }),
                    catchError((error: Error) => {
                        return of(
                            fromActions.GET_ARTICLE_USER_HISTORY_FAIL({
                                error: fromMappings.ToError(error)
                            })
                        );
                    })
                )
            )
        )
    );

    getArticleById$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.GET_ARTICLE_BY_ID),
            mergeMap((props: { articleId: string }) =>
                this.articleService.getArticleById(props.articleId).pipe(
                    map((article: Article[]) => {
                        return fromActions.GET_ARTICLE_BY_ID_SUCCESS({ article });
                    }),
                    catchError((error: any) => {
                        return of(
                            fromActions.GET_ARTICLE_BY_ID_FAIL({
                                error: fromMappings.ToError(error)
                            })
                        );
                    })
                )
            )
        )
    );

    // getArticleByNameSize$ = createEffect(() =>
    //     this.actions$.pipe(
    //         ofType(fromActions.GET_ARTICLE_BY_NAME_SIZE),
    //         mergeMap((props: { name: string; size: string; color: number }) =>
    //             this.articleService.getArticleByNameSize(props.name, props.size, props.color).pipe(
    //                 map((articles: Article[]) => {
    //                     console.log(`articles`);
    //                     console.log(articles);

    //                     return fromActions.GET_ARTICLE_BY_NAME_SIZE_SUCCESS({ articles });
    //                 }),
    //                 catchError((error: any) => {
    //                     return of(
    //                         fromActions.GET_ARTICLE_BY_NAME_SIZE_FAIL({
    //                             error: fromMappings.ToError(error)
    //                         })
    //                     );
    //                 })
    //             )
    //         )
    //     )
    // );

    getArticleByUserId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.GET_ARTICLE_BY_USER_ID),
            mergeMap((props: { userId: string }) =>
                this.articleService.getArticleByUserId(props.userId).pipe(
                    map((articles: Article[]) => {
                        return fromActions.GET_ARTICLE_BY_USER_ID_SUCCESS({ articles });
                    }),
                    catchError((error: Error) => {
                        return of(
                            fromActions.GET_ARTICLE_BY_USER_ID_FAIL({
                                error: fromMappings.ToError(error)
                            })
                        );
                    })
                )
            )
        )
    );

    getChargedArticleByArticleId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.GET_CHARGE_ARTICLE_BY_ARTICLE_ID),
            mergeMap((props: { articleId: string }) =>
                this.articleService.getChargedArticleByArticleId(props.articleId).pipe(
                    map((payload: { success: boolean; msg: Article[] }) => {
                        return fromActions.GET_CHARGE_ARTICLE_BY_ARTICLE_ID_SUCCESS({
                            article: payload.msg[0]
                        });
                    }),
                    catchError((error: Error) => {
                        return of(
                            fromActions.GET_CHARGE_ARTICLE_BY_ARTICLE_ID_FAIL({
                                error: fromMappings.ToError(error)
                            })
                        );
                    })
                )
            )
        )
    );

    getChargedTempArticleByUserId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.GET_CHARGED_TEMP_ARTICLE_BY_USER_ID),
            mergeMap((props: { userId: string }) =>
                this.articleService.getChargedTempArticleByUserId(props.userId).pipe(
                    map((payload: { success: boolean; msg: Article[] }) => {
                        return fromActions.GET_CHARGED_TEMP_ARTICLE_BY_USER_ID_SUCCESS({
                            articles: payload.msg
                        });
                    }),
                    catchError((error: Error) => {
                        return of(
                            fromActions.GET_CHARGED_TEMP_ARTICLE_BY_USER_ID_FAIL({
                                error: fromMappings.ToError(error)
                            })
                        );
                    })
                )
            )
        )
    );

    getArticleByDepartmentId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.GET_ARTICLE_BY_DEPARTMENT_ID),
            mergeMap((props: { departmentId: string }) =>
                this.articleService.getArticleByDepartmentId(props.departmentId).pipe(
                    map((articles: Article[]) => {
                        return fromActions.GET_ARTICLE_BY_DEPARTMENT_ID_SUCCESS({ articles });
                    }),
                    catchError((error: Error) => {
                        return of(
                            fromActions.GET_ARTICLE_BY_DEPARTMENT_ID_FAIL({
                                error: fromMappings.ToError(error)
                            })
                        );
                    })
                )
            )
        )
    );

    getArticleByClientId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.GET_ARTICLE_BY_CLIENT_ID),
            mergeMap((props: { clientId: string; userId: string; active: number }) =>
                this.articleService.getArticleByClientId(props.clientId, props.active).pipe(
                    map((articles: { success: boolean; msg: Article[]; failMsg: string }) => {
                        this.store.dispatch(
                            fromStore.GET_CHARGED_TEMP_ARTICLE_BY_USER_ID({
                                userId: props.userId
                            })
                        );

                        return fromActions.GET_ARTICLE_BY_CLIENT_ID_SUCCESS({
                            articles: articles.msg,
                            chargeFailMsg: articles.failMsg
                        });
                    }),
                    catchError((error: Error) => {
                        return of(
                            fromActions.GET_ARTICLE_BY_CLIENT_ID_FAIL({
                                error: fromMappings.ToError(error)
                            })
                        );
                    })
                )
            )
        )
    );

    getArticleByStatusActive$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.GET_ARTICLE_FOR_WRITE_OFF),
            mergeMap((props: { status: number; active: number }) =>
                this.articleService.getArticleByStatusActive(props.status, props.active).pipe(
                    map((articles: { success: boolean; msg: Article[] }) => {
                        return fromActions.GET_ARTICLE_FOR_WRITE_OFF_SUCCESS({
                            articles: articles.msg
                        });
                    }),
                    catchError((error: Error) => {
                        return of(
                            fromActions.GET_ARTICLE_FOR_WRITE_OFF_FAIL({
                                error: fromMappings.ToError(error)
                            })
                        );
                    })
                )
            )
        )
    );

    addUserArticle$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.ADD_USER_ARTICLE),
            mergeMap((props: { articleId: string; userId: string }) =>
                this.articleService.addUserArticle(props.articleId, props.userId).pipe(
                    map((articles: Article[]) => {
                        return fromActions.ADD_USER_ARTICLE_SUCCESS({ articles });
                    }),
                    catchError((error: Error) => {
                        return of(
                            fromActions.ADD_USER_ARTICLE_FAIL({
                                error: fromMappings.ToError(error)
                            })
                        );
                    })
                )
            )
        )
    );

    removeUserArticle$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.ARTICLE_REMOVE_USER),
            mergeMap((props: { userArticles: ArticleRemoveUser }) =>
                this.articleService.removeUserArticle(props.userArticles).pipe(
                    map((articles: UserArticle[]) => {
                        const pdfDocs = cloneDeep(props.userArticles.pdf);
                        pdfMake.createPdf(pdfDocs).open();

                        return fromActions.ARTICLE_REMOVE_USER_SUCCESS({ articles });
                    }),
                    catchError((error: Error) => {
                        return of(
                            fromActions.ARTICLE_REMOVE_USER_FAIL({
                                error: fromMappings.ToError(error)
                            })
                        );
                    })
                )
            )
        )
    );

    updateArticle$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.UPDATE_ARTICLE),
            mergeMap((props: { article: Article }) =>
                this.articleService.updateArticle(props.article).pipe(
                    map((article: Article[]) => {
                        return fromActions.UPDATE_ARTICLE_SUCCESS({ article });
                    }),
                    catchError((error: Error) => {
                        return of(
                            fromActions.UPDATE_ARTICLE_FAIL({ error: fromMappings.ToError(error) })
                        );
                    })
                )
            )
        )
    );

    updateMultipleArticle$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.UPDATE_MULTIPLE_ARTICLE),
            mergeMap((props: { article: { article: ArticleUpdateMultiple; pdf: PdfDocs } }) =>
                this.articleService
                    .updateMultipleArticles(props.article.article, props.article.pdf)
                    .pipe(
                        map((article: Article[]) => {
                            this.notificationService.success('success.articlesUpdated');
                            const pdfDocs = cloneDeep(props.article.pdf);

                            pdfMake.createPdf(pdfDocs).open();

                            this.router.navigate(['article']);
                            return fromActions.UPDATE_MULTIPLE_ARTICLE_SUCCESS({ article });
                        }),
                        catchError((error: Error) => {
                            return of(
                                fromActions.UPDATE_MULTIPLE_ARTICLE_FAIL({
                                    error: fromMappings.ToError(error)
                                })
                            );
                        })
                    )
            )
        )
    );

    updateArticleActiveStatus$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.UPDATE_ARTICLE_ACTIVE_STATUS),
            mergeMap((props: { article: ArticleChangeActive }) =>
                this.articleService.updateArticleActiveStatus(props.article).pipe(
                    map((article: Article[]) => {
                        return fromActions.UPDATE_ARTICLE_SUCCESS({ article });
                    }),
                    catchError((error: Error) => {
                        return of(
                            fromActions.UPDATE_ARTICLE_FAIL({ error: fromMappings.ToError(error) })
                        );
                    })
                )
            )
        )
    );

    createArticle$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.CREATE_ARTICLE),
            mergeMap((props: { article: Article }) =>
                this.articleService.createArticle(props.article).pipe(
                    map((article: Article[]) => {
                        this.router.navigate(['article']);

                        return fromActions.CREATE_ARTICLE_SUCCESS({ article });
                    }),
                    catchError((error: Error) => {
                        return of(
                            fromActions.CREATE_ARTICLE_FAIL({ error: fromMappings.ToError(error) })
                        );
                    })
                )
            )
        )
    );

    createTemporaryArticle$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.CREATE_TEMPORARY_ARTICLE),
            mergeMap((props: { article: Article }) =>
                this.articleService.createTemporaryArticle(props.article).pipe(
                    map((article: Article[]) => {
                        return fromActions.CREATE_ARTICLE_SUCCESS({ article });
                    }),
                    catchError((error: Error) => {
                        return of(
                            fromActions.CREATE_ARTICLE_FAIL({ error: fromMappings.ToError(error) })
                        );
                    })
                )
            )
        )
    );

    verifyUserArticle$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.VERIFY_USER_ARTICLES),
            mergeMap((props: { userArticle: { articles: Article[]; user: string; pdf: any } }) =>
                this.articleService.verifyUserArticle(props.userArticle).pipe(
                    map((articles: UserArticle[]) => {
                        const pdfDocs = cloneDeep(props.userArticle.pdf);
                        this.store.dispatch(
                            fromActions.GET_PDF_DOCS_BY_USER_ID({
                                userId: props.userArticle.user
                            })
                        );

                        pdfMake.createPdf(pdfDocs).open();
                        return fromActions.VERIFY_USER_ARTICLES_SUCCESS({ articles });
                    }),
                    catchError((error: Error) => {
                        return of(
                            fromActions.VERIFY_USER_ARTICLES_FAIL({
                                error: fromMappings.ToError(error)
                            })
                        );
                    })
                )
            )
        )
    );

    writeOffArticle$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.WRITE_OFF_ARTICLE),
            mergeMap((props: { articles: Article[]; pdf: any }) =>
                this.articleService.writeOffArticle(props.articles, props.pdf).pipe(
                    map((articles: { success: boolean; msg: Article[] }) => {
                        const pdfDocs = cloneDeep(props.pdf);

                        pdfMake.createPdf(pdfDocs).open();
                        return fromActions.GET_ARTICLE_FOR_WRITE_OFF_SUCCESS({
                            articles: articles.msg
                        });
                    }),
                    catchError((error: Error) => {
                        return of(
                            fromActions.GET_ARTICLE_FOR_WRITE_OFF_FAIL({
                                error: fromMappings.ToError(error)
                            })
                        );
                    })
                )
            )
        )
    );

    //#region Charge
    chargeArticle$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.ARTICLE_CHARGE),
            mergeMap((props: { articleCharge: ArticleCharge; printData: any }) =>
                this.articleService.chargeArticle(props.articleCharge, props.printData).pipe(
                    map((articles: UserArticle[]) => {
                        return fromActions.ARTICLE_CHARGE_SUCCESS({ articles });
                    }),
                    catchError((error: Error) => {
                        return of(
                            fromActions.ARTICLE_CHARGE_FAIL({ error: fromMappings.ToError(error) })
                        );
                    })
                )
            )
        )
    );

    chargeArticleTemp$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.ARTICLE_CHARGE_TEMP),
            mergeMap((props: { articleCharge: ArticleCharge; printData: any }) =>
                this.articleService.chargeArticleTemp(props.articleCharge, props.printData).pipe(
                    map((articles: { userId: string; msg: Article[]; failMsg: string }) => {
                        this.store.dispatch(
                            fromStore.GET_CHARGED_TEMP_ARTICLE_BY_USER_ID({
                                userId: articles.userId
                            })
                        );
                        return fromActions.GET_ARTICLE_BY_CLIENT_ID_SUCCESS({
                            articles: articles.msg,
                            chargeFailMsg: articles.failMsg
                        });
                    }),
                    catchError((error: Error) => {
                        return of(
                            fromActions.GET_ARTICLE_BY_CLIENT_ID_FAIL({
                                error: fromMappings.ToError(error)
                            })
                        );
                    })
                )
            )
        )
    );

    getArticleChargeByStatus$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.GET_ARTICLE_CHARGE_BY_STATUS),
            mergeMap((props: { status: number }) =>
                this.articleService.getArticleChargeByStatus(props.status).pipe(
                    map((articles: { success: boolean; msg: ArticleWash[] }) => {
                        return fromActions.GET_ARTICLE_CHARGE_BY_STATUS_SUCCESS({
                            articles: articles.msg
                        });
                    }),
                    catchError((error: Error) => {
                        return of(
                            fromActions.GET_ARTICLE_CHARGE_BY_STATUS_FAIL({
                                error: fromMappings.ToError(error)
                            })
                        );
                    })
                )
            )
        )
    );

    articleSendForAWash$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.ARTICLE_SEND_TO_WASH),
            mergeMap((props: { articles: ArticleWash[]; status: number; pdf: any }) =>
                this.articleService.sendToWash(props.articles, props.status, props.pdf).pipe(
                    map((articles: { success: boolean; isValid: boolean; msg: ArticleWash[] }) => {
                        if (!articles.isValid) {
                            return fromActions.GET_ARTICLE_CHARGE_BY_STATUS_SUCCESS({
                                articles: articles.msg
                            });
                        }
                        const pdfDocs = cloneDeep(props.pdf);

                        pdfMake.createPdf(pdfDocs).open();

                        this.router.navigate(['article-charge']);

                        return fromActions.GET_ARTICLE_CHARGE_BY_STATUS_SUCCESS({
                            articles: articles.msg
                        });
                    }),
                    catchError((error: Error) => {
                        return of(
                            fromActions.GET_ARTICLE_CHARGE_BY_STATUS_FAIL({
                                error: fromMappings.ToError(error)
                            })
                        );
                    })
                )
            )
        )
    );

    articleReturnFromAWash$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.ARTICLE_RETURN_FROM_WASH),
            mergeMap((props: { articles: ArticleWash[]; status: number; pdf: any }) =>
                this.articleService.returnFromAWash(props.articles, props.status, props.pdf).pipe(
                    map((articles: { success: boolean; isValid: boolean; msg: ArticleWash[] }) => {
                        if (!articles.isValid) {
                            return fromActions.GET_ARTICLE_CHARGE_BY_STATUS_SUCCESS({
                                articles: articles.msg
                            });
                        }
                        const pdfDocs = cloneDeep(props.pdf);

                        pdfMake.createPdf(pdfDocs).open();

                        this.router.navigate(['article-charge']);

                        return fromActions.GET_ARTICLE_CHARGE_BY_STATUS_SUCCESS({
                            articles: articles.msg
                        });
                    }),
                    catchError((error: Error) => {
                        return of(
                            fromActions.GET_ARTICLE_CHARGE_BY_STATUS_FAIL({
                                error: fromMappings.ToError(error)
                            })
                        );
                    })
                )
            )
        )
    );

    adjustTemporaryArticleQuantity$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.ADJUST_TEMPORARY_ARTICLE_QUANTITY),
            mergeMap((props: { articles: ArticleTemporaryRemoveSendToAPI }) =>
                this.articleService.adjustTemporaryArticleQuantity(props.articles).pipe(
                    map((articles: { success: boolean; msg: ArticleHistory[] }) => {
                        const pdfDocs = cloneDeep(props.articles.pdf);

                        pdfMake.createPdf(pdfDocs).open();

                        return fromActions.GET_ARTICLE_HISTORY_SUCCESS({
                            articles: articles.msg
                        });
                    }),
                    catchError((error: Error) => {
                        return of(
                            fromActions.GET_ARTICLE_HISTORY_FAIL({
                                error: fromMappings.ToError(error)
                            })
                        );
                    })
                )
            )
        )
    );

    articleGroupReturn$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.ARTICLE_GROUP_RETURN),
            mergeMap((props: { articles: ArticleWash[]; pdf: any }) =>
                this.articleService.articleGroupReturn(props.articles, props.pdf).pipe(
                    map((articles: { success: boolean; msg: boolean }) => {
                        const pdfDocs = cloneDeep(props.pdf);

                        pdfMake.createPdf(pdfDocs).open();

                        this.router.navigate(['article-charge']);

                        return fromActions.ARTICLE_GROUP_RETURN_SUCCESS({
                            status: articles.msg
                        });
                    }),
                    catchError((error: Error) => {
                        return of(
                            fromActions.GET_ARTICLE_CHARGE_BY_STATUS_FAIL({
                                error: fromMappings.ToError(error)
                            })
                        );
                    })
                )
            )
        )
    );

    // thermalPrinterPrint$ = createEffect(() =>
    //     this.actions$.pipe(
    //         ofType(fromActions.THERMAL_PRINTER_PRINT),
    //         mergeMap((props: { data: any }) =>
    //             this.articleService.thermalPrinterPrint(props.data).pipe(
    //                 map((articles: { success: boolean; msg: boolean }) => {
    //                     return fromActions.THERMAL_PRINTER_PRINT_SUCCESS({
    //                         data: articles.msg
    //                     });
    //                 }),
    //                 catchError((error: Error) => {
    //                     return of(
    //                         fromActions.THERMAL_PRINTER_PRINT_FAIL({
    //                             error: fromMappings.ToError(error)
    //                         })
    //                     );
    //                 })
    //             )
    //         )
    //     )
    // );

    // getArticleChargeByArticleId$ = createEffect(() =>
    //     this.actions$.pipe(
    //         ofType(fromActions.GET_ARTICLE_CHARGE_BY_ARTICLE),
    //         mergeMap(
    //             (props: {
    //                 articleId: string;
    //                 itemsPerPage: number;
    //                 page: number;
    //                 searchString: string;
    //             }) =>
    //                 this.articleService
    //                     .getArticleChargeByArticleId(
    //                         props.articleId,
    //                         props.itemsPerPage,
    //                         props.page,
    //                         props.searchString
    //                     )
    //                     .pipe(
    //                         map((article: ArticleCharge[]) => {
    //                             this.notificationService.success(
    //                                 'success.article.articleChargeByArticleIdLoaded'
    //                             );
    //                             return {
    //                                 type: '[Article Component] Get article charge by article id object success',
    //                                 payload: article
    //                             };
    //                         }),
    //                         catchError((error: Error) => {
    //                             this.notificationService.error(fromMappings.ToError(error).message);
    //                             return of({
    //                                 type: '[Article Component] Get article charge by article id object fail',
    //                                 payload: fromMappings.ToError(error)
    //                             });
    //                         })
    //                     )
    //         )
    //     )
    // );
    //#endregion

    //#region Group
    loadArticleGroupsWithQueryParams$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.GET_ARTICLE_GROUP_QUERY_PARAMS),
            mergeMap((props: { searchQuery: ListQueryParams }) =>
                this.articleService.getArticleGroupsWithQueryParams(props.searchQuery).pipe(
                    map((articleGroups: ArticleGroup[]) => {
                        return fromActions.GET_ARTICLE_GROUP_QUERY_PARAMS_SUCCESS({
                            articleGroups
                        });
                    }),
                    catchError((error: Error) => {
                        return of(
                            fromActions.GET_ARTICLE_GROUP_QUERY_PARAMS_FAIL({
                                error: fromMappings.ToError(error)
                            })
                        );
                    })
                )
            )
        )
    );

    loadArticleGroups$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.GET_ARTICLE_GROUP),
            mergeMap(() =>
                this.articleService.getArticleGroups().pipe(
                    map((articleGroups: ArticleGroup[]) => {
                        return fromActions.GET_ARTICLE_GROUP_SUCCESS({ articleGroups });
                    }),
                    catchError((error: Error) => {
                        return of(
                            fromActions.GET_ARTICLE_GROUP_FAIL({
                                error: fromMappings.ToError(error)
                            })
                        );
                    })
                )
            )
        )
    );

    getArticleGroupById$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.GET_ARTICLE_GROUP_BY_ID),
            mergeMap((props: { articleGroupId: string }) =>
                this.articleService.getArticleGroupById(props.articleGroupId).pipe(
                    map((articleGroups: ArticleGroup[]) => {
                        return fromActions.GET_ARTICLE_GROUP_BY_ID_SUCCESS({ articleGroups });
                    }),
                    catchError((error: any) => {
                        return of(
                            fromActions.GET_ARTICLE_GROUP_BY_ID_FAIL({
                                error: fromMappings.ToError(error)
                            })
                        );
                    })
                )
            )
        )
    );

    updateArticleGroup$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.UPDATE_ARTICLE_GROUP),
            mergeMap((props: { articleGroup: ArticleGroup }) =>
                this.articleService.updateArticleGroup(props.articleGroup).pipe(
                    map((articleGroups: ArticleGroup[]) => {
                        return fromActions.UPDATE_ARTICLE_GROUP_SUCCESS({ articleGroups });
                    }),
                    catchError((error: Error) => {
                        return of(
                            fromActions.UPDATE_ARTICLE_GROUP_FAIL({
                                error: fromMappings.ToError(error)
                            })
                        );
                    })
                )
            )
        )
    );

    createArticleGroup$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.CREATE_ARTICLE_GROUP),
            mergeMap((props: { articleGroup: ArticleGroup }) =>
                this.articleService.createArticleGroup(props.articleGroup).pipe(
                    map((articleGroups: ArticleGroup[]) => {
                        return fromActions.CREATE_ARTICLE_GROUP_SUCCESS({ articleGroups });
                    }),
                    catchError((error: Error) => {
                        return of(
                            fromActions.CREATE_ARTICLE_GROUP_FAIL({
                                error: fromMappings.ToError(error)
                            })
                        );
                    })
                )
            )
        )
    );
    //#endregion

    constructor(
        public router: Router,
        private actions$: Actions,
        private articleService: ArticleService,
        private store: Store<fromStore.CoreState>,
        private notificationService: NotificationService
    ) {}
}
