import { createAction, props } from '@ngrx/store';

const actionPrefix = '[Global]';

//#region Actions
export const SET_WINDOW_SIZE = createAction(
    `${actionPrefix} Set window size`,
    props<{ size: number }>()
);
//#endregion
