import { createAction, props } from '@ngrx/store';
import * as fromModel from '@shared/models';

const actionName = '[PdfDocs]';

export const GET_PDF_DOCS_QUERY_PARAMS = createAction(
    `${actionName} Get pdfDocs by query params from the API`,
    props<{ searchQuery: fromModel.ListQueryParams }>()
);
export const GET_PDF_DOCS_QUERY_PARAMS_SUCCESS = createAction(
    `${actionName} Get pdfDocs by query params from the API success`,
    props<{ pdfDocs: fromModel.PdfDocs[] }>()
);
export const GET_PDF_DOCS_QUERY_PARAMS_FAIL = createAction(
    `${actionName} Get pdfDocs by query params from the API fail`,
    props<{ error: any }>()
);

// export const GET_PDF_DOCS = createAction(`${actionName} Get all pdfDocs from the API`);
// export const GET_PDF_DOCS_SUCCESS = createAction(
//     `${actionName} Get all pdfDocs from the API success`,
//     props<{ pdfDocs: fromModel.PdfDocs[] }>()
// );
// export const GET_PDF_DOCS_FAIL = createAction(
//     `${actionName} Get all pdfDocs from the API fail`,
//     props<{ error: any }>()
// );

// export const GET_PDF_DOCS_BY_ID = createAction(
//     `${actionName} Get pdfDocs by id from the API`,
//     props<{ pdfDocsId: string }>()
// );
// export const GET_PDF_DOCS_BY_ID_SUCCESS = createAction(
//     `${actionName} Get pdfDocs by id from the API success`,
//     props<{ pdfDocs: fromModel.PdfDocs[] }>()
// );
// export const GET_PDF_DOCS_BY_ID_FAIL = createAction(
//     `${actionName} Get pdfDocs by id from the API fail`,
//     props<{ error: any }>()
// );

// export const GET_PDF_DOCS_BY_USER_ID = createAction(
//     `${actionName} Get pdfDocs by user id from the API`,
//     props<{ userId: string }>()
// );
// export const GET_PDF_DOCS_BY_USER_ID_SUCCESS = createAction(
//     `${actionName} Get pdfDocs by user id from the API success`,
//     props<{ pdfDocs: fromModel.PdfDocs[] }>()
// );
// export const GET_PDF_DOCS_BY_USER_ID_FAIL = createAction(
//     `${actionName} Get pdfDocs by user id from the API fail`,
//     props<{ error: any }>()
// );

// export const CREATE_PDF_DOCS = createAction(
//     `${actionName} Create pdfDocs object`,
//     props<{ pdfDocs: fromModel.PdfDocs }>()
// );
// export const CREATE_PDF_DOCS_SUCCESS = createAction(
//     `${actionName} Create pdfDocs object success`,
//     props<{ pdfDocs: fromModel.PdfDocs[] }>()
// );
// export const CREATE_PDF_DOCS_FAIL = createAction(
//     `${actionName} Create pdfDocs object success fail`,
//     props<{ error: any }>()
// );
