import { createAction, props } from '@ngrx/store';
import { ListQueryParams, ReportData, ReportSearchQuery, UserEmailNotify } from '@shared/models';

const actionName = '[Report]';
const actionNameEmail = '[EmailNotify]';

export const CREATE_REPORT = createAction(
    `${actionName} Create report`,
    props<{ reportQuery: ReportSearchQuery }>()
);
export const CREATE_REPORT_SUCCESS = createAction(
    `${actionName} Create report success`,
    props<{ reportData: ReportData[] }>()
);
export const CREATE_REPORT_FAIL = createAction(
    `${actionName} Create report fail`,
    props<{ error: any }>()
);

export const CLEAR_REPORTS = createAction(`${actionName} Clear reports`);

export const CREATE_USER_EMAIL_NOTIFY = createAction(
    `${actionNameEmail} User email notify`,
    props<{ userSetup: UserEmailNotify }>()
);
export const CREATE_USER_EMAIL_NOTIFY_SUCCESS = createAction(
    `${actionNameEmail} User email notify success`,
    props<{ setupData: any }>()
);
export const CREATE_USER_EMAIL_NOTIFY_FAIL = createAction(
    `${actionNameEmail} User email notify fail`,
    props<{ error: any }>()
);

export const GET_USER_EMAIL_NOTIFY = createAction(
    `${actionNameEmail} Get user email notify by userId`,
    props<{ userId: string }>()
);
export const GET_USER_EMAIL_NOTIFY_SUCCESS = createAction(
    `${actionNameEmail} Get user email notify by userId success`,
    props<{ setupData: UserEmailNotify[] }>()
);
export const GET_USER_EMAIL_NOTIFY_FAIL = createAction(
    `${actionNameEmail} Get user email notify by userId fail`,
    props<{ error: any }>()
);

export const GET_EMAIL_NOTIFY = createAction(
    `${actionNameEmail} Get user email notify`,
    props<{ searchQuery: ListQueryParams }>()
);
export const GET_EMAIL_NOTIFY_SUCCESS = createAction(
    `${actionNameEmail} Get user email notify success`,
    props<{ setupData: UserEmailNotify[] }>()
);
export const GET_EMAIL_NOTIFY_FAIL = createAction(
    `${actionNameEmail} Get user email notify fail`,
    props<{ error: any }>()
);
