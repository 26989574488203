//#region Imports
import { Injectable } from '@angular/core';
import { environment as env } from '@env/environment';
import { HttpClient } from '@angular/common/http';

import { map } from 'rxjs/operators';
import * as fromModel from '@shared/models';
import { Observable } from 'rxjs';
import { createSearchQueryParams } from '@shared/functions/functions';
//#endregion

@Injectable({
    providedIn: 'root'
})
export class UserService {
    endpoint: string = env.server;

    constructor(private http: HttpClient) {}

    //#region Roles
    getRoles(): Observable<fromModel.Role[]> {
        return this.http.get<fromModel.Role[]>(`${this.endpoint}get-role`);
    }

    getRoleById(roleId: string): Observable<fromModel.Role[]> {
        return this.http.get<fromModel.Role[]>(`${this.endpoint}read-role?roleId=${roleId}`);
    }

    getRoleByName(name: string): Observable<boolean | string> {
        return this.http.get<boolean | string>(`${this.endpoint}get-role-by-name?name=${name}`);
    }

    updateRole(role: fromModel.Role): Observable<fromModel.Role[]> {
        return this.http.put<fromModel.Role[]>(
            `${this.endpoint}update-role?roleId=${role.roleId}`,
            role
        );
    }

    createRole(role: fromModel.Role): Observable<fromModel.Role[]> {
        return this.http.post<fromModel.Role[]>(`${this.endpoint}create-role`, role);
    }
    //#endregion

    //#region User
    getUsersWithQueryParams(
        searchQuery: fromModel.ListQueryParams
    ): Observable<fromModel.User[]> {
        return this.http.get<fromModel.User[]>(
            `${this.endpoint}get-user-by-query${createSearchQueryParams(searchQuery)}`
        );
    }

    getUsers(active: number): Observable<fromModel.UserSimple[]> {
        return this.http.get<fromModel.UserSimple[]>(
            `${this.endpoint}get-user?active=${active}`
        );
    }

    getUserById(userId: string): Observable<fromModel.User[]> {
        return this.http.get<fromModel.User[]>(`${this.endpoint}read-user?userId=${userId}`);
    }

    getUserByExternalId(externalId: string): Observable<fromModel.User[]> {
        return this.http.get<fromModel.User[]>(
            `${this.endpoint}read-user-external?externalId=${externalId}`
        );
    }

    getUserByDepartmentId(departmentId: string): Observable<fromModel.User[]> {
        return this.http.get<fromModel.User[]>(
            `${this.endpoint}read-user-department?departmentId=${departmentId}`
        );
    }

    getUserByClientDepartmentId(
        clientId: string,
        departmentId: string
    ): Observable<fromModel.User[]> {
        return this.http.get<fromModel.User[]>(
            `${this.endpoint}read-user-client-department?clientId=${clientId}&departmentId=${departmentId}`
        );
    }

    getUserByUsername(username: string): Observable<fromModel.User[]> {
        return this.http.get<fromModel.User[]>(
            `${this.endpoint}read-user-by-username?username=${username}`
        );
    }

    getUserByFullName(firstName: string, lastName: string): Observable<fromModel.User[]> {
        return this.http.get<fromModel.User[]>(
            `${this.endpoint}get-user-by-full-name?firstName=${firstName}&lastName=${lastName}`
        );
    }

    getPdfDocsByUserId(userId: string): Observable<fromModel.PdfDocs[]> {
        return this.http.get<fromModel.PdfDocs[]>(
            `${this.endpoint}get-pdf-doc-by-userId?userId=${userId}`
        );
    }

    updateUserPassword(value: {
        userId: string;
        password: string;
        confirmPassword: string;
    }): Observable<fromModel.User[]> {
        return this.http.put<fromModel.User[]>(
            `${this.endpoint}update-user-password?userId=${value.userId}`,
            value
        );
    }

    updateUserActive(value: fromModel.UserActive): Observable<fromModel.User[]> {
        return this.http.put<fromModel.User[]>(`${this.endpoint}update-user-active`, value);
    }

    updateUser(user: fromModel.User): Observable<fromModel.User[]> {
        return this.http
            .put<fromModel.User[]>(`${this.endpoint}update-user?userId=${user.userId}`, user)
            .pipe(map((updatedUser: fromModel.User[]) => updatedUser));
    }

    createUser(user: fromModel.User): Observable<fromModel.User[]> {
        return this.http.post<fromModel.User[]>(`${this.endpoint}create-user`, user);
    }
    //#endregion
}
