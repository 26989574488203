import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'articleColor'
})
export class ArticleColorPipe implements PipeTransform {
    transform(value: unknown): string {
        let result = '';
        switch (Number(value)) {
            case 0:
                result = 'article.colors.white';
                break;
            case 1:
                result = 'article.colors.black';
                break;
            case 2:
                result = 'article.colors.blue';
                break;
            case 3:
                result = 'article.colors.darkBlue';
                break;
            case 4:
                result = 'article.colors.beige';
                break;
            case 5:
                result = 'article.colors.yellow';
                break;
            case 6:
                result = 'article.colors.green';
                break;
            case 7:
                result = 'article.colors.lightGray';
                break;
            case 8:
                result = 'article.colors.darkGray';
                break;
            case 9:
                result = 'article.colors.red';
                break;
        }

        return result;
    }
}

