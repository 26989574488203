//#region Imports
import { createSelector } from '@ngrx/store';
// import { UI_CONSTANTS } from '@shared/config/constants';
import { toObjectArray } from '@shared/functions/functions';
// import { cloneDeep, groupBy } from 'lodash-es';
import * as fromFeature from './../reducers';
//#endregion

const articleState = createSelector(
    fromFeature.getCoreState,
    (state: fromFeature.CoreState) => state.article
);

//#region Article Group
const getAllArticleGroups = createSelector(articleState, (state) => {
    return toObjectArray(state.group);
});

const getAllArticleGroupsSimple = createSelector(articleState, (state) => {
    return toObjectArray(state.groupSimple);
});

const getArticleGroupById = createSelector(articleState, (state) => state.selectedArticleGroup);

const getArticleGroupTotalNumberOfEntities = createSelector(
    articleState,
    (state) => state.totalGroup
);

const getArticleGroupIsLoaded = createSelector(articleState, (state) => state.isGroupLoaded);

const getArticleGroupIsLoading = createSelector(articleState, (state) => state.isGroupLoading);
//#endregion

//#region Article
const getAllArticles = createSelector(articleState, (state) => toObjectArray(state.articles));

const getArticleHistory = createSelector(articleState, (state) =>
    toObjectArray(state.articleHistory)
);

const getArticleUserHistory = createSelector(articleState, (state) =>
    toObjectArray(state.articleUserHistory)
);

const getAllArticlesSimple = createSelector(articleState, (state) =>
    toObjectArray(state.articleSimple)
);

const getAllArticlesTemp = createSelector(articleState, (state) =>
    toObjectArray(state.articleTemp)
);

const getAllArticlesTempSimple = createSelector(articleState, (state) =>
    toObjectArray(state.articleTempSimple)
);

const getAllArticleCharge = createSelector(articleState, (state) => state.chargedArticles);

const getArticleChargeByStatus = createSelector(articleState, (state) =>
    toObjectArray(state.articlesForWash)
);

const getTempArticleChargeByUser = createSelector(articleState, (state) =>
    toObjectArray(state.chargedTempArticles)
);

const getArticleChargeHistory = createSelector(
    articleState,
    (state) => state.chargedArticlesHistory
);

const getArticleMultipleEdit = createSelector(articleState, (state) => state.multipleEditArticles);

const getArticleById = createSelector(articleState, (state) => state.selectedArticle);

const getUserLastCharge = createSelector(articleState, (state) => state.userLastCharge);

const getArticleTotalNumberOfEntities = createSelector(articleState, (state) => state.total);

const getArticleIsLoaded = createSelector(articleState, (state) => state.isArticleLoaded);

const getArticleIsLoading = createSelector(articleState, (state) => state.isArticleLoading);

const getArticleError = createSelector(articleState, (state) => state.error);

const getArticleChargeFailMsg = createSelector(articleState, (state) => state.chargeFailMsg);
//#endregion

export const ArticleSelectors = {
    articleState,
    getAllArticles,
    getArticleHistory,
    getArticleUserHistory,
    getAllArticlesSimple,
    getAllArticlesTemp,
    getAllArticlesTempSimple,
    getAllArticleCharge,
    getArticleChargeByStatus,
    getTempArticleChargeByUser,
    getArticleChargeHistory,
    getArticleById,
    getUserLastCharge,
    getArticleTotalNumberOfEntities,
    getArticleMultipleEdit,
    getArticleIsLoaded,
    getArticleIsLoading,
    getArticleError,
    getArticleChargeFailMsg
};

export const ArticleGroupSelectors = {
    articleState,
    getAllArticleGroups,
    getAllArticleGroupsSimple,
    getArticleGroupById,
    getArticleGroupTotalNumberOfEntities,
    getArticleGroupIsLoaded,
    getArticleGroupIsLoading
};
