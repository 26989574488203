//#region Imports
import { createSelector } from '@ngrx/store';
import { toObjectArray } from '@shared/functions/functions';
import * as fromFeature from './../reducers';
//#endregion

const pdfDocsState = createSelector(
    fromFeature.getCoreState,
    (state: fromFeature.CoreState) => state.pdfDocs
);

const getPdfDocs = createSelector(pdfDocsState, (state) => toObjectArray(state.documents));

const getTotalNumberOfEntities = createSelector(pdfDocsState, (state) => state.total);

const getIsLoaded = createSelector(pdfDocsState, (state) => state.isLoaded);

const getIsLoading = createSelector(pdfDocsState, (state) => state.isLoading);

const getError = createSelector(pdfDocsState, (state) => state.error);

export const PdfDocsSelectors = {
    pdfDocsState,
    getPdfDocs,
    getTotalNumberOfEntities,
    getIsLoaded,
    getIsLoading,
    getError
};
