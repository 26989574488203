//#region Imports
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { UI_CONSTANTS } from '@shared/config/constants';
import { DUKLEY_LOGO_BASE64 } from '@shared/config/dukley-logo';
import { DateTime } from 'luxon';

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
//#endregion

@Component({
    selector: 'ec-charged-items-dialog',
    templateUrl: './charged-items-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChargedItemsDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<ChargedItemsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public inputData: any,
        public translate: TranslateService
    ) {}

    onNoClick(): void {
        this.dialogRef.close();
    }

    private createChargeStatusString(status: number): string {
        const value = this.translate.instant('pipe.returned');
        if (status === 0) {
            return value;
        }
        if (status === 1) {
            return this.translate.instant('pipe.charged');
        }
        return value;
    }

    onPrint(): void {
        const title = this.translate.instant('pdfDocs.titleAll');
        const userTitle: string = this.translate.instant('pdfDocs.using');
        const dateTitle: string = this.translate.instant('pdfDocs.dateTime');
        const dateFrom = DateTime.fromJSDate(new Date()).toFormat(
            UI_CONSTANTS.DATE_FORMAT.DATE_TIME
        );

        let docDefinition = {
            pageSize: 'A4',
            pageOrientation: 'portrait',
            content: [
                {
                    image: DUKLEY_LOGO_BASE64,
                    width: 120,
                    style: 'logo'
                },
                {
                    text: title,
                    style: 'header'
                },
                {
                    text: `${dateTitle}: ${dateFrom}`,
                    style: 'date'
                },
                {
                    text: `${userTitle}: ${this.inputData.user.firstName} ${this.inputData.user.lastName}`,
                    style: 'userData'
                },
                {
                    table: {
                        headerRows: 1,
                        widths: ['*', 'auto', 'auto', 'auto', '*'],
                        body: [
                            [
                                this.translate.instant('pdfDocs.articleName'),
                                this.translate.instant('pdfDocs.index'),
                                this.translate.instant('pdfDocs.status'),
                                this.translate.instant('pdfDocs.createdBy'),
                                this.translate.instant('pdfDocs.createdAt')
                            ],
                            ...this.inputData.chargedItems.map((p) => [
                                p.name,
                                p.id,
                                this.createChargeStatusString(p.status),
                                p.createdName,
                                p.createdAt
                                    ? DateTime.fromSQL(p.createdAt).toFormat(
                                          UI_CONSTANTS.DATE_FORMAT.DATE_TIME
                                      )
                                    : ''
                            ])
                        ]
                    },
                    layout: {
                        fillColor: function (rowIndex, node, columnIndex) {
                            return rowIndex % 2 === 0 ? '#CCCCCC' : null;
                        }
                    }
                }
            ],
            styles: {
                logo: {
                    alignment: 'center',
                    margin: [0, 0, 0, 16]
                },
                header: {
                    bold: true,
                    decoration: 'underline',
                    fontSize: 22,
                    margin: [0, 8, 0, 16],
                    alignment: 'center'
                },
                date: {
                    bold: false,
                    fontSize: 18,
                    margin: [0, 0, 0, 16],
                    alignment: 'center'
                },
                userData: {
                    bold: false,
                    fontSize: 14,
                    margin: [0, 0, 0, 16],
                    alignment: 'left'
                }
            }
        };
        pdfMake.createPdf(docDefinition).open();
    }
}
