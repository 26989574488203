import { createSelector } from '@ngrx/store';
import * as fromFeature from './../reducers';

const authState = createSelector(
    fromFeature.getCoreState,
    (state: fromFeature.CoreState) => state.loggedIn
);

const getLoggedInUser = createSelector(authState, (state) => state.loggedInUser);

const isAdminSupervisor = createSelector(authState, (state) => {
    const isLoggedUserNull = state.loggedInUser !== null;
    const adminRole = state.loggedInUser.roleName === 'role.administrator';
    const superRole = state.loggedInUser.roleName === 'role.supervisor';

    return isLoggedUserNull && (adminRole || superRole);
});

const isAdmin = createSelector(authState, (state) => {
    const isLoggedUserNull = state.loggedInUser !== null;
    const adminRole = state.loggedInUser?.roleName === 'role.administrator';

    return isLoggedUserNull && adminRole;
});

const isSupervisor = createSelector(authState, (state) => {
    const isLoggedUserNull = state.loggedInUser !== null;
    const superRole = state.loggedInUser.roleName === 'role.supervisor';

    return isLoggedUserNull && superRole;
});

const isOperator = createSelector(authState, (state) => {
    const isLoggedUserNull = state.loggedInUser !== null;
    const operativeRole = state.loggedInUser.roleName === 'role.operative';

    return isLoggedUserNull && operativeRole;
});

const isTokenExpired = createSelector(authState, (state) => {
    const isAuthenticated = state.isAuthenticated !== null && state.isAuthenticated;
    const isLoggedUserNull = state.loggedInUser !== null;
    const tokenExpired = state.expireAt !== null && Date.now() >= state.expireAt * 1000;
    return isLoggedUserNull && !tokenExpired && isAuthenticated;
});

export const AuthSelectors = {
    getLoggedInUser,
    isAdminSupervisor,
    isAdmin,
    isSupervisor,
    isOperator,
    isTokenExpired
};
