//#region Imports
import { createReducer, on } from '@ngrx/store';

import { ErrorModel, ReportData, UserEmailNotify } from '@shared/models';
import {
    CREATE_REPORT,
    CREATE_REPORT_SUCCESS,
    CREATE_REPORT_FAIL,
    CLEAR_REPORTS,
    GET_USER_EMAIL_NOTIFY,
    GET_USER_EMAIL_NOTIFY_FAIL,
    GET_USER_EMAIL_NOTIFY_SUCCESS,
    GET_EMAIL_NOTIFY,
    GET_EMAIL_NOTIFY_SUCCESS,
    GET_EMAIL_NOTIFY_FAIL
} from '../actions';
//#endregion

export interface ReportState<T = any> {
    data: T;
    emailNotify: UserEmailNotify[];
    emailNotifyAll: UserEmailNotify[];
    isLoading: boolean;
    isLoaded: boolean;
    total: number;
    error: ErrorModel | null;
    errorEmail: ErrorModel | null;
}

export const initialState: ReportState = {
    data: {},
    emailNotify: [],
    emailNotifyAll: [],
    isLoaded: false,
    isLoading: false,
    total: 0,
    error: null,
    errorEmail: null
};

const reportReducerState = createReducer<ReportState>(
    initialState,
    on(CREATE_REPORT, (state: ReportState) => {
        return {
            ...state,
            data: {},
            isLoaded: false,
            isLoading: true,
            error: null
        };
    }),
    on(CREATE_REPORT_SUCCESS, (state: ReportState, payload: any) => {
        const createReport: ReportData = payload.reportData.msg;

        return {
            ...state,
            data: createReport,
            isLoaded: true,
            isLoading: false,
            error: null
        };
    }),
    on(CREATE_REPORT_FAIL, (state: ReportState, payload: any) => {
        const error: ErrorModel = payload.error;

        return {
            ...state,
            data: {},
            isLoaded: false,
            isLoading: false,
            error
        };
    }),
    on(CLEAR_REPORTS, (state: ReportState) => {
        return {
            ...state,
            data: {},
            isLoaded: true,
            isLoading: false,
            error: null
        };
    }),

    on(GET_EMAIL_NOTIFY, (state: ReportState) => {
        return {
            ...state,
            emailNotifyAll: [],
            isLoaded: false,
            isLoading: true,
            errorEmail: null
        };
    }),
    on(GET_EMAIL_NOTIFY_SUCCESS, (state: ReportState, payload: any) => {
        const emailNotifyData: UserEmailNotify[] = payload.setupData.msg;
        const total: number = emailNotifyData[0].total;

        return {
            ...state,
            emailNotifyAll: emailNotifyData,
            total,
            isLoaded: true,
            isLoading: false,
            errorEmail: null
        };
    }),
    on(GET_EMAIL_NOTIFY_FAIL, (state: ReportState, payload: any) => {
        const error: ErrorModel = payload.error;

        return {
            ...state,
            emailNotifyAll: [],
            isLoaded: false,
            isLoading: false,
            errorEmail: error
        };
    }),

    on(GET_USER_EMAIL_NOTIFY, (state: ReportState) => {
        return {
            ...state,
            emailNotify: [],
            isLoaded: false,
            isLoading: true,
            errorEmail: null
        };
    }),
    on(GET_USER_EMAIL_NOTIFY_SUCCESS, (state: ReportState, payload: any) => {
        const emailNotifyData: UserEmailNotify[] = payload.setupData.msg;

        return {
            ...state,
            emailNotify: emailNotifyData,
            isLoaded: true,
            isLoading: false,
            errorEmail: null
        };
    }),
    on(GET_USER_EMAIL_NOTIFY_FAIL, (state: ReportState, payload: any) => {
        const error: ErrorModel = payload.error;

        return {
            ...state,
            emailNotify: [],
            isLoaded: false,
            isLoading: false,
            errorEmail: error
        };
    })
);

/**
 *
 * @param {ReportState} state
 * @param {ReportActions} action
 * @return {reportReducerState} reportReducerState
 */
export function reportReducer(state: ReportState, action: any) {
    return reportReducerState(state, action);
}
