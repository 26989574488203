//#region Imports
import * as fromActions from './../actions';
import { createReducer, on } from '@ngrx/store';
import * as fromModel from '@shared/models';
//#endregion

export interface PositionState {
    positions: { [positionId: string]: fromModel.Position };
    positionSimple: { [positionId: string]: fromModel.Position };
    total: number;
    selectedPosition: fromModel.Position | null;

    isLoading: boolean;
    isLoaded: boolean;
    error: fromModel.ErrorModel | null;
}

export const initialState: PositionState = {
    positions: {},
    positionSimple: {},
    total: 0,
    selectedPosition: null,

    isLoaded: false,
    isLoading: false,
    error: null
};

const positionReducerState = createReducer(
    initialState,
    on(
        fromActions.GET_POSITION_QUERY_PARAMS,
        fromActions.GET_POSITION,
        fromActions.GET_POSITION_BY_ID,
        fromActions.GET_POSITION_BY_USER_ID,
        fromActions.UPDATE_POSITION,
        fromActions.CREATE_POSITION,
        (state: PositionState) => {
            return {
                ...state,
                isLoaded: false,
                isLoading: true,
                error: null
            };
        }
    ),
    on(fromActions.GET_POSITION_QUERY_PARAMS_SUCCESS, (state: PositionState, payload: any) => {
        const getPosition: fromModel.Position[] = payload.positions.msg;
        const total: number = payload.positions.total;
        const entities = getPosition.reduce(
            (
                entity: { [positionId: string]: fromModel.Position },
                position: fromModel.Position
            ) => {
                return {
                    ...entity,
                    [position.positionId]: position
                };
            },
            {}
        );
        return {
            ...state,
            positions: entities,
            total,
            isLoaded: true,
            isLoading: false,
            error: null
        };
    }),
    on(fromActions.GET_POSITION_QUERY_PARAMS_FAIL, (state: PositionState, payload: any) => {
        const error: fromModel.ErrorModel = payload.error;
        return {
            ...state,
            positions: {},
            total: 0,
            isLoaded: false,
            isLoading: false,
            error
        };
    }),

    on(fromActions.GET_POSITION_SUCCESS, (state: PositionState, payload: any) => {
        const getPosition: fromModel.Position[] = payload.positions.msg;
        const entities = getPosition.reduce(
            (
                entity: { [positionId: string]: fromModel.Position },
                position: fromModel.Position
            ) => {
                return {
                    ...entity,
                    [position.positionId]: position
                };
            },
            {
                ...state.positionSimple
            }
        );
        return {
            ...state,
            positionSimple: entities,
            isLoaded: true,
            isLoading: false,
            error: null
        };
    }),
    on(
        fromActions.GET_POSITION_FAIL,
        fromActions.GET_POSITION_BY_ID_FAIL,
        fromActions.GET_POSITION_BY_USER_ID_FAIL,
        fromActions.UPDATE_POSITION_FAIL,
        fromActions.CREATE_POSITION_FAIL,
        (state: PositionState, payload: any) => {
            const error: fromModel.ErrorModel = payload.error;
            return {
                ...state,
                isLoaded: false,
                isLoading: false,
                error
            };
        }
    ),

    on(fromActions.GET_POSITION_BY_ID_SUCCESS, (state: PositionState, payload: any) => {
        return {
            ...state,
            selectedPosition: payload.position.msg[0],
            isLoaded: true,
            isLoading: false,
            error: null
        };
    }),

    on(fromActions.GET_POSITION_BY_USER_ID_SUCCESS, (state: PositionState, payload: any) => {
        const getPosition: fromModel.Position[] = payload.positions.msg;
        const entities = getPosition.reduce((idx, position) => {
            return {
                ...idx,
                [position.positionId]: position
            };
        }, {});
        return {
            ...state,
            positions: entities,
            isLoaded: true,
            isLoading: false,
            error: null
        };
    }),

    on(
        fromActions.CREATE_POSITION_SUCCESS,
        fromActions.UPDATE_POSITION_SUCCESS,
        (state: PositionState, payload: any) => {
            const position: fromModel.Position = payload.position.msg[0];
            const positions = {
                ...state.positions,
                [position.positionId]: position
            };
            return {
                ...state,
                positions,
                isLoaded: true,
                isLoading: false,
                error: null
            };
        }
    )
);

/**
 *
 * @param {PositionState} state
 * @param {PositionActions} action
 * @return {positionReducerState} positionReducerState
 */
export function positionReducer(state: PositionState, action: any) {
    return positionReducerState(state, action);
}
