//#region Imports
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { ClientService } from '@core/http/services';
import * as fromActions from '../actions';
import * as fromMappings from '@core/mappings';
import * as fromModel from '@shared/models';
//#endregion

@Injectable()
export class ClientEffects {
    loadClientsWithQueryParams$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.GET_CLIENT_QUERY_PARAMS),
            mergeMap((props: { searchQuery: fromModel.ListQueryParams }) =>
                this.clientService.getClientsWithQueryParams(props.searchQuery).pipe(
                    map((clients: fromModel.Client[]) => {
                        return fromActions.GET_CLIENT_QUERY_PARAMS_SUCCESS({ clients });
                    }),
                    catchError((error: Error) => {
                        return of(
                            fromActions.GET_CLIENT_QUERY_PARAMS_FAIL({
                                error: fromMappings.ToError(error)
                            })
                        );
                    })
                )
            )
        )
    );

    loadClients$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.GET_CLIENT),
            mergeMap((props: { active: number }) =>
                this.clientService.getClients(props.active).pipe(
                    map((clients: fromModel.Client[]) => {
                        return fromActions.GET_CLIENT_SUCCESS({ clients });
                    }),
                    catchError((error: Error) => {
                        return of(
                            fromActions.GET_CLIENT_FAIL({ error: fromMappings.ToError(error) })
                        );
                    })
                )
            )
        )
    );

    getClientById$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.GET_CLIENT_BY_ID),
            mergeMap((props: { clientId: string }) =>
                this.clientService.getClientById(props.clientId).pipe(
                    map((client: fromModel.Client[]) => {
                        return fromActions.GET_CLIENT_BY_ID_SUCCESS({ client });
                    }),
                    catchError((error: any) => {
                        return of(
                            fromActions.GET_CLIENT_BY_ID_FAIL({
                                error: fromMappings.ToError(error)
                            })
                        );
                    })
                )
            )
        )
    );

    getClientByUserId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.GET_CLIENT_BY_USER_ID),
            mergeMap((props: { userId: string }) =>
                this.clientService.getClientByUserId(props.userId).pipe(
                    map((clients: fromModel.Client[]) => {
                        return fromActions.GET_CLIENT_BY_USER_ID_SUCCESS({ clients });
                    }),
                    catchError((error: Error) => {
                        return of(
                            fromActions.GET_CLIENT_BY_USER_ID_FAIL({
                                error: fromMappings.ToError(error)
                            })
                        );
                    })
                )
            )
        )
    );

    updateClient$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.UPDATE_CLIENT),
            mergeMap((props: { client: fromModel.Client }) =>
                this.clientService.updateClient(props.client).pipe(
                    map((client: fromModel.Client[]) => {
                        return fromActions.UPDATE_CLIENT_SUCCESS({ client });
                    }),
                    catchError((error: Error) => {
                        return of(
                            fromActions.UPDATE_CLIENT_FAIL({ error: fromMappings.ToError(error) })
                        );
                    })
                )
            )
        )
    );

    createClient$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.CREATE_CLIENT),
            mergeMap((props: { client: fromModel.Client }) =>
                this.clientService.createClient(props.client).pipe(
                    map((client: fromModel.Client[]) => {
                        return fromActions.CREATE_CLIENT_SUCCESS({ client });
                    }),
                    catchError((error: Error) => {
                        return of(
                            fromActions.CREATE_CLIENT_FAIL({ error: fromMappings.ToError(error) })
                        );
                    })
                )
            )
        )
    );
    //#endregion

    constructor(
        private actions$: Actions,
        private clientService: ClientService
    ) {}
}
