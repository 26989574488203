//#region Imports
import { createSelector } from '@ngrx/store';
import { toObjectArray } from '@shared/functions/functions';
import * as fromFeature from './../reducers';
//#endregion

const departmentState = createSelector(
    fromFeature.getCoreState,
    (state: fromFeature.CoreState) => state.department
);

const getAllDepartments = createSelector(departmentState, (state) =>
    toObjectArray(state.departments)
);

const getAllDepartmentsSimple = createSelector(departmentState, (state) =>
    toObjectArray(state.departmentSimple)
);

const getDepartmentById = createSelector(departmentState, (state) => state.selectedDepartment);

const getDepartmentTotalNumberOfEntities = createSelector(departmentState, (state) => state.total);

const getDepartmentIsLoaded = createSelector(departmentState, (state) => state.isLoaded);

const getDepartmentIsLoading = createSelector(departmentState, (state) => state.isLoading);

const getDepartmentError = createSelector(departmentState, (state) => state.error);

export const DepartmentSelectors = {
    departmentState,
    getAllDepartments,
    getAllDepartmentsSimple,
    getDepartmentById,
    getDepartmentTotalNumberOfEntities,
    getDepartmentIsLoaded,
    getDepartmentIsLoading,
    getDepartmentError
};
