//#region Imports
import * as fromFeature from './../reducers';

import { createSelector } from '@ngrx/store';
import { toObjectArray } from '@shared/functions/functions';
//#endregion

const reportState = createSelector(
    fromFeature.getCoreState,
    (state: fromFeature.CoreState) => state.report
);

const getAllReports = createSelector(reportState, (state) => toObjectArray(state.data));

const getReportIsLoaded = createSelector(reportState, (state) => state.isLoaded);

const getReportIsLoading = createSelector(reportState, (state) => state.isLoading);

const getReportError = createSelector(reportState, (state) => state.error);

const getEmailNotify = createSelector(reportState, (state) =>
    Object.keys(state.emailNotifyAll).map((key) => state.emailNotifyAll[key])
);

const getUserEmailNotify = createSelector(reportState, (state) =>
    Object.keys(state.emailNotify).map((key) => state.emailNotifyAll[key])
);

const getUserNotifyTotalNumberOfEntities = createSelector(reportState, (state) => state.total);

const getEmailNotifyError = createSelector(reportState, (state) => state.error);

export const ReportSelectors = {
    reportState,
    getAllReports,
    getReportIsLoaded,
    getReportIsLoading,
    getReportError,

    getEmailNotify,
    getUserEmailNotify,
    getUserNotifyTotalNumberOfEntities,
    getEmailNotifyError
};
