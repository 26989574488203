//#region Imports
import { OverlayContainer } from '@angular/cdk/overlay';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    HostListener,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
// import * as from ';
import { Observable, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromStore from './core/store';
import * as service from '@core/http/services';
import { TranslateService } from '@ngx-translate/core';
import localeEnGb from '@angular/common/locales/en-GB';
import localeLatn from '@angular/common/locales/sr-Latn';
import { environment as env } from './../environments/environment';
// import { ShortcutOverviewComponent } from '@shared/components';
import { MatDrawerMode, MatSidenav } from '@angular/material/sidenav';
import * as fromModel from '@shared/models';
import { takeUntil } from 'rxjs/operators';
import { debounce, isNil } from 'lodash-es';
import { NotificationService } from '@core/notification/notification.service';
import { LocalStorageService } from 'ngx-webstorage';
import { AppMenuService } from './main-menu/service/menu-list.service';
import { AuthSelectors, UserSelectors } from './core/store';
import { NavItem } from './main-menu/menu-list-item/menu-list-item.component';
import { DateTime } from "luxon";
//#endregion

@Component({
    selector: 'ec-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
    //#region Variables
    private readonly onDestroy$ = new Subject<void>();

    user$: Observable<fromModel.User> = this.store.select(UserSelectors.getUserById);
    userIsExternalScan$: Observable<boolean> = this.store.select(UserSelectors.getUserExternalScan);
    isExternal: boolean;
    scannedUser: fromModel.User;
    @ViewChild('drawer') drawer: MatSidenav;

    routes: any[] = [];
    env = env.server;
    sideNavType: MatDrawerMode = 'side';
    sideNavBack = 'false';
    token: any;
    currentDate = DateTime.local().toLocaleString(DateTime.DATETIME_MED);
    currentYear = DateTime.local().year;
    theme$ = '';
    loggedUser$: Observable<fromModel.User> = this.store.select(AuthSelectors.getLoggedInUser);
    loggedUser: fromModel.User;
    languagesFancyNames: { value: string; viewValue: string }[] = [];

    isOperative = false;

    themesArray = [
        { value: 'black-theme', view: 'themes.black', icon: 'brightness_7' },
        { value: 'default-theme', view: 'themes.default', icon: 'brightness_6' },
        { value: 'light-theme', view: 'themes.light', icon: 'brightness_5' },
        { value: 'nature-theme', view: 'themes.nature', icon: 'eco' }
    ];

    barcode = '';
    barcodeTemp = '';
    interval;
    innerWidth: number;
    //#endregion

    constructor(
        public dialog: MatDialog,
        public translate: TranslateService,
        private store: Store<fromStore.CoreState>,
        private overlayContainer: OverlayContainer,
        private authService: service.AuthService,
        private router: Router,
        private cdr: ChangeDetectorRef,
        private notificationService: NotificationService,
        private localSt: LocalStorageService,
        private menuService: AppMenuService
    ) {
        translate.addLangs(['en', 'rs']);

        this.loggedUser$.subscribe((user) => {
            if (!isNil(user)) {
                const selectedLang = this.localSt.retrieve('selectedLang');

                this.loggedUser = user;
                this.isOperative = user.roleName === 'role.operative' ? true : false;
                this.theme$ = user.theme;

                translate.setDefaultLang('en');

                this.createNavItems(user);

                if (selectedLang !== user.lang) {
                    translate.use(selectedLang);
                    return;
                }
                translate.use(user.lang);
            }
        });
        this.store.dispatch(fromStore.SET_WINDOW_SIZE({ size: window.innerWidth }));

        this.onResize = debounce(this.onResize, 150, { leading: false, trailing: true });
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        if (this.innerWidth !== event.target.innerWidth) {
            this.innerWidth = event.target.innerWidth;
            this.store.dispatch(fromStore.SET_WINDOW_SIZE({ size: event.target.innerWidth }));
        }
    }

    @HostListener('document:keypress', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        if (this.interval) {
            clearInterval(this.interval);
        }
        if (event.key === 'Enter') {
            if (this.barcodeTemp) {
                this.barcode = this.barcodeTemp;
                this.handleScannedBarcode(this.barcode);
                this.barcodeTemp = '';
                return;
            }
        }
        if (event.key !== 'Enter') {
            this.barcodeTemp += event.key;
        }

        this.interval = setInterval(() => (this.barcodeTemp = ''), 100);
    }

    ngOnInit(): void {
        registerLocaleData(localeLatn, 'rs');
        registerLocaleData(localeEnGb, 'en');
        this.overlayContainer.getContainerElement().classList.add(this.theme$);

        this.createLanguageArray(this.translate.getLangs());
        this.subToUserExternalId();
        this.isExternal = false;
        // this.subToUserIsExternalScan();
    }

    private handleScannedBarcode(barcode: string): void {
        const barcodeChangeChar = barcode.replace(/'/g, '-');
        const barcodeArray = barcodeChangeChar.split(/[.\* +-/'/_]/);

        if (barcodeArray.length === 1) {
            this.handleUserCode(barcode);
        } else {
            this.handleQRCode(barcodeChangeChar);
        }
    }

    private handleUserCode(barcode: string): void {
        this.store.dispatch(fromStore.GET_USER_BY_EXTERNAL_ID({ externalId: barcode }));
        localStorage.setItem('externalId', barcode);
        this.isExternal = true;
    }

    private handleQRCode(barcode: string): void {
        this.localSt.store('barcode', barcode);
    }

    subToUserExternalId(): void {
        this.user$.pipe(takeUntil(this.onDestroy$)).subscribe((userBy) => {
            if (userBy) {
                this.scannedUser = userBy;
                if (isNil(this.scannedUser.userId)) {
                    this.notificationService.error('user.selectUserFromTheList');
                    return;
                }
                if (this.isExternal) {
                    this.isExternal = false;
                    this.router.navigate(['article-charge']);
                }
            }
        });
    }

    private createLanguageArray(languages: string[]): void {
        for (const i of languages) {
            if (i === 'rs') {
                this.languagesFancyNames.push({ value: 'rs', viewValue: 'RS - Serbian' });
            }
            if (i === 'en') {
                this.languagesFancyNames.push({ value: 'en', viewValue: 'EN - English' });
            }
        }
    }

    onPreventDefault(e: any): void {
        e.preventDefault();
        e.stopPropagation();
    }

    logOut(): void {
        this.authService.doLogout();
        this.store.dispatch(fromStore.LOGOUT());
    }

    // onKeyboardShortcut(): void {
    //     const dialogRef = this.dialog.open(ShortcutOverviewComponent, {
    //         width: `50%`,
    //         panelClass: 'ec-keyboard-shortcut-dialog-panel-class',
    //         disableClose: false
    //     });

    //     dialogRef.afterClosed().subscribe(() => {});
    // }

    switchLang(lang: string): void {
        this.localSt.store('selectedLang', lang);
        this.translate.use(lang);
        this.cdr.detectChanges();
    }

    switchTheme(theme: string): void {
        this.theme$ = theme;
        this.checkTheme(theme);
    }

    private checkTheme(theme: string): void {
        if (this.overlayContainer.getContainerElement().classList.contains('black-theme')) {
            this.overlayContainer.getContainerElement().classList.replace('black-theme', theme);
        }
        if (this.overlayContainer.getContainerElement().classList.contains('default-theme')) {
            this.overlayContainer.getContainerElement().classList.replace('default-theme', theme);
        }
        if (this.overlayContainer.getContainerElement().classList.contains('light-theme')) {
            this.overlayContainer.getContainerElement().classList.replace('light-theme', theme);
        }
        if (this.overlayContainer.getContainerElement().classList.contains('nature-theme')) {
            this.overlayContainer.getContainerElement().classList.replace('nature-theme', theme);
        }
    }

    onSelectedMenuItem(event: NavItem): void {
        if (event.children.length > 0) {
            return;
        }
        this.drawer.close();
    }

    private createNavItems(user: fromModel.User): void {
        this.routes = this.menuService.getNavigationMenuForUser(user);
    }
}
