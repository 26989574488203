//#region Imports
import { UserService } from './user.service';
import { AuthService } from './auth.service';
import { ArticleService } from './article.service';
import { DepartmentService } from './department.service';
import { PositionService } from './position.service';
import { ClientService } from './client.service';
import { QRCodeService } from './qrcode.service';
import { PdfDocsService } from './pdf-docs.service';
//#endregion

//#region Exports
export * from './user.service';
export * from './auth.service';
export * from './article.service';
export * from './department.service';
export * from './position.service';
export * from './client.service';
export * from './qrcode.service';
export * from './pdf-docs.service';
//#endregion

export const services: any[] = [
    UserService,
    AuthService,
    ArticleService,
    DepartmentService,
    PositionService,
    ClientService,
    QRCodeService,
    PdfDocsService
];
