import { Directive, Input, HostListener } from '@angular/core';

@Directive({
    selector: 'button[ecPrint]'
})
export class EcPrintDirective {
    /**
     *
     *
     * @memberof NgxPrintDirective
     */
    @Input() printSectionId: string;

    @Input() indexToPrint: string;

    /**
     *
     *
     * @memberof NgxPrintDirective
     */
    @Input() printTitle: string;

    /**
     *
     *
     * @memberof NgxPrintDirective
     */
    @Input() useExistingCss = false;
    /**
     * A delay in milliseconds to force the print dialog to wait before opened. Default: 0
     *
     * @memberof NgxPrintDirective
     */
    @Input() printDelay = 0;

    public _printStyle = [];

    // eslint-disable-next-line valid-jsdoc
    /**
     * Style
     *
     * @memberof NgxPrintDirective
     */
    @Input()
    set printStyle(values: { [key: string]: { [key: string]: string } }) {
        for (const key in values) {
            if (values.hasOwnProperty(key)) {
                this._printStyle.push((key + JSON.stringify(values[key])).replace(/['"]+/g, ''));
            }
        }
        this.returnStyleValues();
    }

    /**
     *
     *
     * @returns html for the given tag
     *
     * @memberof NgxPrintDirective
     */
    private _styleSheetFile = '';

    /**
     * @memberof NgxPrintDirective
     * @param {cssList} cssList
     */
    @Input()
    set styleSheetFile(cssList: string) {
        const linkTagFn = (cssFileName: any) =>
            `<link rel="stylesheet" type="text/css" href="${cssFileName}">`;
        // eslint-disable-next-line @typescript-eslint/prefer-includes
        if (cssList.indexOf(',') !== -1) {
            const valueArr = cssList.split(',');
            for (const val of valueArr) {
                this._styleSheetFile = this._styleSheetFile + linkTagFn(val);
            }
        } else {
            this._styleSheetFile = linkTagFn(cssList);
        }
    }

    // eslint-disable-next-line valid-jsdoc
    /**
     * @return the string that create the stylesheet which will be injected
     * later within <style></style> tag.
     *
     * -join/replace to transform an array objects to css-styled string
     *
     * @memberof NgxPrintDirective
     */
    public returnStyleValues() {
        return `<style> ${this._printStyle.join(' ').replace(/,/g, ';')} </style>`;
    }

    /**
     *
     *
     * @memberof NgxPrintDirective
     */
    @HostListener('click')
    public print(): void {
        setTimeout(() => {
            let styles = '';
            let links = '';

            if (this.useExistingCss) {
                styles = this.getElementTag('style');
                links = this.getElementTag('link');
            }

            const printTitleParse: {
                firstName: string;
                lastName: string;
                department: string;
                articleId: number;
                qrCode: string;
            } = JSON.parse(this.printTitle);

            const name = printTitleParse.firstName ? printTitleParse.firstName : '';
            const lastName = printTitleParse.lastName ? printTitleParse.lastName : '';
            const department = printTitleParse.department ? printTitleParse.department : '';
            const articleId = printTitleParse.articleId ? printTitleParse.articleId : '';
            const child: any = document.createElement('H2');

            const qrCode = printTitleParse.qrCode;
            const innerHTML_main = `
                <span style='font-size:110px; margin-left:16px; position:absolute; top:50px';>${name}</span>
                <span style='font-size:110px; margin-left:16px; position:absolute; top:162px'>${lastName}</span>
                <span style='font-size:100px; margin-left:16px; position:absolute; top:285px'>${department} ${articleId}</span>
            `;
            const innerHTML_qrcode = `
                <span style='font-size:110px; margin-left:16px; position:absolute; top:300px';>${qrCode}</span>
            `;

            child.innerHTML = !qrCode ? innerHTML_main : innerHTML_qrcode;

            // eslint-disable-next-line operator-linebreak
            const printContents =
                document.getElementById(this.printSectionId).innerHTML + child.innerHTML;

            const popupWin = window.open('', '_blank', 'top=0,left=0,height=auto,width=auto');
            popupWin.document.open();
            popupWin.document.write(`
                        <html>
                            <head>
                            <title>${this.printTitle ? this.printTitle : ''}</title>
                            ${this.returnStyleValues()}
                            ${this.returnStyleSheetLinkTags()}
                            ${styles}
                            ${links}
                            </head>
                            <body style="padding: 64px 0 0 24px;">
                            ${printContents}
                            <script defer>
                                function triggerPrint(event) {
                                window.removeEventListener('load', triggerPrint, false);
                                setTimeout(() => {
                                    window.print();
                                    setTimeout(function() { window.close(); }, 0);
                                }, ${this.printDelay});
                                }
                                window.addEventListener('load', triggerPrint, false);
                            </script>
                            </body>
                        </html>`);

            popupWin.document.close();
        }, 1000); //1 second timeout to hide paginator
    }

    // eslint-disable-next-line valid-jsdoc
    /**
     * @return string which contains the link tags containing the css which will
     * be injected later within <head></head> tag.
     *
     */
    private returnStyleSheetLinkTags() {
        return this._styleSheetFile;
    }

    private getElementTag(tag: keyof HTMLElementTagNameMap): string {
        const html: string[] = [];
        const elements = document.getElementsByTagName(tag);

        for (let index = 0; index < elements.length; index++) {
            html.push(elements[index].outerHTML);
        }
        return html.join('\r\n');
    }
}
