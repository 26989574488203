// export const paginationSizeOptions: number[] = [5, 10, 20, 50];
// export const paginationPageSize = 20;

// export const regex = {
//     letterNumberSpaceDash: /^[A-Za-z0-9\s-]+$/,
//     letterNumberSpaceDashParentheses: /^[A-Za-z0-9\s-,()šŠđĐžŽčČćĆ]+$/,
//     password: /^[a-zA-Z0-9]{4,}$/,
//     passwordStrong: /^(?=.*?[0-9])(?=.*?[A-Z])(?=.*?[#?!@$%^&*\-_]).{8,}$/,
//     email: /\S+@\S+\.\S+/,
//     mobile: /^(\+|\d)[0-9]{7,16}$/,
//     idNumber: /^[1-9][0-9]{8,14}$/,
//     bankNumber: /^[0-9][0-9]{8,17}$/,
//     letterSpaceCommaDotApostropheDash: /^[a-zA-Z0-9\s,.'-]{3,}$/,
//     points: /^[1-9]\d*$/,
//     numbers: /^[0-9]\d*$/,
//     numbersDashSlash: /^[0-9-//]+$/,
//     numbersSlash: /^[0-9/]+$/,
//     websiteStrong:
//         /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/,
//     website: /^[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/
// };

// export const isBasicQRCode = {
//     arrived: 1,
//     leave: 2,
//     break: 3,
//     arrivedLeave: 4,
//     leaveBreak: 5,
//     arrivedBreak: 6,
//     arrivedLeaveBreak: 7,
//     arrivedTitle: 'ARRIVED',
//     leaveTitle: 'LEFT',
//     breakTitle: 'BREAK'
// };

// export const DATE_FORMAT = 'y-MM-dd';

// export const NOTIFICATION_TIME_INTERVAL = 60000;
// import { DateTime } from 'luxon-business-days';

// export const dt = DateTime.local();
// const businessWeek = [1, 2, 3, 4, 5];
// dt.setupBusiness({ businessDays: businessWeek });

export const UI_CONSTANTS = {
    REGEX: {
        LETTER_NUMBER_SPACE_DASH: /^[A-Za-z0-9\s-]+$/,
        LETTER_NUMBER_SPACE_DASH_PARENTHESES: /^[A-Za-z0-9\s-,()šŠđĐžŽčČćĆ]+$/,
        PASSWORD: /^[a-zA-Z0-9]{4,}$/,
        PASSWORD_STRONG: /^(?=.*?[0-9])(?=.*?[A-Z])(?=.*?[#?!@$%^&*\-_]).{8,}$/,
        EMAIL_SOFT: /\S+@\S+\.\S+/,
        MOBILE: /^(\+|\d)[0-9]{7,16}$/,
        ID_NUMBER: /^[1-9][0-9]{8,14}$/,
        BANK_NUMBER: /^[0-9][0-9]{8,17}$/,
        LETTER_SPACE_COMMA_DOT_APOSTROPHE_DASH: /^[a-zA-Z0-9\s,.'-]{3,}$/,
        POINTS: /^[1-9]\d*$/,
        NUMBERS: /^[0-9]\d*$/,
        NUMBERS_DASH_SLASH: /^[0-9-//]+$/,
        NUMBERS_SLASH: /^[0-9/]+$/,
        WEBSITE_STRONG:
            /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/,
        WEBSITE:
            /^[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/
    },
    PAGINATION: {
        PAGINATION_SIZE_OPTIONS: [5, 10, 30, 50, 100],
        PAGINATION_PAGE_SIZE: 30,
        SORT_VALUE: 'updatedAt',
        SORT_VALUE_CREATED_AT: 'createdAt',
        SORT_DIRECTION: 'desc'
    },
    QR_CODE_TYPE_ENUM: {
        ARRIVED: 1,
        LEAVE: 2,
        BREAK: 3,
        ARRIVED_LEAVE: 4,
        LEAVE_BREAK: 5,
        ARRIVE_BREAK: 6,
        ARRIVE_LEAVE_BREAK: 7,
        ARRIVE_TITLE: 'ARRIVED',
        LEAVE_TITLE: 'LEFT',
        BREAK_TITLE: 'BREAK'
    },
    DATE_FORMAT: {
        DAYS_OF_WEEK: '',
        DATE: 'dd-MM-y',
        DATE_TIME: 'dd-MM-y TT'
    },
    NOTIFICATION_TYPE: {
        DEFAULT: 0,
        INFO: 1,
        SUCCESS: 2,
        WARN: 3,
        ERROR: 4
    },
    NOTIFICATION_TIME_INTERVAL: 60000,
    TOAST_MESSAGE_DURATION: 10000,
    GENDER: [
        { value: 'FEMALE', viewValue: 'Female' },
        { value: 'MALE', viewValue: 'Male' },
        { value: 'OTHER', viewValue: 'Other' }
    ],
    OBJECT: {
        MULTI_PURPOSE: 'MULTI_PURPOSE',
        WAREHOUSE: 'WAREHOUSE'
    },
    QR_CODE_TYPE: {
        RETURN: 0,
        CHARGE: 1,
        FINISH: 2
    },
    MAX_IMAGE_SIZE_BIG: 5242880,
    MAX_IMAGE_SIZE_SMALL: 1048576,
    ARTICLE: {
        MIN_QUANTITY_PERCENT: 30,
        AVG_QUANTITY_PERCENT: 70,
        SIZES: [
            'XS',
            'S',
            'M',
            'L',
            'XL',
            'XXL',
            'XXXL',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44',
            '46',
            '48',
            '50',
            '52',
            '54',
            '56',
            '58',
            '60',
            '62'
        ],
        COLORS: [
            { value: 0, viewValue: 'article.colors.white' },
            { value: 1, viewValue: 'article.colors.black' },
            { value: 2, viewValue: 'article.colors.blue' },
            { value: 3, viewValue: 'article.colors.darkBlue' },
            { value: 4, viewValue: 'article.colors.beige' },
            { value: 5, viewValue: 'article.colors.yellow' },
            { value: 6, viewValue: 'article.colors.green' },
            { value: 7, viewValue: 'article.colors.lightGray' },
            { value: 8, viewValue: 'article.colors.darkGray' },
            { value: 9, viewValue: 'article.colors.red' }
        ]
    },
    ROLES: {
        ADMIN: 'role.administrator',
        SUPER: 'role.supervisor',
        OPERATIVE: 'role.operative',
        USER: 'role.user'
    },
    ACTIVE_STATE: {
        NOT_ACTIVE: 0,
        ACTIVE: 1,
        WRITE_OFF: 2,
        ALL: 3
    },
    CHARGE_STATUS: {
        RETURN: 0,
        CHARGE: 1,
        SENT_TO_WASH: 2,
        READY_TO_CHARGE: 3,
        USER_RETURNED_ARTICLE: 4,
        LOST: 5,
        DAMAGED: 6,
        ALL: 7
    },
    ARTICLE_USER_STATUS: {
        CHARGE: 0,
        LOST: 1,
        DAMAGED: 2,
        RETURN: 3,
        ALL: 7
    },
    PDF_TYPES: {
        USER_VERIFY: 0,
        USER_RETURN_ARTICLE: 1,
        SENT_FOR_A_WASH: 2,
        READY_TO_CHARGE: 3,
        ALL: 4,
        CHANGED_ARTICLES: 5,
        ARTICLE_GROUP_RETURN: 6
    },
    DEBOUNCE: {
        SHORT: 200,
        MID: 500,
        LONG: 1000
    },
    TABLE_TYPE: {
        COMPACT: 0,
        NORMAL: 1
    },
    WINDOW_SIZE: {
        SMALL: 830,
        MEDIUM: 1052,
        BIG: 1292
    },
    COLORS: {
        WHITE: 0,
        BLACK: 1,
        BLUE: 2,
        DARK_BLUE: 3,
        BEIGE: 4,
        YELLOW: 5,
        GREEN: 6,
        LIGHT_GRAY: 7,
        DARK_GRAY: 8,
        RED: 9
    }
};
