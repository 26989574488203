//#region Imports
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { SharedModule } from './../shared/shared.module';
import { StoreModule } from '@ngrx/store';
import * as fromCore from './store';
import { EffectsModule } from '@ngrx/effects';
import { effects } from './store';
//#endregion

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        HttpClientModule,
        StoreModule.forFeature('core', fromCore.reducers),
        EffectsModule.forFeature(effects)
    ]
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        throwIfAlreadyLoaded(parentModule, 'CoreModule');
    }
}

/**
 *
 * @param {any} parentModule
 * @param {string} moduleName
 */
export function throwIfAlreadyLoaded(parentModule: any, moduleName: string) {
    if (parentModule) {
        throw new Error(
            `${moduleName} has already been loaded. Import Core module in the AppModule only`
        );
    }
}
