//#region Imports
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { PositionService } from '@core/http/services/position.service';
import * as fromActions from '../actions';
import * as fromMappings from '@core/mappings';
import * as fromModel from '@shared/models';
//#endregion

@Injectable()
export class PositionEffects {
    loadPositionsWithQueryParams$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.GET_POSITION_QUERY_PARAMS),
            mergeMap((props: { searchQuery: fromModel.ListQueryParams }) =>
                this.positionService.getPositionsWithQueryParams(props.searchQuery).pipe(
                    map((positions: fromModel.Position[]) => {
                        return fromActions.GET_POSITION_QUERY_PARAMS_SUCCESS({ positions });
                    }),
                    catchError((error: Error) => {
                        return of(
                            fromActions.GET_POSITION_QUERY_PARAMS_FAIL({
                                error: fromMappings.ToError(error)
                            })
                        );
                    })
                )
            )
        )
    );

    loadPositions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.GET_POSITION),
            mergeMap(() =>
                this.positionService.getPositions().pipe(
                    map((positions: fromModel.Position[]) => {
                        return fromActions.GET_POSITION_SUCCESS({ positions });
                    }),
                    catchError((error: Error) => {
                        return of(
                            fromActions.GET_POSITION_FAIL({ error: fromMappings.ToError(error) })
                        );
                    })
                )
            )
        )
    );

    getPositionById$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.GET_POSITION_BY_ID),
            mergeMap((props: { positionId: string }) =>
                this.positionService.getPositionById(props.positionId).pipe(
                    map((position: fromModel.Position[]) => {
                        return fromActions.GET_POSITION_BY_ID_SUCCESS({ position });
                    }),
                    catchError((error: any) => {
                        return of(
                            fromActions.GET_POSITION_BY_ID_FAIL({
                                error: fromMappings.ToError(error)
                            })
                        );
                    })
                )
            )
        )
    );

    getPositionByUserId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.GET_POSITION_BY_USER_ID),
            mergeMap((props: { userId: string }) =>
                this.positionService.getPositionByUserId(props.userId).pipe(
                    map((positions: fromModel.Position[]) => {
                        return fromActions.GET_POSITION_BY_USER_ID_SUCCESS({ positions });
                    }),
                    catchError((error: Error) => {
                        return of(
                            fromActions.GET_POSITION_BY_USER_ID_FAIL({
                                error: fromMappings.ToError(error)
                            })
                        );
                    })
                )
            )
        )
    );

    updatePosition$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.UPDATE_POSITION),
            mergeMap((props: { position: fromModel.Position }) =>
                this.positionService.updatePosition(props.position).pipe(
                    map((position: fromModel.Position[]) => {
                        return fromActions.UPDATE_POSITION_SUCCESS({ position });
                    }),
                    catchError((error: Error) => {
                        return of(
                            fromActions.UPDATE_POSITION_FAIL({ error: fromMappings.ToError(error) })
                        );
                    })
                )
            )
        )
    );

    createPosition$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.CREATE_POSITION),
            mergeMap((props: { position: fromModel.Position }) =>
                this.positionService.createPosition(props.position).pipe(
                    map((position: fromModel.Position[]) => {
                        return fromActions.CREATE_POSITION_SUCCESS({ position });
                    }),
                    catchError((error: Error) => {
                        return of(
                            fromActions.CREATE_POSITION_FAIL({ error: fromMappings.ToError(error) })
                        );
                    })
                )
            )
        )
    );
    //#endregion

    constructor(
        private actions$: Actions,
        private positionService: PositionService
    ) {}
}
