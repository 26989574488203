import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'ec-box-item',
    templateUrl: './box-item.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BoxItemComponent {
    //#region Variables
    @Input() title: string;

    @Input() text: string;
    //#endregion
}
