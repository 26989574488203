/* eslint-disable @typescript-eslint/indent */
//#region Imports
import { createReducer, on } from '@ngrx/store';
import * as fromActions from './../actions';
import * as fromModel from '@shared/models';
//#endregion

export interface GlobalState {
    size: number;
    isLoading: boolean;
    isLoaded: boolean;
}

export const initialState: GlobalState = {
    size: 0,
    isLoaded: false,
    isLoading: false
};

const globalReducerState = createReducer(
    initialState,
    on(fromActions.SET_WINDOW_SIZE, (state: GlobalState, payload: any) => {
        const size: number = payload.size;

        return {
            ...state,
            size,
            isLoaded: false,
            isLoading: false
        };
    })
);

/**
 * Some comment
 * @param {GlobalState} state
 * @param {any} action
 * @return {globalReducerState}
 */
export function globalReducer(state: GlobalState, action: any) {
    return globalReducerState(state, action);
}
