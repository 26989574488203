//#region Imports
import { Component, Input, HostBinding, Output, EventEmitter } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Router } from '@angular/router';
import * as fromModel from '@shared/models';
//#endregion

export interface NavItem {
    title: string;
    disabled?: boolean;
    icon: string;
    link?: string;
    children?: NavItem[];
    newItems?: number;
    roleCanSee: string[];
}

@Component({
    selector: 'ec-menu-list-item',
    template: `
        <mat-list-item
            [ngStyle]="{ 'padding-left': depth * 12 + 'px' }"
            (click)="onItemSelected(item)"
            [ngClass]="{
                active: item.link ? router.isActive(item.link, true) : false,
                expanded: expanded
            }"
        >
            <div class="menu-item">
                <mat-icon class="route-icon">{{ item.icon }}</mat-icon>

                <!-- <div *ngIf="item?.newItems" class="ec-nav-item-items-wrap">
                <div class="ec-nav-item-items">
                    <span class="ec-nav-item-items-number">{{ item.newItems }}</span>
                </div>
            </div> -->

                <span>
                    {{ item.title | translate }}
                </span>

                @if (item.children && item.children.length) {
                <span>
                    <span></span>
                    <mat-icon [@indicatorRotate]="expanded ? 'expanded' : 'collapsed'">
                        expand_more
                    </mat-icon>
                </span>
                }
            </div>
        </mat-list-item>

        @if (expanded) {
        <div class="ec-sub-menu">
            @for (child of item.children; track child) {
            <ec-menu-list-item
                [item]="child"
                [depth]="depth + 1"
                (selectedMenuItem)="onItemSelected($event)"
            >
            </ec-menu-list-item>
            }
        </div>
        }
    `,
    styleUrls: ['./menu-list-item.component.scss'],
    animations: [
        trigger('indicatorRotate', [
            state('collapsed', style({ transform: 'rotate(0deg)' })),
            state('expanded', style({ transform: 'rotate(180deg)' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4,0.0,0.2,1)'))
        ])
    ]
})
export class MenuListItemComponent {
    expanded = false;

    @HostBinding('attr.aria-expanded') ariaExpanded = this.expanded;

    @Input() item!: NavItem;

    @Input() depth = 1;

    @Input() loggedInUser: fromModel.User;

    @Output() selectedMenuItem = new EventEmitter<NavItem>();

    constructor(public router: Router) {
        if (this.depth === undefined) {
            this.depth = 0;
        }
    }

    public onItemSelected(item: NavItem): void {
        if (!item.children || !item.children.length) {
            this.router.navigate([item.link]);
        }
        if (item.children && item.children.length) {
            this.expanded = !this.expanded;
        }
        this.selectedMenuItem.emit(item);
    }
}
