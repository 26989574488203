import { createSelector } from '@ngrx/store';
import { UI_CONSTANTS } from '@shared/config/constants';
import * as fromFeature from './../reducers';

const globalState = createSelector(
    fromFeature.getCoreState,
    (state: fromFeature.CoreState) => state.global
);

const getWindowSize = createSelector(globalState, (state) => state.size);

const isMobileWindowSize = createSelector(
    globalState,
    (state) => state.size <= UI_CONSTANTS.WINDOW_SIZE.SMALL
);

const isTabletWindowSize = createSelector(
    globalState,
    (state) =>
        state.size > UI_CONSTANTS.WINDOW_SIZE.SMALL && state.size <= UI_CONSTANTS.WINDOW_SIZE.MEDIUM
);

const isDesktopWindowSize = createSelector(
    globalState,
    (state) => state.size >= UI_CONSTANTS.WINDOW_SIZE.MEDIUM
);

const getDepartmentIsLoaded = createSelector(globalState, (state) => state.isLoaded);

const getDepartmentIsLoading = createSelector(globalState, (state) => state.isLoading);

export const GlobalSelectors = {
    getWindowSize,
    getDepartmentIsLoaded,
    getDepartmentIsLoading,
    isMobileWindowSize,
    isTabletWindowSize,
    isDesktopWindowSize
};
