//#region Imports
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { DepartmentService } from '@core/http/services/department.service';
import * as fromActions from '../actions';
import * as fromMappings from '@core/mappings';
import * as fromModel from '@shared/models';
//#endregion

@Injectable()
export class DepartmentEffects {
    loadDepartmentsWithQueryParams$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.GET_DEPARTMENT_QUERY_PARAMS),
            mergeMap((props: { searchQuery: fromModel.ListQueryParams }) =>
                this.departmentService.getDepartmentsWithQueryParams(props.searchQuery).pipe(
                    map((departments: fromModel.Department[]) => {
                        return fromActions.GET_DEPARTMENT_QUERY_PARAMS_SUCCESS({ departments });
                    }),
                    catchError((error: Error) => {
                        return of(
                            fromActions.GET_DEPARTMENT_QUERY_PARAMS_FAIL({
                                error: fromMappings.ToError(error)
                            })
                        );
                    })
                )
            )
        )
    );

    loadDepartments$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.GET_DEPARTMENT),
            mergeMap((props: { active: number }) =>
                this.departmentService.getDepartments(props.active).pipe(
                    map((departments: fromModel.Department[]) => {
                        return fromActions.GET_DEPARTMENT_SUCCESS({ departments });
                    }),
                    catchError((error: Error) => {
                        return of(
                            fromActions.GET_DEPARTMENT_FAIL({ error: fromMappings.ToError(error) })
                        );
                    })
                )
            )
        )
    );

    getDepartmentById$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.GET_DEPARTMENT_BY_ID),
            mergeMap((props: { departmentId: string }) =>
                this.departmentService.getDepartmentById(props.departmentId).pipe(
                    map((department: fromModel.Department[]) => {
                        return fromActions.GET_DEPARTMENT_BY_ID_SUCCESS({ department });
                    }),
                    catchError((error: any) => {
                        return of(
                            fromActions.GET_DEPARTMENT_BY_ID_FAIL({
                                error: fromMappings.ToError(error)
                            })
                        );
                    })
                )
            )
        )
    );

    getDepartmentByUserId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.GET_DEPARTMENT_BY_USER_ID),
            mergeMap((props: { userId: string }) =>
                this.departmentService.getDepartmentByUserId(props.userId).pipe(
                    map((departments: fromModel.Department[]) => {
                        return fromActions.GET_DEPARTMENT_BY_USER_ID_SUCCESS({ departments });
                    }),
                    catchError((error: Error) => {
                        return of(
                            fromActions.GET_DEPARTMENT_BY_USER_ID_FAIL({
                                error: fromMappings.ToError(error)
                            })
                        );
                    })
                )
            )
        )
    );

    getDepartmentByClientId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.GET_DEPARTMENT_BY_CLIENT_ID),
            mergeMap((props: { clientId: string }) =>
                this.departmentService.getDepartmentByClientId(props.clientId).pipe(
                    map((departments: fromModel.Department[]) => {
                        return fromActions.GET_DEPARTMENT_BY_CLIENT_ID_SUCCESS({ departments });
                    }),
                    catchError((error: Error) => {
                        return of(
                            fromActions.GET_DEPARTMENT_BY_CLIENT_ID_FAIL({
                                error: fromMappings.ToError(error)
                            })
                        );
                    })
                )
            )
        )
    );

    updateDepartment$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.UPDATE_DEPARTMENT),
            mergeMap((props: { department: fromModel.Department }) =>
                this.departmentService.updateDepartment(props.department).pipe(
                    map((department: fromModel.Department[]) => {
                        return fromActions.UPDATE_DEPARTMENT_SUCCESS({ department });
                    }),
                    catchError((error: Error) => {
                        return of(
                            fromActions.UPDATE_DEPARTMENT_FAIL({
                                error: fromMappings.ToError(error)
                            })
                        );
                    })
                )
            )
        )
    );

    createDepartment$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.CREATE_DEPARTMENT),
            mergeMap((props: { department: fromModel.Department }) =>
                this.departmentService.createDepartment(props.department).pipe(
                    map((department: fromModel.Department[]) => {
                        return fromActions.CREATE_DEPARTMENT_SUCCESS({ department });
                    }),
                    catchError((error: Error) => {
                        return of(
                            fromActions.CREATE_DEPARTMENT_FAIL({
                                error: fromMappings.ToError(error)
                            })
                        );
                    })
                )
            )
        )
    );
    //#endregion

    constructor(
        private actions$: Actions,
        private departmentService: DepartmentService
    ) {}
}
