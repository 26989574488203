import { Pipe, PipeTransform } from '@angular/core';
import { UI_CONSTANTS } from '@shared/config/constants';

@Pipe({
    name: 'articleUserChargeStatus'
})
export class ArticleUserChargeStatusPipe implements PipeTransform {
    transform(value: unknown): string {
        let result = '';
        if (Number(value) === UI_CONSTANTS.ARTICLE_USER_STATUS.RETURN) {
            result = 'pipe.userReturnedArticle';
        }
        if (Number(value) === UI_CONSTANTS.ARTICLE_USER_STATUS.CHARGE) {
            result = 'pipe.charged';
        }
        if (Number(value) === UI_CONSTANTS.ARTICLE_USER_STATUS.LOST) {
            result = 'pipe.lost';
        }
        if (Number(value) === UI_CONSTANTS.ARTICLE_USER_STATUS.DAMAGED) {
            result = 'pipe.damaged';
        }

        return result;
    }
}

