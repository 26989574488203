import { NavItem } from '../menu-list-item/menu-list-item.component';

export const navigationItems: NavItem[] = [
    {
        link: '/article-charge',
        title: 'nav.articleCharge',
        icon: 'qr_code_scanner',
        roleCanSee: ['role.administrator', 'role.supervisor', 'role.operator']
    },
    {
        title: 'nav.articleManagement',
        icon: 'checkroom',
        children: [
            {
                link: '/article',
                title: 'nav.articleList',
                icon: 'dry_cleaning',
                roleCanSee: ['role.administrator', 'role.supervisor']
            },
            {
                link: '/article-temporary',
                title: 'nav.articleTemporaryList',
                icon: 'devices_other',
                roleCanSee: ['role.administrator', 'role.supervisor']
            },
            {
                link: '/article-group',
                title: 'nav.articleGroup',
                icon: 'hvac',
                roleCanSee: ['role.administrator', 'role.supervisor']
            }
        ],
        roleCanSee: ['role.administrator', 'role.supervisor']
    },
    {
        title: 'nav.userManagement',
        icon: 'groups',
        children: [
            {
                link: '/user',
                title: 'nav.user',
                icon: 'badge',
                roleCanSee: ['role.administrator', 'role.supervisor']
            }
        ],
        roleCanSee: ['role.administrator', 'role.supervisor']
    },
    {
        link: '/client',
        title: 'nav.clientList',
        icon: 'home_work',
        roleCanSee: ['role.administrator', 'role.supervisor']
    },
    {
        link: '/department',
        title: 'nav.departmentList',
        icon: 'house_siding',
        roleCanSee: ['role.administrator', 'role.supervisor']
    },
    {
        link: '/position',
        title: 'nav.positionList',
        icon: 'perm_contact_calendar',
        roleCanSee: ['role.administrator', 'role.supervisor']
    },
    {
        link: '/qrcode',
        title: 'nav.qrCode',
        icon: 'qr_code_2',
        roleCanSee: ['role.administrator']
    },
    {
        link: '/report',
        title: 'nav.report',
        icon: 'app_registration',
        roleCanSee: ['role.administrator']
    }
];
